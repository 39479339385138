<template>
  <div
    class="box-titlee"
    id="videoContainer-info"
    :style="info1"
    @wheel="mouseWheel"
  >
    <div class="videoContainer">
      <video
        id="bgVid"
        class="fullscreenVideo"
        :style="info2"
        autoplay
        muted
        loop
        ignoreapd="true"
        :src="videoMask"
        poster="./1.jpg"
      >
        程序检测到您的浏览器不支持video 标签。
      </video>
      <!-- 四栏 -->
      <div class="nav-box">
        <!-- 表头 -->
        <div class="img">
          <!-- 表头查询 -->
          <div class="search" id="header">
            <div class="head-logo">
              <!-- <img class="head-img" src="@/assets/home/logo.png" alt="" /> -->
              <img class="head-img" src="./logo.png" alt="" />
              <!-- <div class="head-title">甄工匠</div> -->
            </div>
            <div class="title-info">
              <h1>甄工匠一打造建筑行业认可的人才信用评价平台</h1>
              <p class="title-text">
                运用区块链技术,构建新型工业化人才评价体系，
                让企业人力资源管理服务变得更加智能高效
              </p>
              <div class="title-bottom">
                <div class="solid"></div>
                <img class="polygon" src="../../img/Polygon.png" alt="" />
                <div class="solid"></div>
              </div>
            </div>
            <div class="search-input" style="margin-top: 15px">
              <div class="input-icon"><i class="el-icon-search"></i></div>
              <input
                class="input-search"
                placeholder="请输入身份证号或者姓名查询"
                @change="evaluateInput"
                v-model="evaluateValue"
              />
              <div class="input-btn" @click="toEvaluation">查询评价</div>
            </div>
          </div>
        </div>
        <div class="nav-bosx" @click="toEvaluation">
          <div class="img-box">
            <img class="img-child" src="../../img/Group 810@3x.png" alt="" />
            <img src="../../img/Group 944@3x.png" alt="" />
          </div>
          <div class="img-info">
            <p class="xingyong">信用评价</p>
            <p class="ren">劳务/班组/工人</p>
          </div>
        </div>
        <div class="nav-bosx" @click="gowen">
          <img src="../../img/Group 953@3x.png" alt="" />
          <div class="img-info">
            <p class="xingyong">行业动态</p>
            <p class="ren">行业政策/法规/快讯</p>
          </div>
        </div>
        <div class="nav-bosx" @click="toOnlineLearning">
          <img src="../../img/Group 950@3x.png" alt="" />
          <div class="img-info">
            <p class="xingyong">考证及培训</p>
            <p class="ren">考证类/拿证类/就业培训</p>
          </div>
        </div>
        <div class="nav-bosx" @click="toJobFair">
          <img src="../../img/Group 952@3x.png" alt="" />
          <div class="img-info">
            <p class="xingyong">人才招募</p>
            <p class="jianli">热门简历/靠谱企业/招聘会</p>
          </div>
        </div>
      </div>
    </div>
    <div class="home-footer">
      <div class="mouse">
        <svg
          width="34px"
          height="34px"
          viewBox="0 0 34 34"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="画板"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <path
              d="M28,12.5676017 L28,12.5676025 C27.7586697,6.49483729 22.6400884,1.767492 16.5672925,2.00884537 C10.6874933,2.24250712 6.03196149,7.06047183 6,12.9448457 L6,22.7527393 L6,22.7527381 C6.24133004,28.8255033 11.3599116,33.5528486 17.4327075,33.3114952 C23.3125067,33.0778338 27.9680385,28.2598687 28,22.3754949 L28,12.9448137 L28,12.5676017 Z M25.6423328,22.3754953 L25.6423329,22.3755003 C25.7950131,27.1485311 22.0494805,31.1415923 17.2764497,31.2942765 C12.5034189,31.4469566 8.5103577,27.701424 8.35767353,22.9283932 C8.35177945,22.7441357 8.35177957,22.5597376 8.35767384,22.3754788 L8.35767384,12.9447977 L8.3576738,12.9448 C8.44583149,8.17014199 12.3879159,4.3709986 17.1625739,4.45914585 C21.8130398,4.54501066 25.5623528,8.29433412 25.6482281,12.9448 L25.6423328,22.3754953 Z M17.0014768,8.62440158 L17.0014767,8.62440158 C16.3504244,8.62440158 15.8226431,9.15218285 15.8226431,9.80323518 L15.8226431,14.5185757 L15.8226431,14.5185759 C15.8226431,15.1696283 16.3504244,15.6974095 17.0014767,15.6974095 C17.6525291,15.6974095 18.1803103,15.1696283 18.1803103,14.5185759 L18.1803103,9.80323536 L18.1803103,9.80323536 C18.1803103,9.15218303 17.6525291,8.62440158 17.0014767,8.62440158 L17.0014768,8.62440158 Z"
              id="形状"
              fill="#FFFFFF"
              fill-rule="nonzero"
            ></path>
          </g>
        </svg>
        <div>鼠标滚动下滑</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      evaluateValue: "",
      videoMask: require("./bg-video-1.mp4"),
      info1: {},
      info2: {},
      screenWidth: null,
      evaluateValue: ''
    };
  },
  created() {
    this.stop();
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  watch: {
    screenWidth: {
      handler(newval, oldval) {
        if (newval < 1225) {
          this.demo();
        } else {
          console.log("屏幕宽度没有小于1200");
        }
      },
    },
  },
  methods: {
    mouseWheel(e) {
      if (e.wheelDelta || e.detail) {
        if (e.wheelDelta > 0 || e.detail < 0) {
          //当鼠标滚轮向上滚动时
        }
        if (e.wheelDelta < 0 || e.detail > 0) {
          //当鼠标滚轮向下滚动时
          console.log("向下");
          this.info1 = {
            transition: "all 1s",
            transform: "translateY(-1081px)",
            // width: 0,
            height: 0,
          };
          this.info2 = {
            width: 0,
          }
          this.move();
        }
      }
    },
    evaluateInput(val) {
      console.log(val);
    },
    toEvaluation() {},
    demo() {
      this.info1 = {
          transition: "all 1s",
          transform: "translateY(-1081px)",
          height: 0
        };
        this.move();
      // let scrollTop =
      //   window.pageYOffset ||
      //   document.documentElement.scrollTop ||
      //   document.body.scrollTop;
      // let scroll = scrollTop - this.i;
      // this.i = scrollTop;
      // if (scroll < 0) {
      //   console.log("up");
      //   // this.info1 = {
      //   //   transform: "translate3d(0px, 0px, 0px)",
      //   //   transition: "0.4s",
      //   // };
      // } else {
      //   console.log("down");
      //   // this.info1 = {
      //   //   transition: "all 1s",
      //   //   transform: "translateY(-1081px)",
      //   //   // width: 0,
      //   //   height: 0
      //   // };
      //   // this.move();
      // }
    },
    stop() {
      document.documentElement.style.overflowY = "hidden";
    },
    move() {
      document.documentElement.style.overflowY = "";
      this.$emit("showCreate", true);
    },
    evaluateInput(e) {
      this.evaluateValue = e.target._value;
    },
    toEvaluation() {
      if (this.evaluateValue) {
        this.$router.push({path: "/evaluation", query: {value: this.evaluateValue}})
      } else {
        this.$router.push("/evaluation");
      }
      document.documentElement.style.overflowY = "";
    },
    gowen() {
      this.$router.push("/newscenter");
      document.documentElement.style.overflowY = "";
    },
    toOnlineLearning() {
      this.$router.push("/onlineLearning");
      document.documentElement.style.overflowY = "";
    },
    toJobFair() {
      this.$router.push("/jobFair");
      document.documentElement.style.overflowY = "";
    },
  },
};
</script>

<style scoped lang="scss">
$opacity-h: 0.8;
.img {
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: -350%;
  z-index: 90;
  .head-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #029aff;
    margin-top: -12%;
    margin-bottom: 100px;
    opacity: $opacity-h;
    .head-img {
      width: 283px;
      height: 91px;
    }
    .head-title {
      font-size: 36px;
      font-weight: bold;
    }
  }
  img {
    position: relative;
    display: block;
    width: 100%;
    height: 461px;
  }
  .search {
    width: 1320px;
    margin: 0 auto;
    height: 100%;
    color: #fff;

    .title-info {
      width: 735px;
      text-align: center;
      margin: 0 auto;
      opacity: 0.8;
      margin-bottom: 3%;

      .title {
        font-size: 42px;
        font-weight: bold;
      }

      .title-text {
        width: 350px;
        text-align: center;
        margin: 0 auto;
      }

      .title-bottom {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;

        .solid {
          width: 288px;
          height: 3px;
          margin: auto 0;
          background-color: rgba(237, 228, 217, $opacity-h);
        }

        .polygon {
          width: 34px;
          height: 25px;
        }
      }
    }

    .search-input {
      width: 1000px;
      height: 72px;
      margin: 0 auto;
      display: flex;

      .input-icon {
        line-height: 72px;
        font-size: 30px;
        width: 60px;
        background-color: rgba(237, 228, 217, 0.2);
        border-radius: 50px 0 0 50px;
        text-align: center;

        i {
          color: #fff;
          opacity: 1;
        }
      }

      .input-search {
        width: 790px;
        background-color: rgba(237, 228, 217, 0.2);
        border: none;
        border-radius: 0;
        color: #fff;
        font-size: 18px;
      }

      .input-search:focus {
        outline: none;
      }

      input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        border: none;
        color: #fff;
      }

      input::-moz-placeholder {
        /* Firefox 19+ */
        color: #fff;
        // color: rgba(237, 228, 217, 0.5);
      }

      input:-ms-input-placeholder {
        /* IE 10+ */
        color: #fff;
        // color: rgba(237, 228, 217, 0.5);
      }

      input:-moz-placeholder {
        /* Firefox 18- */
        color: #fff;
        // color: rgba(237, 228, 217, 0.5);
      }

      .input-btn {
        width: 150px;
        line-height: 72px;
        font-size: 18px;
        background-color: rgba(21, 146, 234, $opacity-h);
        color: #fff;
        border-radius: 0 50px 50px 0;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.nav-box {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100px;
  position: absolute;
  left: 0;
  bottom: 24%;
  margin: 0 auto;
  z-index: 100;

  .nav-bosx {
    position: relative;
    display: flex;
    cursor: pointer;
    border-radius: 6px;
    background: rgba(255, 255, 255, $opacity-h)
      linear-gradient(
        180deg,
        rgba(140, 188, 233, 0.62) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    width: 260px;
    height: 80px;
    padding: 10px;
    margin: 0 15px;
    .img-box {
      position: relative;
      .img-child {
        position: absolute;
        left: 68px;
        display: block;
        width: 40px;
        height: 24px;
      }
    }

    .img-info {
      flex: 1;
      margin: auto 0;
    }
  }

  .active {
    background-color: #dbefff;
  }

  img {
    display: block;
    margin: auto;
    // margin-left: 80px;
    width: 80px;
    height: 80px;
  }

  .xx {
    width: 70px;
    color: rgba(51, 51, 51, 1);
    font-size: 14px;
    margin-left: 15px;
  }

  .xingyong {
    margin: 10px 0 20px 20px;
    font-size: 16px;
    font-weight: bold;
  }

  .xingyong1 {
    width: 65.97px;
    color: rgba(51, 51, 51, 1);
    font-size: 14px;
    margin-left: 8px;
  }

  .ren {
    color: rgba(102, 102, 102, 1);
    font-size: 14px;
    margin-left: 20px;
  }

  .jianli {
    color: rgba(102, 102, 102, 1);
    font-size: 14px;
    margin-left: 10px;
  }
}
.box-titlee {
  width: 100vw;
  height: 100vh;
  // width: 100%;
  // height: 100%;
  opacity: 0.9999;
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* KHTML内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
}
.videoContainer {
  // width: 100%;
  // height: 975px;
  .fullscreenVideo {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
}
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
//播放按钮
video::-webkit-media-controls-play-button {
  display: none;
}
//进度条
video::-webkit-media-controls-timeline {
  display: none;
}
//观看的当前时间
video::-webkit-media-controls-current-time-display {
  display: none;
}
//剩余时间
video::-webkit-media-controls-time-remaining-display {
  display: none;
}
//音量按钮
video::-webkit-media-controls-mute-button {
  display: none;
}
// 画中画
video::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
}
//音量的控制条
video::-webkit-media-controls-volume-slider {
  display: none;
}
//所有控件
video::-webkit-media-controls-enclosure {
  display: none;
}
video:focus {
  outline: none;
}
.home-footer {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 5%;
  color: #fff;
  font-size: 14px;
}

.home-footer .mouse {
  position: relative;
  margin-top: 25px;
  font-size: 16px;
  -webkit-animation: TopBots 1.2s ease-in-out infinite both;
  -moz-animation: TopBots 1.2s ease-in-out infinite both;
  -ms-animation: TopBots 1.2s ease-in-out infinite both;
  animation: TopBots 1.2s ease-in-out infinite both;
}

.home-footer .mouse img {
  height: 31px;
}
@keyframes TopBots {
  0% {
    transform: translateY(-8px);
  }

  50% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-8px);
  }
}

@-webkit-keyframes TopBots {
  0% {
    transform: translateY(-8px);
  }

  50% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-8px);
  }
}

@-moz-keyframes TopBots {
  0% {
    transform: translateY(-8px);
  }

  50% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-8px);
  }
}

@-ms-keyframes TopBots {
  0% {
    transform: translateY(-8px);
  }

  50% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-8px);
  }
}
</style>

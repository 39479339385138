import request from "@/api/request"
// 数据添加库
export function addOrgAuth(data) {
  return request({
    method: 'post',
    url: '/jobUser/jobAuthOrg/addJobAuthOrg',
    data:data
  })
}



export function getAuthOrg(data) {
  return request({
    method: 'get',
    url: '/jobUser/jobAuthOrg/getAuthOrg',
    params:data
  })
}

 
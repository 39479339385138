<template>
  <div class="main">
    <div class="header">
      <div class="top">
        <div class="left">
          <img src="@/assets/personCenter/headerLeft.png" alt="" />
          <span>甄工匠</span>
        </div>
        <div class="center">
          <div class="span" v-for="(item, index) in headerCList" :key="index">
            <span @click="xianshi(index)">{{ item }}</span>
            <div
              style="margin-top: 10px"
              class="xians"
              v-if="xian == index ? true : false"
            ></div>
          </div>
        </div>
        <div class="right">
          <div
            class="imgBox"
            v-for="(item, index) in headerRightList"
            :key="index"
          >
            <img :src="item.top" alt="" />
            <img   :src="item.bottom" alt="" />
          </div>
          <img
            class="personImg"
            src="@/assets/personCenter/personImg.png"
            alt=""
          />
          <div class="an">
            <div v-if="xian >= 1" class="register" @click="register">登录</div>
            <div v-if="xian >= 1" class="zuce" @click="zuce">注册</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <img class="titlebei" src="../../img/titlebei.png" alt="" />
      <div class="yanz">身份验证</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      xian: -1,
      headerCList: ['首页', '职位搜索', '求职信息', '在线学习', '关于我们'],
      headerRightList: [
        {
          top: require('@/assets/personCenter/appT.png'),
          bottom: require('@/assets/personCenter/appB.png')
        },
        {
          top: require('@/assets/personCenter/headerRight2T.png'),
          bottom: require('@/assets/personCenter/headerRight2B.png')
        },
        {
          top: require('@/assets/personCenter/headerRight3T.png'),
          bottom: require('@/assets/personCenter/headerRight3B.png')
        }
      ]
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    getindex(index) {
      this.xian = index
    },
    getindex0(index) {
      this.xian = index
    },
    xianshi(index) {
      console.log(index)
      if (index == 0) {
        this.$router.push('/home')
      } else if (index == 1) {
        this.$router.push('./Position')
      }

      console.log(this.xian)
    },
    register() {
      this.$router.push('/login')
    },
    zuce() {
      this.$router.push('/register')
    }
  }
}
</script>

<style scoped lang="scss">

.titlebei{
  display: block;
  width: 100%;
  height: 220px;
  position: relative;
}
.yanz{
  position: absolute;
  left: 19%;
  top: 15%;
   font-size: 48px;
   color: #3584ab;
}
  .center {
    .span {
      text-align: center;
      cursor: pointer;
    }

    .xians {
      margin-top: 11px;
      width: 24px;
      height: 3px;
      border-radius: 1px;
      background-color: rgba(255, 255, 255, 1);
    }
  }

  .an {
    display: flex;
    margin-left: 20px;

    .register {
      margin-top: 13px;
      width: 80px;
      height: 36px;
      background: #fff;
      backdrop-filter: blur(24px);
      color: rgba(2, 154, 255, 1);
      border: 1px solid #fff;
      text-align: center;
      line-height: 36px;
      cursor: pointer;
      border-radius: 3px;
    }

    .register:hover {
      background: #409eff;
      color: #fff;
    }

    .zuce:hover {
      background: #409eff;
      color: #fff;
    }

    .zuce {
      margin-top: 13px;
      width: 80px;
      height: 36px;
      color: rgba(2, 154, 255, 1);
      border: 1px solid #fff;
      backdrop-filter: blur(24px);
      background: #fff;
      text-align: center;
      line-height: 36px;
      margin-left: 10px;
      cursor: pointer;
      border-radius: 3px;
    }
  }

  .header {
    width: 100%;
    background-color: #029aff;

    .top {
      width: 1200px;
      margin: 0 auto;
      height: 88px; //68
      background-color: #029aff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;

      .left {
        display: flex;
        align-content: center;

        img {
          width: 36px;
          height: 36px;
          margin-right: 20px;
        }

        span {
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 18px;
        }
      }

      .center {
        display: flex;

        div {
          margin: 0 20px;
          font-size: 14px;
          color: #fff;
        }
      }

      .right {
        display: flex;
        align-items: center;

        .imgBox {
          position: relative;
          margin-top: 8px;
          img:nth-child(1) {
            width: 38px;
            height: 18px;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
          }

          img:nth-child(2) {
            width: 56px;
            height: 56px;
          }
        }

        .personImg {
          width: 36px;
          height: 36px;
          margin-left: 20px;
        }
      }
    }
  }
</style>
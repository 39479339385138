<template>
  <div class="main">
    <div class="outBox">
      <div class="leftBox">
        <div class="logoBox">
          <img src="@/assets/login/logo.png" alt="" />
        </div>
        <div class="sloganBox">
          <span style="font-size: 18px; color: #666"
            >解决新型工业化人才评价痛点</span
          >
          <div class="slogans">
            <i class="iconfont icon-line_qukuailian"></i>
            <span>运用区块链技术,构建新型工业化人才评价体系</span>
          </div>
          <div class="slogans">
            <i class="iconfont icon-zhineng"></i>
            <span>让企业人力资源管理服务变得更加智能高效</span>
          </div>
        </div>
      </div>
      <div class="rightBox">
        <div class="formBox">
          <span
            style="font-size: 20px; color: #333; margin: 10px auto 30px 82px"
            >注册</span
          >
          <el-form ref="form" :model="form" :rules="formRules">
            <el-form-item prop="account">
              <el-input v-model="form.account" maxlength="12" placeholder="请输入非中文用户名">
                <i
                  slot="prefix"
                  class="iconfont icon-yonghu"
                  style="color: #029aff; margin-left: 5px"
                ></i
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="form.password"
                show-password
                maxlength="20"
                placeholder="请输入密码"
              >
                <i
                  slot="prefix"
                  class="iconfont icon-mima1"
                  style="color: #029aff; margin-left: 5px"
                ></i
              ></el-input>
            </el-form-item>
            <el-form-item prop="repassword">
              <el-input
                v-model="form.repassword"
                show-password
                maxlength="20"
                placeholder="请再次输入密码"
              >
                <i
                  slot="prefix"
                  class="iconfont icon-mima1"
                  style="color: #029aff; margin-left: 5px"
                ></i
              ></el-input>
            </el-form-item>
            <el-form-item prop="phone">
              <el-input v-model="form.phone" placeholder="请输入手机号码">
                <i
                  slot="prefix"
                  class="iconfont icon-shouji"
                  style="color: #029aff; margin-left: 5px"
                ></i
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="verification"
                maxlength="10"
                placeholder="请输入验证码"
              >
                <i
                  slot="prefix"
                  class="iconfont icon-yanzheng"
                  style="color: #029aff; margin-left: 5px"
                ></i>
                <span
                  v-show="result == null"
                  slot="suffix"
                  @click="getCode"
                  style="padding: 0 10px; cursor: pointer; color: #029aff"
                  ><el-divider direction="vertical"></el-divider
                  >获取验证码</span
                >
                <span
                  v-show="result > 0 && result"
                  slot="suffix"
                  style="padding: 0 10px; cursor: pointer; color: #029aff"
                  ><el-divider direction="vertical"></el-divider
                  >{{ result }}</span
                >
                <!-- <span
                  v-show="sendAuthCode"
                  slot="suffix"
                  style="padding: 0 10px; cursor: pointer; color: #029aff"
                  @click="getCode"
                  ><el-divider direction="vertical"></el-divider
                  >获取验证码</span
                >
                <span
                  v-show="!sendAuthCode"
                  slot="suffix"
                  style="padding: 0 10px; cursor: pointer; color: #029aff"
                  ><el-divider direction="vertical"></el-divider
                  ><span class="auth_text_blue">{{ auth_time }} </span></span
                > -->
              </el-input>
            </el-form-item>
          </el-form>
          <el-button
            type="primary"
            style="color: #fff; width: 400px; height: 52px; font-size: 16px"
            @click="_register"
            >注册</el-button
          >
          <div style="color: #999; font-size: 14px; margin-top: 10px">
            已有账号？<span
              @click="toLogin"
              style="color: #029aff; cursor: pointer"
              >登录</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendSmsCode, register } from "@/api/login/login";
export default {
  name: "",
  data() {
    var validateAccount = (rule, value, callback) => {
      if (value === "" || value.length < 6) {
        callback(new Error("请输入长度6至12位的账户名"));
      } else {
        let reg = new RegExp("[\\u4E00-\\u9FFF]+","g")
        if (reg.test(this.form.account)) {
          callback(new Error("账号包含中文名称请修改"));
        }
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.password.length < 6) {
          callback(new Error("密码长度要大于等于6位"));
        } else {
          let testPassword = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]*$/
          if(!testPassword.test(this.form.password)) {
            callback(new Error("密码包括大小写字母、数字"));
          }
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const validateTel = (rule, value, callback) => {
      let regTest =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!value) {
        callback(new Error("请输入联系方式"));
      } else {
        if (!regTest.test(value)) {
          callback(new Error("手机号码格式不正确"));
          return;
        }
        callback();
      }
    };
    return {
      verification: "", //绑定输入验证码框框
      verCode: "",
      // sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      // auth_time: 0 /*倒计时 计数器*/,
      btnFlag: false,
      timer: null,
      result: null, //此变量为截止时间戳与当前时间戳相减的差
      timeStamp: null, //此变量为倒计时截止的时间戳
      form: {
        account: "",
        password: "",
        repassword: "",
        phone: "",
      },
      formRules: {
        account: [{ validator: validateAccount, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        repassword: [{ validator: validatePass2, trigger: "blur" }],
        phone: [{ validator: validateTel, trigger: "blur" }],
      },
    };
  },
  // watch: {},
  // props: {},
  // components: {},
  // computed: {},
  created() {
    // window.addEventListener("beforeunload", () => {
    //   if (this.timeStamp) {
    //     localStorage.setItem("reduceTimeStamp", JSON.stringify(this.timeStamp));

    //     clearInterval(this.timer);
    //   }
    // });
    // let temp = JSON.parse(localStorage.getItem("reduceTimeStamp"));
    // this.form.tel = localStorage.getItem("phone");
    // let NowStamp = new Date().getTime();
    // //如果<=0说明倒计时已结束，将按钮恢复原始状态
    // if (+temp - NowStamp <= 0) {
    //   this.result = null;
    //   this.btnFlag = false;
    // } else {
    //   this.result = parseInt((temp - NowStamp) / 1000);
    // }
  },
  // mounted() {
  //   if (this.result) this.getCode();
  // },
  methods: {
    countTime() {
      //如果result<=0,证明上一次读秒已结束，需要重新设置新的时间戳
      if (!this.result) {
        let currentStamp = new Date().getTime();
        this.timeStamp = new Date().getTime() + 60 * 1000; //我设置了60秒
        this.result = (this.timeStamp - currentStamp) / 1000;
      }
      this.timer = setInterval(() => {
        this.result--;
        if (this.result === 0) {
          clearInterval(this.timer);
          this.timer = null;
          this.result = null;
          this.btnFlag = false;
        }
      }, 1000);
      sendSmsCode({ phone: this.form.phone }).then((res) => {
        console.log(res)
        // if (res.success) {
          // this.verCode = res.data.code;
          // console.log(this.verCode)
        // }
      });
    },
    //点击获取验证码按钮
    getCode() {
      this.btnFlag = !this.btnFlag;
      localStorage.setItem("phone", this.form.phone);
      let regTest =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!this.form.phone) {
        this.$message.error("请输入手机号码");
        return;
      } else {
        if (!regTest.test(this.form.phone)) {
          this.$message.error("手机号码格式不正确");
          return;
        }
      }
      this.countTime();
    },
    _register() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // if (this.verCode == this.verification) {
            this.form.code = this.verification;
            register(this.form).then((res) => {
              if (res.success) {
                this.$message.success("注册成功");
                setTimeout(() => {
                  this.$router.push("/login");
                }, 1000);
              } else {
                this.$message.error(res.message);
              }
            });
          // }else{
          //   this.$message.error("验证码错误");
          // }
        } else {
          // console.log('error submit!!')
          return false;
        }
      });
    },
    // getCode() {
    //   // 获取验证码
    //   let regTest =
    //     /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    //   if (!this.form.tel) {
    //     this.$message.error("请输入手机号码");
    //     return;
    //   } else {
    //     if (!regTest.test(this.form.tel)) {
    //       this.$message.error("手机号码格式不正确");
    //       return;
    //     }
    //   }
    //   this.sendAuthCode = false;
    //   //设置倒计时秒
    //   this.auth_time = 60;
    //   var auth_timeTimer = setInterval(() => {
    //     this.auth_time--;
    //     if (this.auth_time <= 0) {
    //       this.sendAuthCode = true;
    //       clearInterval(auth_timeTimer);
    //     }
    //   }, 1000);
    //   sendSmsCode({ phone: this.form.tel }).then((res) => {
    //     console.log(res);
    //     if (res.success) {
    //       this.verification = res.data.code;
    //     }
    //   });
    // },
    toLogin() {
      this.$router.push("/login");
    },
  },
  filters: {},
};
</script>

<style scoped lang="scss">
.main {
  background: url("~@/assets/login/bgc.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
}

.outBox {
  width: 877px;
  height: 550px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}

.leftBox {
  width: 329px;
  height: 542px;
  margin-top: 4px;
  border-radius: 5px;
  background-color: rgba($color: #f7fcff, $alpha: 0.9);
  .logoBox {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 140px;
      height: 140px;
    }
  }

  .sloganBox {
    height: 50%;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: #999;
    justify-content: space-between;
    .slogans {
      display: flex;
      align-items: center;
      i {
        font-size: 36px;
        margin-right: 20px;
      }
    }
    .slogans:nth-last-child(1) {
      margin-bottom: 70px;
    }
  }
}

.rightBox {
  width: 559px;
  height: 100%;
  // background-color: #5031;
  margin-left: -11px;
  background: url("~@/assets/login/bgc2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.rightBox::before {
  position: absolute;
  content: "";
  width: 318px;
  height: 100%;
  right: 4px;
  z-index: -1;
  background: url("~@/assets/login/bgc3.png");
  background-repeat: no-repeat;
}

.formBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  :deep(.el-input) {
    .el-input__inner {
      width: 400px;
      height: 50px;
    }
    .el-input__prefix,
    .el-input__suffix {
      line-height: 50px;
    }
  }
  .el-form {
    .el-form-item {
      // margin-bottom: 10px;
    }
  }
}
</style>

<template>
  <div class="box-titlee">
    <div class="box-tab">
      <div>
        <div class="left-box">
          <div class="left-flex" v-if="this.xian == 0">
            <img class="imgs" src="@/assets/home/logo.png" alt="" />
            <div class="rencai">甄工匠</div>
          </div>

          <div class="left-flex" v-else>
            <img class="imgss" src="@/assets/home/logo.png" alt="" />
            <span class="rencai">甄工匠</span>
          </div>

          <!-- for循环tab栏 -->
          <div class="center-box">
            <div class="tabs" v-for="(item, index) in tab" :key="index">
              <span
                @click="xianshi(index)"
                :class="index == borderBottom ? 'borderBottom' : ''"
                >{{ item.name }}</span
              >
            </div>
          </div>

          <div class="right">
            <div class="right-box" v-show="isLogin">
              <div class="mask-icon">
                <img
                  class="img-icon"
                  :src="imageIcon"
                  :onerror="$global.srcImgUrl"
                  alt=""
                  @click.stop="toCredit()"
                />
              </div>
              <div @click="toNotice" class="messageBox">
                <i class="el-icon-chat-dot-square"></i>
                <div class="redPoint" v-if="isRedPoint"></div>
              </div>

              <div class="yuxiang">
                <div class="app app1">
                  <img class="shouji" src="../../img/shouji-1.png" alt="" />
                  <div class="sj">
                    <img class="ma" src="@/assets/img/ewm.png" alt="" />
                  </div>
                </div>
                <!-- <div class="app">
                  <img
                    class="shouji"
                    src="../../img/jl-1.png"
                    alt=""
                    @click.stop="toPosition()"
                  />
                </div> -->
                <!-- <div class="app">
                  <img
                    class="shouji"
                    src="../../img/yonghu-1.png"
                    alt=""
                    @click.stop="toJobInformation()"
                  />
                </div> -->
              </div>
              <el-dropdown>
                <div
                  class="el-dropdown-link"
                  style="height: 100%; display: flex; align-items: center"
                >
                  <img
                    el-dropdown
                    class="personImg"
                    :src="userImg? userImg : imageUser"
                    :onerror="$global.srcImgUrl"
                    alt=""
                    style="cursor: pointer"
                  />
                  <!-- @/assets/personCenter/personImg.png -->
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    icon="el-icon-user"
                    @click.native="toPersonalCenter"
                    >个人中心</el-dropdown-item
                  >
                  <!-- <el-dropdown-item icon="el-icon-document"
                    >简历中心</el-dropdown-item
                  >
                  <el-dropdown-item icon="el-icon-thumb"
                    >我的申请</el-dropdown-item
                  > -->
                  <el-dropdown-item
                    icon="el-icon-key"
                    @click.native="toResetPwd"
                    >修改密码</el-dropdown-item
                  >
                  <el-dropdown-item
                    icon="el-icon-thumb"
                    @click.native="toFeedback"
                    >建议反馈</el-dropdown-item
                  >
                  <el-dropdown-item
                    icon="el-icon-switch-button"
                    @click.native="handleLogout"
                    >退出账号</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <div class="an" v-show="!isLogin">
              <div class="register" @click="register">登录</div>
              <div class="zuce" @click="zuce">注册</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLoginUser, logout } from "@/api/login/login";
import { getJobUserAuth } from "@/api/jobInformation/jobInformation";
import { detailTeam } from "@/api/team/team";
import { jobScoringList } from "@/api/evaluation/evaluation";
import { mapState } from "vuex";
export default {
  data() {
    return {
      userImg: "",
      imageUser: require("@/assets/img/noAvatar.png"),
      imageIcon: require("@/assets/home/mask-icon.png"),
      xian: -1,
      Token: {},
      isLogin: true,
      borderBottom: null,
      an: "",
      tab: [
        { name: "首页", id: "" },
        { name: "职位搜索" },
        { name: "求职信息" },
        { name: "在线学习" },
        { name: "关于我们" },
      ],
      headerRightList: [
        {
          top: require("@/assets/personCenter/appT.png"),
          bottom: require("@/assets/personCenter/appB.png"),
        },
        {
          top: require("@/assets/personCenter/headerRight2T.png"),
          bottom: require("@/assets/personCenter/headerRight2B.png"),
        },
        {
          top: require("@/assets/personCenter/headerRight3T.png"),
          bottom: require("@/assets/personCenter/headerRight3B.png"),
        },
      ],
      userTeam: {},
      userAuth: {},
    };
  },
  computed: {
    ...mapState(["isRedPoint"]),
  },
  watch: {
    isLogin(val, oldVal) {
      if (val) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }
    },
  },
  created() {
    this.isLogin = localStorage.getItem("userId") ? true : false;
    this.userImg = localStorage.getItem("userImg");
    console.log(this.userImg,localStorage.getItem("userImg"))
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("/"),
      window.location.href.length
    );
    switch (href) {
      case "/home":
        this.borderBottom = 0;
        break;
      case "/position":
        this.borderBottom = 1;
        break;
      case "/jobInformation":
        this.borderBottom = 2;
        break;
      case "/onlineLearning":
        this.borderBottom = 3;
        break;
      case "/aboutUs":
        this.borderBottom = 4;
        break;

      default:
        this.borderBottom = null;
        break;
    }
    // this._isLogin()
  },
  mounted() {},

  methods: {
    async toCredit() {
      let jobUserId = localStorage.getItem("userId");
      this.userTeam = await detailTeam({ jobUserId });
      this.userAuth = await getJobUserAuth();
      if (this.userAuth.data && this.userAuth.data.audit_status == "2") {
        this.$router.push({
          path: "/personalCenter/creditrating",
          query: { idCard: this.userAuth.data.identity_code, val: 1 },
        });
      } else if (this.userTeam.data && this.userTeam.data.auditStatus == 2) {
        this.$router.push({
          path: "/personalCenter/creditrating",
          query: { idCard: this.userTeam.data.idCard, val: 2 },
        });
      } else {
        this.$message.warning('认证个人/班组信息,才可涨分!')
      }
    },
    toNotice() {
      this.$router.push("/notice");
      // let route = this.$router.resolve('/notice')
      // window.open(route.href,'_blank')
    },

    toFeedback() {
      this.$router.push("/feedback");
    },

    _isLogin() {
      getLoginUser()
        .then((res) => {
          console.log(res, "判断是否登录");
          if (res.success) {
            this.isLogin = true;
          }
        })
        .catch((err) => {
          this.isLogin = false;
        });
    },
    toResetPwd() {
      this.$router.push({ path: "/forgetPwd", query: { type: 1 } });
    },
    handleLogout() {
      logout().then((res) => {
        // IM登出
        this.$tim.logout();
        // IM销毁
        // this.$tim.destroy()
        this.$store.commit("set_peopleList", "");
        this.$store.commit("set_wordList", "");
        this.$store.commit("set_isRedPoint", false);
        // localStorage.clear()
        this.isLogin = false;
        localStorage.removeItem("userId");
        localStorage.removeItem("orgId");
        localStorage.removeItem("Access-Token");
        this.$store.commit("SET_HASORGLOGIN", false);
        localStorage.removeItem("readInfo");
        localStorage.removeItem("userImg");
        // this._isLogin()
        if (this.$route.path !== "/home") {
          this.$router.push("/home");
        } else {
          this.$router.go(0);
        }
      });
    },
    toPersonalCenter() {
      this.$router.push("/personalCenter");
    },

    // 首页
    homeindexss(index) {
      this.xian = index;
    },
    getindex(index) {
      this.xian = index;
    },
    getindex0(index) {
      this.xian = index;
    },
    xianshi(index) {
      // this.borderBottom = index

      let href = window.location.href.substring(
        window.location.href.lastIndexOf("/"),
        window.location.href.length
      );

      console.log(index);

      // let route = ''
      if (index == 0 && href != "/home") {
        this.$router.push("/home");
        // route = this.$router.resolve('/home')
      } else if (index == 1 && href != "/position") {
        this.$router.push("/position");
        // route = this.$router.resolve('/position')
      } else if (index == 2 && href != "/jobInformation") {
        this.$router.push("/jobInformation");
        // route = this.$router.resolve('/jobInformation')
      } else if (index == 3 && href != "/onlineLearning") {
        this.$router.push("/onlineLearning");
        // route = this.$router.resolve('/onlineLearning')
      } else if (index == 4 && href != "/aboutUs") {
        this.$router.push("/aboutUs");
        // route = this.$router.resolve('/aboutUs')
      }
      // window.open(route.href, '_blank')
    },

    //进入职位搜索
    toPosition() {
      let href = window.location.href.substring(
        window.location.href.lastIndexOf("/"),
        window.location.href.length
      );
      if (href == "/position") return;
      this.$router.push({
        path: "/position",
      });
    },

    //进入找人才
    toJobInformation() {
      let href = window.location.href.substring(
        window.location.href.lastIndexOf("/"),
        window.location.href.length
      );
      if (href == "/jobInformation") return;
      this.$router.push({
        path: "/jobInformation",
      });
    },

    register() {
      this.$router.push("/login");
    },
    zuce() {
      this.$router.push("/register");
    },
  },
};
</script>

<style scoped lang="scss">
.messageBox {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  .el-icon-thumb {
    font-size: 32px;
    color: #029aff;
    font-weight: 500;
    margin-right: 10px;
  }
  .el-icon-chat-dot-square {
    font-size: 32px;
    color: #029aff;
    font-weight: 500;
  }
  .redPoint {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.borderBottom {
  color: #0081d6;
  position: relative;
}
.borderBottom::after {
  position: absolute;
  content: "";
  width: 24px;
  height: 3px;
  border-radius: 4px;
  background-color: #0081d6;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 0);
}
.app1:hover .sj {
  display: block;
}
.sj {
  z-index: 99999;
  position: absolute;
  display: none;
  top: 88px;
  .ma {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
}
.yuxiang {
  position: relative;
  display: flex;
  // width: 189px;
  width: 50px;
  justify-content: space-around;
  align-items: center;

  .app {
    width: 33.99%;
    display: flex;
    color: rgba(255, 255, 255, 1);
    font-size: 12px;
    justify-content: center;
    cursor: pointer;
  }
  .shouji {
    z-index: 99;
    width: 30px;
    height: 30px;
    color: #0081d6;
  }
}

::v-deep .el-icon-arrow-down:before {
  content: none;
}
.el-dropdown {
  width: 100px;
  height: 80px;
}
:hover.el-dropdown {
  width: 100px;
  height: 80px;
}
.right {
  // width: 336px;
  width: 350px;
  display: flex;
  align-items: center;
  // z-index: 99999;
  justify-content: flex-end;
  margin-right: 20px;
  .right-box {
    display: flex;
    align-items: center;
    .mask-icon {
      width: 50px;
      height: 40px;
      cursor: pointer;
      margin-right: 20px;
      .img-icon {
        width: 50px;
        height: 40px;
      }
    }
  }

  .personImg {
    // z-index: 99999;
    width: 36px;
    height: 36px;
    border-radius: 35px;
    margin-left: 30px;
    line-height: 80px;
  }
  .an {
    display: flex;

    .register {
      width: 80px;
      height: 36px;
      backdrop-filter: blur(24px);
      color: rgba(2, 154, 255, 1);
      border: 1px solid #fff;
      text-align: center;
      line-height: 36px;
      cursor: pointer;
      border-radius: 3px;
      z-index: 1;
    }

    .register:hover {
      background: #409eff;
      color: #fff;
    }

    .zuce:hover {
      background: #fff;
      color: #409eff;
    }

    .zuce {
      width: 80px;
      height: 36px;
      color: rgba(2, 154, 255, 1);
      border: 1px solid #fff;
      backdrop-filter: blur(24px);
      background: #fff;
      text-align: center;
      line-height: 36px;
      margin-left: 10px;
      cursor: pointer;
      border-radius: 3px;
      z-index: 1;
    }
  }
}
.box-titlee {
  // position: fixed;
  // top: 0;
  // z-index: 9;
  width: 100%;
  margin: 0 auto;
  .box-tab {
    background: #fff;
    .left-box {
      height: 80px;
      display: flex;
      justify-content: space-between;
      .left-flex {
        width: 320px;
        display: flex;
        margin-left: 22px;
        align-items: center;
      }

      .imgs {
        width: 44px;
        height: 44px;
      }
      .imgss {
        width: 44px;
        height: 44px;
      }
      .rencai {
        width: 200px;
        margin-left: 24px;
        color: #029aff;
        font-size: 30px;
        font-weight: bolder;
        .miaoshu {
          width: 180px;
          color: #029aff;
          font-size: 12px;
        }
      }
    }

    .center-box {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tabs {
        width: 100px;
        text-align: center;
        font-size: 18px;
        float: left;
        cursor: pointer;
      }
      .xian {
        margin-top: 11px;
        width: 24px;
        height: 3px;
        border-radius: 1px;
        margin: 11px auto;
        color: #029aff;
      }
    }
    .go {
      margin-top: 26px;
    }
  }
  .img {
    position: relative;
    display: block;
    width: 100%;
    height: 461px;
  }
  img {
    // position: relative;
    // display: block;
    width: 100%;
    height: 461px;
  }
  .banner {
    position: relative;
  }
  .banner-img {
    display: block;
    height: 250px;
  }
  .banner-zi {
    position: absolute;
    left: 26%;
    top: 220px;
    transform: translate(-26%, -50%);
    width: 503px;
    height: 124px;
    display: block;
    font-size: 48px;
    color: #3584ab;
  }
}
</style>

<template>
  <div class="box-titlee">
    <div id="scoring-mask" v-show="showVisible1 || showVisible2">
      <div class="mask-left">
        <div class="left-span" v-show="leftMask">
          信用评价是不同的维度组成，奖惩情况、安全意识、专业能力、工程质量以及其他加减分项，通过信用评价页面上传真实的证明材料来获取更高的评分吧!
        </div>
        <div class="left-mask">如何涨分?</div>
      </div>
      <div class="mask-center">
        <div class="center-hide">
          <i class="el-icon-close" @click="hideShow"></i>
        </div>
        <div class="center-info">
          <div class="info-title">您已获得信用评价</div>
          <div class="info-grade">
            <span>{{ percentage }}</span
            >分
          </div>
        </div>
        <img class="center-img" :src="imageMask" alt="" />
        <div class="center-btn" @click="toCredit">完善信息去涨分!</div>
      </div>
      <div class="mask-right">
        <div class="right-mask">
          什么是信 <br />用评价?
        </div>
        <div class="right-span" v-show="leftMask">
          信用评价是根据你的基本信息、综合信息以及个人评价来进行评分。评分是展现真实客观的数据，企业或者班组可以通过你的评分来查看你的综合能力。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLoginUser, logout } from "@/api/login/login";
import { getJobUserAuth } from "@/api/jobInformation/jobInformation";
import { detailTeam } from "@/api/team/team";
import { jobScoringList } from "@/api/evaluation/evaluation";
export default {
  data() {
    return {
      imageIcon: require("@/assets/home/mask-icon.png"),
      imageMask: require("@/assets/home/mask-info.png"),
      showVisible1: false,
      showVisible2: false,
      userTeam: {},
      userAuth: {},
      percentage: 0,
      leftMask: true,
      rightMask: true,
    };
  },
  created() {
  },
  mounted() {
    this.getUser();
  },

  methods: {
    async getUser() {
      let jobUserId = localStorage.getItem("userId");
      if (
        jobUserId &&
        this.$route.path !== "/creditEvaluation" &&
        this.$route.path !== "/creditExamination" &&
        this.$route.path !== "/personalCenter/creditrating"
      ) {
        this.userTeam = await detailTeam({ jobUserId });
        this.userAuth = await getJobUserAuth();
        this.jobScoring();
      }
    },
    async jobScoring() {
      if (this.userAuth.data && this.userAuth.data.audit_status == "2") {
        let params = {
          idCard: this.userAuth.data.identity_code,
          userType: 1,
          type: 1,
          targetId: localStorage.getItem("userId"),
        };
        let res = await jobScoringList(params);
        let showVisible = res.data && res.data.isSubmit ? true : false;
        if(showVisible){
          localStorage.setItem("showPopVisible", true);
          this.showVisible1 = localStorage.getItem("showPopVisible");
          this.percentage = parseInt(res.data.grade);
        }else{
          this.isTeam()
        }
        return;
      }else if (this.userTeam.data && this.userTeam.data.auditStatus == 2) {
        let params = {
          idCard: this.userTeam.data.idCard,
          userType: 3,
          type: 3,
          targetId: this.userTeam.data.id,
        };
        let res = await jobScoringList(params);
        let showVisible = res.data.isSubmit ? true : false;
        if(showVisible){
          localStorage.setItem("showPopVisible", true);
          this.showVisible2 = localStorage.getItem("showPopVisible");
          this.percentage = parseInt(res.data.grade);
        }
        return;
      }
    },
   async isTeam(){
      if (this.userTeam.data && this.userTeam.data.auditStatus == 2) {
        let params = {
          idCard: this.userTeam.data.idCard,
          userType: 3,
          type: 3,
          targetId: this.userTeam.data.id,
        };
        let res = await jobScoringList(params);
        let showVisible = res.data.isSubmit ? true : false;
        if(showVisible){
          localStorage.setItem("showPopVisible", true);
          this.showVisible2 = localStorage.getItem("showPopVisible");
          this.percentage = parseInt(res.data.grade);
        }
        return;
      }
    },
    hideShow() {
      if(this.showVisible1 || this.showVisible2){
        this.showVisible1 = false;
        this.showVisible2 = false;
      }
      localStorage.setItem("showPopVisible", false);
      this.$emit("showCreate", false);
    },
    toCredit() {
      // setTimeout(() => {
      if (this.userAuth.data && this.userAuth.data.audit_status == "2" && this.showVisible1) {
        this.$router.push({
          path: "/personalCenter/creditrating",
          query: { idCard: this.userAuth.data.identity_code, val: 1 },
        });
      } else if (this.userTeam.data && this.userTeam.data.auditStatus == 2 && this.showVisible2) {
        this.$router.push({
          path: "/personalCenter/creditrating",
          query: { idCard: this.userTeam.data.idCard, val: 2 },
        });
      }
      //   }, 1000);
    }
  },
};
</script>

<style scoped lang="scss">
#scoring-mask {
  width: 100%;
  height: 100%;
  background-color: rgba(15, 15, 15, 0.6);
  bottom: 0;
  left: 0;
  position: fixed;
  color: #ffffff;
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
  .mask-left {
    width: 381px;
    font-size: 12px;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 30px;
    margin-right: -1%;
    z-index: 10;
    .left-span {
      font-size: 12px;
      margin-left: 20px;
      background-color: rgba(93, 172, 234, 0.5);
      border-radius: 35px 0 0 35px;
      box-sizing: border-box;
      padding: 10px 30px;
      overflow: hidden;
      width: 0;
      height: 0;
      position: absolute;
      right: -8%;
      transition: 0.4s;
    }
    .left-mask {
      width: 64px;
      height: 64px;
      cursor: pointer;
      line-height: 64px;
      background-color: #4e89f2;
      text-align: center;
      border-radius: 50%;
      position: absolute;
      right: -40px;
    }
  }
  .mask-left:hover .left-span{
    width: 351px;
    height: 63px;
    right: 0;
  }
  .mask-center {
    position: relative;
    text-align: center;
    font-size: 20px;
    .center-hide {
      width: 32px;
      height: 32px;
      background-color: #4e89f2;
      color: #ffffff;
      border-radius: 50%;
      font-size: 24px;
      text-align: center;
      margin: auto 0 auto auto;
      z-index: 100;
    }
    .center-info {
      position: absolute;
      z-index: 100;
      left: 46%;
      top: 48%;
      transform: translate(-50%, -50%);
      .info-grade {
        span {
          font-size: 72px;
        }
      }
    }
    .center-img {
      width: 520px;
      height: 520px;
      z-index: 1;
    }
    .center-btn {
      width: 192px;
      height: 35px;
      cursor: pointer;
      line-height: 35px;
      font-size: 24px;
      font-weight: bold;
      background: linear-gradient(90deg, #5fb1e8 0%, #2e62db 100%);
      border-radius: 35px;
      margin: 0 auto;
      text-align: center;
      margin-top: -60px;
      position: absolute;
      z-index: 100;
      left: 0;
      right: 20px;
      box-shadow: 0px 5px 2px 0px #2e62db;
    }
  }
  .mask-right {
    width: 381px;
    display: flex;
    font-size: 12px;
    align-items: center;
    position: relative;
    margin-bottom: 9%;
    margin-left: -60px;
    z-index: 10;
    .right-mask {
      cursor: pointer;
      width: 64px;
      height: 64px;
      background-color: #4e89f2;
      text-align: center;
      border-radius: 50%;
      position: absolute;
      left: -6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .right-span {
      width: 351px;
      height: 63px;
      margin-left: 35px;
      background-color: rgba(93, 172, 234, 0.5);
      border-radius: 0 35px 35px 0;
      box-sizing: border-box;
      padding: 10px 30px;
      overflow: hidden;
      width: 0;
      height: 0;
      position: absolute;
      left: -8%;
      transition: 0.4s;
      z-index:-1;
    }
  }
  .mask-right:hover .right-span{
    width: 351px;
    height: 63px;
    left: 0;
  }
}
</style>

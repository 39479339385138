<template>
  <!-- 禁止页面滚动收缩 @touchmove.prevent @mousewheel.prevent -->
  <div class="box">
    <homes ref="productImage"></homes>
    <div class="box-title">
      <!-- 背景图 -->
      <div
        class="register-img"
        :style="{ width: fullWidth + 'px', height: fullHeight + 'px' }"
      >
        <div class="img_box" :style="{ width: fullWidth + 'px' }"></div>
      </div>
      <!-- 图 -->
      <div class="img">
        <!-- 表头查询 -->
        <div class="search" id="header">
          <div class="title-info">
            <h1>甄工匠一打造建筑行业认可的人才信用评价平台</h1>
            <p class="title-text">
              运用区块链技术,构建新型工业化人才评价体系，
              让企业人力资源管理服务变得更加智能高效
            </p>
            <div class="title-bottom">
              <div class="solid"></div>
              <img class="polygon" src="../../img/Polygon.png" alt="" />
              <div class="solid"></div>
            </div>
          </div>
          <div class="search-input" style="margin-top: 15px">
            <div class="input-icon"><i class="el-icon-search"></i></div>
            <input
              class="input-search"
              placeholder="请输入身份证号或者姓名查询"
              @change="evaluateInput"
              v-model="evaluateValue"
            />
            <div class="input-btn" @click="toEvaluation">查询评价</div>
          </div>
        </div>
      </div>
    </div>
    <!-- nav -->
    <div class="nav-box">
      <div
        class="nav-bosx"
        @mousemove="tabHaver(1)"
        :class="[tabIndex == 1 ? 'active' : '']"
      >
        <img class="img" src="../../img/Group 810@3x.png" alt="" />
        <img src="../../img/Group 944@3x.png" alt="" />
        <div class="img-info">
          <p class="xingyong">信用评价</p>
          <p class="ren">劳务/班组/个人</p>
        </div>
      </div>
      <div
        class="nav-bosx"
        @mousemove="tabHaver(2)"
        :class="[tabIndex == 2 ? 'active' : '']"
      >
        <img src="../../img/Group 953@3x.png" alt="" />
        <div class="img-info">
          <p class="xingyong">行业动态</p>
          <p class="ren">行业政策/法规/快讯</p>
        </div>
      </div>
      <div
        class="nav-bosx"
        @mousemove="tabHaver(3)"
        :class="[tabIndex == 3 ? 'active' : '']"
      >
        <img src="../../img/Group 950@3x.png" alt="" />
        <div class="img-info">
          <p class="xingyong">考证及培训</p>
          <p class="ren">考证类/拿证类/就业培训</p>
        </div>
      </div>
      <div
        class="nav-bosx"
        @mousemove="tabHaver(4)"
        :class="[tabIndex == 4 ? 'active' : '']"
      >
        <img src="../../img/Group 952@3x.png" alt="" />
        <div class="img-info">
          <p class="xingyong">人才招募</p>
          <p class="jianli">热门简历/靠谱企业/招聘会</p>
        </div>
      </div>
    </div>
    <!-- 切换内容 -->
    <div class="tab-box">
      <div class="tab" v-show="tabIndex == 1">
        <div class="triangle-first"></div>
        <div class="left-tab">
          <div class="info">
            <div class="titleName">
              <div>
                <span class="solid"></span>
                <span class="text"> 人才推荐 </span>
              </div>
              <div class="more" @click.stop="toEllent()">查看更多</div>
            </div>

            <div class="content-info">
              <template>
                <div
                  class="own-info"
                  v-for="(item, i) in creditList"
                  :key="i"
                  @click.stop="toEvaluationDetail(item)"
                >
                  <img
                    class="content-info-img"
                    :src="item.avatar ? $imgUrl(item.avatar) : srcImgUrl1"
                    :onerror="$global.srcImgUrl"
                    alt=""
                  />
                  <div class="content">
                    <div class="name-data">
                      <span>{{ $processName(item.name) }}</span>
                      <!-- <div class="number">{{ item.grade }}</div> -->
                      <div
                        class="number"
                        v-if="item.grade >= 80"
                        :style="{
                          color: gradeColor[0],
                          borderColor: gradeColor[0],
                        }"
                      >
                        {{ item.grade }}
                      </div>
                      <div
                        class="number"
                        v-if="item.grade < 80 && item.grade >= 60"
                        :style="{
                          color: gradeColor[1],
                          borderColor: gradeColor[1],
                        }"
                      >
                        {{ item.grade }}
                      </div>
                      <div
                        class="number"
                        v-if="item.grade < 60 && item.grade >= 40"
                        :style="{
                          color: gradeColor[2],
                          borderColor: gradeColor[2],
                        }"
                      >
                        {{ item.grade }}
                      </div>
                      <div
                        class="number"
                        v-if="item.grade < 40"
                        :style="{
                          color: gradeColor[3],
                          borderColor: gradeColor[3],
                        }"
                      >
                        {{ item.grade }}
                      </div>
                    </div>

                    <div class="name-info">
                      <span>{{ item.sex == '2' ? '女' : '男' }}</span>
                      <span>|</span>
                      <span>{{ item.age ? item.age : '' }}岁</span>
                      <span>|</span>
                      <span>{{ item.nation ? item.nation : '汉' }}族</span>
                    </div>
                    <span class="name-phone">{{ geTel(item.phone) }}</span>
                    <span class="name-city">
                      <span v-if="item.province"
                        >{{ getProvinceForId(item.province) }} ·</span
                      >
                      {{ item.city ? getCityForId(item.city) : '' }}
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <!-- <div class="tab-solid"> --><!-- </div> -->
          <!-- <div class="info">
                <div class="titleName">
               <div>
                <span class="solid"></span>
                <span class="text">
                  优秀班组
                </span>
                </div>
                <div class="more">
                  查看更多
                </div>
              </div>
               <div class="content-info">
                  <div class="own-info" v-for="(item,index) in ownList" :key="index">
                    <img class="content-info-img" src="../../img/own-img.png" alt="">
                    <div class="content-group">
                      <div class="name-data">
                         <span> <span class="group">班组长:</span> {{ item.name }}</span>
                         <div class="number">{{ item.num }}</div>
                      </div>

                    <p class="name-info">
                      <i class="el-icon-user"></i>
                      18人
                    </p>
                    <p class="name-phone">建筑木工/铝模/拆模</p>
                    <p class="name-city">{{ item.city }}</p>
                    </div>
                  </div>
                </div>-->
        </div>

        <div class="right-tab">
          <!-- <div class="head">
            <div class="evaluate">
              <img class="evaluate-img" src="../../img/bg-color1.png" alt="" />
              <div class="title">
                <span class="pj">
                  <i
                    class="iconfont icon-a-pinglunxiaoxi-07"
                    style="font-size: 13px"
                  ></i>
                </span>
                <span class="evaluate-pj">自我评价</span>
              </div>
            </div>
            <div class="evaluate">
              <img class="evaluate-img" src="../../img/bg-color2.png" alt="" />
              <div class="title">
                <span class="pj">
                  <i class="iconfont icon-guize" style="font-size: 13px"></i>
                </span>
                <span class="evaluate-pj">评价规则</span>
              </div>
            </div>
          </div> -->

          <div class="huawei-link" @click="$router.push('/chainList')">
            华为链查询
            <!-- <img src="@/assets/img/huaweiLink.png" alt=""> -->
          </div>

          <div class="evaluate-nav">
            <vue-seamless-scroll
              :data="upperList"
              :class-option="optionLeft"
              class="evaluate-warp"
            >
              <ul class="item">
                <li v-for="(item, ind) in upperList" :key="ind">
                  <div
                    class="dian"
                    style="margin: 0 30px; font-size: 30px; color: #cccccc"
                  >
                    <img
                      src="@/assets/img/lj.png"
                      style="width: 16px; height: 16px"
                    />
                  </div>
                  <div class="text">{{ $processName(item.name) }}信息已</div>
                  <a class="lia" href="#" style="color: #ff8b50">【上链】</a>
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
      <div class="tab" v-show="tabIndex == 2">
        <div class="triangle-second"></div>
        <div class="swipe-tab">
          <div class="titleName">
            <div>
              <span class="solid"></span>
              <span class="text"> 平台资讯 </span>
            </div>
          </div>
          <div class="swipe">
            <div class="leftArrow" @click="arrowClick('left')">
              <i class="el-icon-arrow-left"></i>
            </div>
            <el-carousel
              :autoplay="true"
              arrow="never"
              indicator-position="none"
              ref="cardShow"
            >
              <el-carousel-item
                class="swipe-img"
                v-for="(item, nIndex) in platform.slice(0, 3)"
                :key="nIndex"
              >
                <img
                  class="swipe-img"
                  :src="$imgUrl(item.litimg)"
                  :onerror="$global.srcImgUrl"
                  @click.stop="toContent(item)"
                  alt=""
                />
                <div class="title-text" @click.stop="toContent(item)">
                  {{ item.title }}
                </div>
              </el-carousel-item>
            </el-carousel>

            <div class="rightArrow" @click="arrowClick('right')">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
        <div class="center-tab">
          <div
            class="industry"
            v-for="(item, nindex) in platform.slice(3, 6)"
            :key="nindex"
            @click.stop="toContent(item)"
          >
            <div class="text-info">{{ item.title }}</div>
            <div class="span">
              {{ moment(item.publishTime).format('YYYY-MM-DD') }}
            </div>
          </div>
        </div>
        <div class="tabs-right">
          <div class="tabs">
            <el-tabs v-model="tabName" @tab-click="onClick">
              <el-tab-pane
                :label="tab.name"
                :name="tab.id"
                v-for="(tab, ix) in tabList.slice(0, 3)"
                :key="ix"
                :class="{ active: itemIndex == 1 }"
                style="padding: 0 16px"
              >
                <div class="content">
                  <div
                    class="content-box"
                    v-for="(item, id) in newsList.slice(0, 6)"
                    :key="id"
                    @click.stop="toContent(item)"
                  >
                    <div class="left">
                      <div class="dian">·</div>
                      <span
                        class="text"
                        style="
                          -webkit-line-clamp: 1;
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                        "
                        >{{ item.title }}</span
                      >
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="more" @click="gowen">查看更多></div>
        </div>
      </div>
      <div class="tab" v-show="tabIndex == 3">
        <div class="triangle-third"></div>
        <div class="flex-tab">
          <div class="info-one">
            <div class="titleName">
              <div>
                <span class="solid"></span>
                <span class="text"> 课程信息 </span>
              </div>
              <div class="more" @click.stop="toOnlineLearning">查看更多</div>
            </div>
            <div class="content-info" v-if="criticismList.length > 0">
              <div
                class="own-info"
                v-for="(item, dex) in criticismList"
                :key="dex"
                @click.stop="toCourseDetails('1', item)"
              >
                <img
                  class="content-info-img"
                  :src="$imgUrl(item.litimg)"
                  :onerror="$global.srcImgUrl"
                  alt=""
                />
                <div class="content-group">
                  <div class="name-data">
                    <span>{{ item.title }}</span>
                  </div>
                  <div class="name-info">{{ item.subTitle }}</div>
                  <div class="name-num">{{ item.sumApplyNum }} 人已报名</div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-solid"></div>
          <div class="info-two">
            <div class="titleName">
              <div>
                <span class="solid"></span>
                <span class="text"> 培训信息 </span>
              </div>
              <div class="more" @click.stop="toOnlineLearning">查看更多</div>
            </div>
            <div class="content-info">
              <div
                class="own-info"
                v-for="(item, ex) in trainList"
                :key="ex"
                @click.stop="toCourseDetails('2', item)"
              >
                <img
                  class="content-info-img"
                  :src="$imgUrl(item.litimg)"
                  :onerror="$global.srcImgUrl"
                  alt=""
                />
                <div class="content-group">
                  <div class="name-data">
                    <span>{{ item.title }}</span>
                  </div>
                  <div class="name-info">{{ item.subTitle }}</div>
                  <div class="name-num">{{ item.applyNum }} 人已报名</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab" v-show="tabIndex == 4">
        <div class="triangle-fourth"></div>
        <div class="tabs-list">
          <div class="tabs">
            <el-tabs v-model="fairName" @tab-click="handleClick">
              <el-tab-pane name="first">
                <span slot="label" class="tab-third">招聘会</span>
                <div class="content">
                  <div
                    class="content-box"
                    v-for="(item, x) in list"
                    :key="x"
                    @click.stop="toJobFairDetails1(item.id)"
                  >
                    <div class="head">
                      <img
                        class="head-img"
                        :src="$imgUrl(item.litimg)"
                        :onerror="$global.srcImgUrl"
                        alt=""
                      />
                      <div class="title">
                        {{ item.title }}
                      </div>
                    </div>
                    <div class="info">
                      {{ item.intro }}
                    </div>
                    <div class="floor">
                      <div class="left">
                        <i class="el-icon-time"></i>
                        <span class="time">{{
                          moment(item.beginTime).format('YYYY-MM-DD')
                        }}</span>
                      </div>
                      <div class="right">
                        <el-button
                          type="primary"
                          @click.stop="toJobFairDetails1(item.id)"
                          >{{ timeMoment(item) }}</el-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="second">
                <span slot="label" class="tab-third">往期招聘会</span>
                <div class="content">
                  <div
                    class="content-box"
                    v-for="(item, u) in carerList"
                    :key="u"
                    @click.stop="toJobFairDetails1(item.id, 1)"
                  >
                    <div class="head">
                      <img
                        class="head-img"
                        :src="$imgUrl(item.litimg)"
                        :onerror="$global.srcImgUrl"
                        alt=""
                      />
                      <div class="title">
                        {{ item.title }}
                      </div>
                    </div>
                    <div class="info">
                      {{ item.intro }}
                    </div>
                    <div class="floor">
                      <div class="left">
                        <i class="el-icon-time"></i>
                        <span class="time">{{
                          moment(item.endApplyTime).format('YYYY-MM-DD')
                        }}</span>
                      </div>
                      <div class="right">
                        <el-button
                          type="primary"
                          @click.stop="toJobFairDetails1(item.id, 1)"
                          >已举办</el-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="more" @click="toJobFair">查看更多</div>
        </div>
      </div>
    </div>

    <!-- 核心企业 -->
    <div class="enterprise">
      <div class="enterprise-title">
        <i class="el-icon-s-comment"></i>
        <div class="he-xin">核心企业</div>
      </div>
      <div class="solid"></div>
      <div class="userImage">
        <vue-seamless-scroll :data="coreList" :class-option="optionRight">
          <ul class="userImageAllWrapper">
            <li v-for="item in coreList" :key="item.index">
              <div class="span">
                {{ item.name }}
                <span class="text" style="color: #ff6000">
                  [{{
                    item.properties
                      ? $dictInfo.getOrgType(item.properties)
                      : ''
                  }}]
                </span>
                <span>入驻</span>
              </div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
    <!-- 点击切屏(定位全屏右侧) -->
    <div class="cut-screen">
      <div class="screen-info" @click="moveScrollIntoView('enterprise')">
        <img src="@/assets/icon/enterprise.png" alt="" />
        <div>找企业</div>
      </div>
      <!-- <div class="screen-info" @click="moveScrollIntoView('service')">
              <img src="@/assets/icon/labour.png" alt="">
             <div> 找劳务</div>
            </div> -->
      <div class="screen-info" @click="moveScrollIntoView('personnel')">
        <img src="@/assets/icon/recruitment.png" alt="" />
        <div>找人才</div>
      </div>
      <!-- <div class="screen-info" @click="moveScrollIntoView('team')">
        <img src="@/assets/icon/groups.png" alt="" />
        <div>招班组</div>
      </div> -->
      <div class="screen-info" @click="moveScrollIntoView('move')">
        <img src="@/assets/icon/hot.png" alt="" />
        <div>热招岗位</div>
      </div>
      <div class="screen-info" @click="moveScrollIntoView('billboard')">
        <img src="@/assets/icon/topic.png" alt="" />
        <div>热搜榜单</div>
      </div>
      <div class="screen-info" @click="sendMessage">
        <img src="@/assets/icon/zaixiankefu.png" alt="" />
        <div>在线客服</div>
      </div>
      <div class="screen-info" @click="moveScrollIntoView('header')">
        <img src="@/assets/icon/overstory.png" alt="" />
        <div>回到顶部</div>
      </div>
    </div>
    <!-- 找企业 -->
    <div class="find-enterprise" id="enterprise">
      <div class="head-title">
        <img class="title-img" src="@/assets/home/title-img-left.png" alt="" />
        <div class="title-text">找企业</div>
        <img class="title-img" src="@/assets/home/title-img-right.png" alt="" />
      </div>
      <div class="enterprise-info">推荐企业</div>
      <div class="ct-box" id="input-style">
        <div
          class="left-ct"
          @click.stop="toEnterpriseDetails(showList.jobOrgId)"
        >
          <img
            :src="$imgUrl(showList.logo)"
            :onerror="$global.srcImgUrl"
            alt=""
          />
          <p>{{ showList.name }}</p>
        </div>
        <div class="lunbu">
          <el-carousel
            height="400px"
            trigger="click"
            :autoplay="false"
            arrow="never"
            class="carouselShow"
          >
            <el-carousel-item>
              <div class="lun-box">
                <div class="lun-yang">
                  <div class="tab">
                    <div
                      class="welfare"
                      v-for="(item, index) in showList.welfare"
                      :key="index"
                      :style="welfareBgc[index]"
                    >
                      {{ item }}
                    </div>
                  </div>
                  <p class="lun-zi" @click.stop="swiperContentShow(showList)">
                    {{ showList.intro }}
                  </p>

                  <!-- <div class="di">
                    <div
                      class="diq"
                      v-for="(item, index) in showList.jobPostList"
                      :key="index"
                      @click="toDatum(item.id)"
                    >
                      <div class="di-box">
                        <div class="ceo">{{ item.name }}</div>
                        <div class="qian">{{ item.pay }}</div>
                      </div>
                      <div class="dizhi">
                        {{ item.city }} | {{ getPostEdu(item.edu) }} |
                        {{ getPostExp(item.exp) }}
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item
              v-for="(item, index) in showList.children"
              :key="index"
            >
              <div class="lun-box">
                <div
                  class="lun-yang"
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <p
                    class="intro"
                    style="padding: 0 38px"
                    @click.stop="swiperContentShow(item)"
                  >
                    {{ item.intro }}
                  </p>
                  <div
                    class="imgBox"
                    style="
                      margin-bottom: 0px;
                      margin-top: auto;
                      padding: 0 24px;
                    "
                  >
                    <img
                      :src="$imgUrl(logo)"
                      :onerror="$global.srcImgUrl"
                      v-for="(logo, logoIndex) in item.logo"
                      :key="logoIndex"
                      alt=""
                      style="margin: 0 5px"
                    />
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="recommend">
          <div class="recommend-title">知名企业推荐</div>
          <div class="recommend-info">
            <div
              class="info"
              v-for="(item, ucx) in famousList"
              :key="ucx"
              @click.stop="toEnterpriseDetails(item.jobOrgId)"
              style="cursor: pointer"
            >
              <span class="dian">·</span>
              <div class="text">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="pane-list">
        <div class="pane-list-input">
          <div class="input-icon"><i class="el-icon-search"></i></div>
          <input
            class="input-search"
            placeholder="请输入您想搜索的企业名称"
            v-model="searchValue"
            @change="searchInput"
          />
        </div>
        <div class="business-list" v-if="findList.length > 0">
          <div
            class="business"
            v-for="(item, udx) in findList.slice(0, 5)"
            :key="udx"
            @click.stop="toEnterpriseDetails(item.id)"
          >
            <div class="img-box" v-if="item.logo && item.logo != 'null'">
              <img
                :src="$imgUrl(item.logo)"
                :onerror="$global.srcImgUrl"
                alt=""
                class="img"
              />
            </div>
            <div class="cLogo" v-else>
              <span>{{ getFirstWord(item.name) }}</span>
            </div>
            <div class="title">{{ item.name }}</div>
            <div class="info">
              {{ item.properties ? getOrgType(item.properties) : '' }}
              <span>·</span>
              {{ item.size ? getGm(item.size) : '' }}
              <span>·</span>
              {{ item.orgType ? getTradeType(item.orgType) : '' }}
            </div>
            <div class="post" v-if="item.postList.length > 0">
              热招<span>{{ item.postList[0].name }}</span
              >等<span>{{ item.postList.length }}</span
              >个岗位
            </div>
            <div class="post" v-else>暂无岗位招聘</div>
          </div>
        </div>
        <el-empty v-if="findList.length == 0" description="空空如也"></el-empty>
        <div class="more" v-if="findList.length > 0">
          <el-button class="more-btn" type="primary" @click.stop="toPosition()"
            >查看更多</el-button
          >
        </div>
      </div>
    </div>
    <!-- 找劳务 -->
    <!-- <div class="find-service" id="service">
          <div class="head-title">
            <img class="title-img" src="@/assets/home/title-img-left.png" alt="">
            <div class="title-text">找劳务</div>
            <img class="title-img" src="@/assets/home/title-img-right.png" alt="">
          </div>
          <div class="recommend-service">
            <div class="title">
              <div class="text">品牌劳务</div>
              <div class="more">更多</div>
            </div>
            <div class="recommend-list">
                <div class="rec-info" v-for="item in 4">
                  <div class="head">
                    <img class="img" src="~@/assets/home/image.png.png" alt="">
                    <div class="right-info">
                      <div class="res-title">湖北聚沃赢建筑劳务有限公司</div>
                      <div class="title-info">民营公司 <span>| </span> 20-99人 <span>|</span> 建筑/建材/工程</div>
                      <div class="label-list">
                        <div class="label">铲车/装载机</div>
                        <div class="label">搅拌罐车/泵车司机/搅拌站</div>
                      </div>
                    </div>
                  </div>
                  <div class="head-info">
                      公司致力于国外项目分包及招聘主要是做工程领域房建．路桥．港口等基础建设和装修。
                  </div>
                </div>
            </div>
          </div>
          <div class="brand-service">
            <div class="title">
              <div class="text">推荐劳务</div>
            </div>
            <div class="business-list">
                  <div class="business" v-for="item in 5">
                  <img class="img" src="~@/assets/home/image.png.png" alt="">
                  <div class="ser-title">深圳市建筑设计研究总院有限公司北京分院</div>
                  <div class="info">国有企业 <span>·</span> 100-499人 <span>·</span> 建筑设计</div>
                  <div class="post">
                    武汉万中宏强建筑劳务有限公司自2013年成立至今，已.发展为拥有独立注册资金1380万元;拥有各类固定建筑工人5000...
                  </div>
                  </div>
            </div>
            <div class="more">
                   <el-button class="more-btn" type="primary">查看更多</el-button>
              </div>
          </div>
        </div> -->

    <!-- 找班组 -->
    <!-- <div class="find-team" id="team">
          <div class="head-title">
            <img class="title-img" src="@/assets/home/title-img-left.png" alt="">
            <div class="title-text">找班组</div>
            <img class="title-img" src="@/assets/home/title-img-right.png" alt="">
        </div>
          <div class="enterprise-info" style="padding-top: 40px;font-size: 20px;font-weight: bold;">优秀班组</div>-->

    <!-- <div class="team">

              <div class="title">
                <div class="text">品牌劳务</div>
                <div class="more">更多</div>
              </div>

              <div class="team-list">
                  <div class="rec-info" v-for="item in 2">
                  <div class="head">
                    <img class="img" src="~@/assets/home/image.png.png" alt="">
                    <div class="right-info">
                      <div class="res-title">湖北聚沃赢建筑劳务有限公司</div>
                      <div class="title-info">民营公司 <span>| </span> 20-99人 <span>|</span> 建筑/建材/工程</div>

                    </div>
                    <div class="address">
                        <i class="el-icon-location-outline icon"></i>
                        <span class="address-name">湖北 <span class="dian">·</span>武汉</span>
                    </div>
                  </div>
                  <div class="label-list">
                        <div class="label">铲车/装载机</div>
                        <div class="label">搅拌罐车/泵车司机/搅拌站</div>
                  </div>
                  <div class="head-info">
                      公司致力于国外项目分包及招聘主要是做工程领域房建．路桥．港口等基础建设和装修。
                  </div>
                  </div>
              </div>
          </div> -->
    <!-- <div class="team-pane-list"> -->
    <!-- <el-tabs v-model="tabName" stretch="true" @tab-click="" class="table"> -->

    <!-- <div class="business-list" style="padding-top: 47px;padding-left: 29px;">
                  <div class="business" v-if="index < 9"  v-for="(item,index) in creditList" :key="index" >
                  <div class="head"> -->
    <!-- <img class="img" src="~@/assets/home/image.png.png" alt=""> -->
    <!-- <img class="img" :src="item.avatar" alt="" />
                    <div class="right-info">
                      <div class="res-title">
                        <div class="user-name">{{item.name}}</div>
                        <div class="address">
                        <i class="el-icon-location-outline icon"></i>
                        <span class="address-name">{{item.city}} <span class="dian">·</span>武汉</span>
                        </div>
                       </div>
                      <div class="title-info">民营公司 <span>| </span> 20-99人 <span>|</span> 建筑/建材/工程</div>

                    </div>
                  </div>
                  <div class="label-list">
                        <div class="label">铲车/装载机</div>
                        <div class="label">搅拌罐车/泵车司机/搅拌站</div>
                  </div>
                  <div class="head-info">
                      公司致力于国外项目分包及招聘主要是做工程领域房建．路桥．港口等基础建设和装修。
                  </div>
                  </div>
                </div> -->
    <!-- <el-tab-pane label="建筑设计" name="2">配置管理</el-tab-pane>
              <el-tab-pane label="工程施工" name="3">角色管理</el-tab-pane>
              <el-tab-pane label="市政路桥" name="4">定时任务补偿</el-tab-pane>
              <el-tab-pane label="轨道交通" name="5">用户管理</el-tab-pane>
              <el-tab-pane label="装配式建筑" name="6">配置管理</el-tab-pane>
              <el-tab-pane label="水利水电" name="7">角色管理</el-tab-pane>
              <el-tab-pane label="园林景观" name="8">定时任务补偿</el-tab-pane>
              <el-tab-pane label="环境工程" name="9">定时任务补偿</el-tab-pane>
            </el-tabs> -->
    <!-- </div>
        </div>  -->

    <!-- 找人才 -->
    <div class="find-personnel" id="personnel">
      <div class="head-title">
        <img class="title-img" src="@/assets/home/title-img-left.png" alt="" />
        <div class="title-text">找人才</div>
        <img class="title-img" src="@/assets/home/title-img-right.png" alt="" />
      </div>
      <div class="team-pane-list">
        <div class="business-list">
          <div
            class="business"
            v-for="(item, ndex) in talentedList"
            :key="ndex"
            @click.stop="toJobInformationDetails(item)"
          >
            <div class="head">
              <!-- <img class="img" src="~@/assets/home/image.png.png" alt=""> -->
              <img
                class="img"
                :src="item.avatar ? $imgUrl(item.avatar) : srcImgUrl1"
                :onerror="$global.srcImgUrl"
                alt=""
              />
              <div class="right-info">
                <div class="res-title">
                  <div class="user-name">{{ $processName(item.name) }}</div>
                  <div class="occupation">
                    {{ getExpectPostForId(orgList, item.expectPost) }}
                  </div>
                </div>
                <div class="title-info">
                  {{ item.sex == '2' ? '女' : '男' }} <span>| </span>
                  {{ item.age }}岁 <span>|</span>{{ getPostEdu(item.edu)
                  }}<span>|</span>{{ getPostExp(item.exp) }}
                </div>
              </div>
            </div>
            <div class="label-list">
              <div class="address">
                <i class="el-icon-location-outline icon"></i>
                <span class="address-name"
                  >{{ item.province ? getProvinceForId(item.province) : '' }}
                  <span v-if="item.province">·</span>
                  {{ item.city ? getCityForId(item.city) : '' }}</span
                >
              </div>
              <div class="labe-info">
                <div></div>
                <div class="time">
                  {{
                    item.create_time
                      ? moment(item.create_time).format('YYYY-MM-DD')
                      : ''
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="more">
        <el-button
          class="more-btn"
          type="primary"
          @click.stop="toJobInformation()"
          >查看更多</el-button
        >
      </div>
    </div>
    <!-- 热招岗位 -->
    <div class="hot-move" id="move">
      <div class="head-title">
        <img class="title-img" src="@/assets/home/title-img-left.png" alt="" />
        <div class="title-text">热招岗位</div>
        <img class="title-img" src="@/assets/home/title-img-right.png" alt="" />
      </div>
      <div class="team-pane-list">
        <div class="business-list">
          <div
            class="business"
            v-for="(item, uindex) in postList"
            :key="uindex"
            @click.stop="toDatum(item.postId)"
          >
            <div class="head">
              <div class="right-info">
                <div class="res-title">
                  <div class="res-name">{{ item.name }}</div>
                  <div class="pay">
                    {{ item.pay ? getPay(item.pay) : '' }}
                  </div>
                </div>
                <div class="title-info">
                  {{ getCityForId(item.city) }}
                  <span>| </span>{{ item.exp ? getPostExp(item.exp) : ''
                  }}<span>|</span>{{ item.edu ? getPostEdu(item.edu) : '' }}
                </div>
              </div>
            </div>
            <div class="label-list">
              <img
                v-if="item.logo && item.logo != 'null'"
                :src="$imgUrl(item.logo)"
                :onerror="$global.srcImgUrl"
                alt=""
                class="img"
              />
              <div class="cLogo" v-else>
                <span>{{
                  item.orgName ? getFirstWord(item.orgName) : ''
                }}</span>
              </div>
              <div class="labe-info">
                <div>{{ item.orgName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="more">
        <el-button class="more-btn" type="primary" @click.stop="toPosition()"
          >查看更多</el-button
        >
      </div>
    </div>
    <!-- 热搜榜单 -->
    <div class="hot-billboard" id="billboard">
      <div class="head-title">
        <img class="title-img" src="@/assets/home/title-img-left.png" alt="" />
        <div class="title-text">热搜榜单</div>
        <img class="title-img" src="@/assets/home/title-img-right.png" alt="" />
      </div>

      <div class="billboard-content">
        <div class="billboard-rank">
          <div
            class="ranking-1"
            v-if="hotList[0]"
            @click.stop="toEnterpriseDetails(hotList[0].jobOrgId)"
          >
            <div class="prize">
              <img class="prize-img" src="~@/assets\home\prize-11.png" alt="" />
              <img class="prize-img2" src="~@/assets\home\prize-1.png" alt="" />
            </div>
            <div class="ranking-info">
              <div
                class="img-box"
                v-if="hotList[0].logo && hotList[0].logo != 'null'"
              >
                <img
                  :src="$imgUrl(hotList[0].logo)"
                  :onerror="$global.srcImgUrl"
                  alt=""
                  class="img"
                />
              </div>
              <div class="cLogo" v-else>
                <span>{{ getFirstWord(hotList[0].name) }}</span>
              </div>
              <div class="ranking-title">{{ hotList[0].name }}</div>
            </div>
            <div class="ranking-text">{{ hotList[0].welfare }}</div>
          </div>

          <div class="ranking-2" v-if="hotList[1]">
            <div class="prize">
              <img class="prize-img" src="~@/assets\home\prize-2.png" alt="" />
              <img
                class="prize-img2"
                src="~@/assets\home\prize-21.png"
                alt=""
              />
            </div>
            <div
              class="ranking-info"
              @click.stop="toEnterpriseDetails(hotList[1].jobOrgId)"
            >
              <div
                class="img-box"
                v-if="hotList[1].logo && hotList[1].logo != 'null'"
              >
                <img
                  :src="$imgUrl(hotList[1].logo)"
                  :onerror="$global.srcImgUrl"
                  alt=""
                  class="img"
                />
              </div>
              <div class="cLogo" v-else>
                <span>{{ getFirstWord(hotList[1].name) }}</span>
              </div>
              <div class="ranking-title">{{ hotList[1].name }}</div>
            </div>
            <div class="ranking-text">{{ hotList[1].welfare }}</div>
          </div>
          <div class="ranking-3" v-if="hotList[2]">
            <div class="prize">
              <img class="prize-img" src="~@/assets\home\prize-31.png" alt="" />
              <img class="prize-img2" src="~@/assets\home\prize-3.png" alt="" />
            </div>
            <div
              class="ranking-info"
              @click.stop="toEnterpriseDetails(hotList[2].jobOrgId)"
            >
              <div
                class="img-box"
                v-if="hotList[2].logo && hotList[2].logo != 'null'"
              >
                <img
                  :src="$imgUrl(hotList[2].logo)"
                  :onerror="$global.srcImgUrl"
                  alt=""
                  class="img"
                />
              </div>
              <div class="cLogo" v-else>
                <span>{{ getFirstWord(hotList[2].name) }}</span>
              </div>
              <div class="ranking-title">{{ hotList[2].name }}</div>
            </div>
            <div class="ranking-text">{{ hotList[2].welfare }}</div>
          </div>
          <div
            class="ranking-4"
            v-for="(item, index) in hotList.slice(3, 12)"
            :key="index"
            @click.stop="toEnterpriseDetails(item.jobOrgId)"
          >
            <div class="ranking-info">
              <div class="img-box" v-if="item.logo && item.logo != 'null'">
                <img
                  :src="$imgUrl(item.logo)"
                  :onerror="$global.srcImgUrl"
                  alt=""
                  class="img"
                />
              </div>
              <div class="cLogo" v-else>
                <span>{{ getFirstWord(item.name) }}</span>
              </div>
              <div class="ranking-title">{{ item.name }}</div>
            </div>
            <div class="ranking-text">
              {{ item.welfare }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="billboard-left">
    <div class="star">
    <div class="title">
     <div class="text">行业明星企业</div>
    </div>
    <div class="star-enter">
      <div class="star-list" v-for="(item,index) in hotList" :key="index">
        <div class="head">
          <div class="enter">
            <img class="img" src="~@/assets/home/image.png.png" alt="">
            <div class="text">深圳市建筑设计研究总院有限公司北京分院</div>
          </div>
          <div class="info">
            国有企业 <span>·</span>
            100-499人 <span>·</span>
            建筑设计
          </div>
        </div>
        <div class="nav-list">
          <div class="nav">
            <div class="nav-title">
              <div class="post">电气工程师</div>
              <div class="pay">5k-8k</div>
            </div>
            <div class="nav-info">
              武汉-汉阳区|1-3年|大专
            </div>
          </div>
          <div class="nav">
            <div class="nav-title">
              <div class="post">电气工程师</div>
              <div class="pay">5k-8k</div>
            </div>
            <div class="nav-info">
              武汉-汉阳区|1-3年|大专
            </div>
          </div>
          <div class="nav">
            <div class="nav-title">
              <div class="post">电气工程师</div>
              <div class="pay">5k-8k</div>
            </div>
            <div class="nav-info">
              武汉-汉阳区|1-3年|大专
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="must-cast">
    <div class="title">
    <div class="text">行业必投公司</div>
  </div>
  <div class="must-enter">
      <div class="must-list" v-for="(item,index) in hotList" :key="index">
            <img class="img" src="~@/assets/home/image.png.png" alt="">
          <div class="text">深圳市建筑设计研究总院有限公司北京分院</div>
          <div class="info">
            国有企业 <span>·</span>
            100-499人 <span>·</span>
            建筑设计
          </div>
         <div class="labels">
          <div class="label">五险一金</div>
          <div class="label">周末双休</div>
          <div class="label">高温补贴</div>
          <div class="label">高温补贴</div>
         </div>
      </div>
    </div>
    </div>
  </div> -->
    <!-- 信用评价弹窗 -->
    <popMask></popMask>
    <!-- 底部 -->
    <footerss></footerss>
    <el-dialog
      :visible.sync="introVisible"
      width="50%"
      :modal="false"
      :before-close="
        () => {
          introVisible = false
        }
      "
    >
      {{ swiperIntro }}
    </el-dialog>
    <videoMask></videoMask>
    <el-dialog
      :visible.sync="visibleIM"
      width="1040px"
      top="10vh"
      :before-close="handleClose"
    >
      <Notice v-if="visibleIM" type="home"></Notice>
    </el-dialog>
  </div>
</template>
<script>
import homes from '@/components/hometitle/hometitle.vue'
import popMask from '@/components/popMask/popMask.vue'
import videoMask from '@/components/popMask/videoMask.vue'
import footerss from '@/components/footer/footers.vue'
import { getDict } from '@/api/system/system'
import { getProvincial, getCity, getArea } from '@/api/index/index'
// import { detailResume } from "@/api/personalCenter/personalCenter";
// import marquee from "@/components/marquee/marquee.vue";
import vueSeamlessScroll from 'vue-seamless-scroll'
import { readInfo } from '@/utils/readInfo.js'
import moment from 'moment'
import Notice from '@/components/notice/notice.vue'
// import { mapState } from 'vuex'

import {
  rotationChart,
  jobNewsColumnTree,
  jobNews,
  detail,
  jobOrgAllPage,
  jobEvaluation,
  jcCoursePage,
  jobFairApplyList,
  jobResumePage,
  jobFairPage,
  queryJobInfoPage,
  newEvaluation,
  jcTrainingPage,
  findJobOrg,
  jobPostType,
} from '@/api/home/home'
import { jobEvaluationData } from '@/api/evaluation/evaluation'
export default {
  components: {
    footerss,
    homes,
    vueSeamlessScroll,
    popMask,
    videoMask,
    Notice
  },
  computed: {
    // ...mapState(['peopleList']),
    optionLeft() {
      return {
        step: 0.5,
        direction: 1, // 0向下 1向上 2向左 3向右
        hoverStop: true,
        openTouch: false,
      }
    },
    optionRight() {
      return {
        step: 1,
        openTouch: false,
        direction: 2,
        hoverStop: true,
      }
    },
  },
  data() {
    return {
      introVisible: false,
      swiperIntro: '',
      limit: 4,
      gradeColor: ['#67CF61', '#3B94F7', '#e6a23c', '#ff0000'],
      // srcImgUrl : require('@/assets/home/affiliated-img.png'),
      srcImgUrl:
        'this.src="' + require('@/assets/home/affiliated-img.png') + '"',
      srcImgUrl1: require('../../assets/home/people.png'),
      tabIndex: 1,
      itemIndex: 1,
      tabName: '',
      fairName: 'first',
      ownList: [],
      grade: '',
      // provinceList: [],
      fullWidth: document.documentElement.clientWidth,
      fullHeight: document.documentElement.clientHeight,
      // 企业搜索数据,
      searchValue: '',
      // 评价搜索数据,
      evaluateValue: '',
      dict: [],
      orgType: [],
      jobEdu: [],
      jobExp: [],
      jobPay: [],
      minid: '',
      tabPosition: 'left',
      xian: 0,
      ntime: '',
      // tab: [
      //   { name: "首页" },
      //   { name: "职位搜索" },
      //   { name: "求职信息" },
      //   { name: "在线学习" },
      //   { name: "关于我们" },
      // ],
      tabList: [],
      disabled: false, //按钮禁用
      isSignUp: false, //是否报名
      // rotationChartList: [], // 轮播图
      jobNewsColumnList: [], // 新闻栏目
      jobNewsActive: 0, // 新闻高亮
      newsList: [], // 新闻列表
      platform: [], //平台资讯
      look: [], //底部视频
      i: [],
      hotList: [], //热搜榜单
      coreList: [], //核心企业
      famousList: [], //知名企业展示
      showList: {}, //企业展示
      creditList: [], //优秀个人
      list: [], //招聘会
      carerList: [],
      postList: [], //热招岗位
      talentedList: [], //找人才
      upperList: [], //上链滚动
      showProvinceList: [],
      showCityList: [],
      trainList: [], //培训
      findList: [], //找企业
      orgList: [], //行业
      criticismList: [],
      welfareBgc: [
        {
          backgroundColor: '#FF4A08',
        },
        {
          backgroundColor: '#FC9A40',
        },
        {
          backgroundColor: '#13C5DD',
        },
        {
          backgroundColor: '#3F72FC',
        },
        {
          backgroundColor: '#FF4A08',
        },
        {
          backgroundColor: '#FC9A40',
        },
        {
          backgroundColor: '#13C5DD',
        },
        {
          backgroundColor: '#3F72FC',
        },
      ],
      visibleIM: false,
    }
  },

  created() {
    this._getDict()
    this._getProvincial()
    this._getCity()
    this._getArea()
    // this.getRotationChart();
    this.getNewsList()
    this.getHotList()
    this.getCoreList()
    this.getShowList()
    this.getPostList()
    this.getCreditList()
    this.getCriticism()
    this.getCarer()
    // this.getStation();
    this.getFair()
    this.getTalented()
    this.getUpperList()
    this.getTraining()
    this.getFind()
    this.getOrg()

    // this.ScrollUp();
    // this.getChainList();

    // this.getJobFairApplyList();
    // this.cityList = globalData.cityList;

    this.showCityList = JSON.parse(localStorage.getItem('cityList'))
    // this.grade = option.value ? option.value : "";
    window.addEventListener('resize', this.handleResize)
  },

  mounted() {
    // 点击传值
    // this.$refs.productImage.homeindexss(0);
    // 传值
    // activeFactoryId(val, oldVal) {
    //   clearInterval(this.componentTimer);
    //   this.bsGetProductProcess();
    //   this.componentTimerFun();
    // }
    // new Swiper(".swiper-container", {
    //   loop: true,
    //   pagination: {
    //     el: ".swiper-pagination",
    //     clickable: true,
    //   },
    //   navigation: {
    //     nextEl: ".swiper-button-next",
    //     prevEl: ".swiper-button-prev",
    //   },
    //   scrollbar: {
    //     el: ".swiper-scrollbar",
    //   },
    // });
  },

  methods: {
    handleClose() {
      this.visibleIM = false
    },
    // 在线客服
    sendMessage() {
      if (!localStorage.getItem('userId')) {
        this.$confirm('请先登录!', '', {
          confirmButtonText: '登录',
          showCancelButton: false,
          type: 'warning'
        }).then(() => {
         this.$router.push('/login')
        }).catch(() => {});
        return
      }
      // if (
      //   this.peopleList.find(i => i.userProfile.userID == 'superAdmin')
      // ) {
      //   return
      // }
      // let peopleList = this.peopleList
      // let obj = {
      //   userProfile: {
      //     userID: 'superAdmin',
      //     nick: '在线客服',
      //   },
      // }
      // this.peopleList.unshift(obj)
      // this.$store.commit('set_peopleList', peopleList)
      this.visibleIM = true
    },
    swiperContentShow(val) {
      this.swiperIntro = val.intro
      this.introVisible = true
    },
    moment,
    // 企业搜索输入框
    searchInput(e) {
      this.searchValue = e.target._value
      this.getFind()
    },
    evaluateInput(e) {
      this.evaluateValue = e.target._value
    },
    tabHaver(e) {
      this.tabIndex = e
    },
    // 页面上下点击滚动
    moveScrollIntoView(content) {
      let PageId = document.getElementById(content)
      let height = PageId.offsetTop - 100
      window.scrollTo({
        top: height,
        behavior: 'smooth',
      })
    },

    arrowClick(val) {
      if (val === 'right') {
        this.$refs.cardShow.next()
      } else {
        this.$refs.cardShow.prev()
      }
    },
    handleResize() {
      this.fullWidth = document.documentElement.clientWidth
      this.fullHeight = document.documentElement.clientHeight
    },
    _getProvincial() {
      getProvincial().then(res => {
        localStorage.setItem('provincialList', JSON.stringify(res.data))
        this.showProvinceList = JSON.parse(
          localStorage.getItem('provincialList')
        )
      })
    },
    _getCity() {
      getCity().then(res => {
        localStorage.setItem('cityList', JSON.stringify(res.data))
        this.showCityList = JSON.parse(localStorage.getItem('cityList'))
      })
    },
    _getArea() {
      getArea().then(res => {
        localStorage.setItem('areaList', JSON.stringify(res.data))
      })
    },
    _getDict() {
      getDict({}).then(res => {
        if (res.code == 200 && res.message === '请求成功') {
          localStorage.setItem('dict', JSON.stringify(res.data))
          this.dict = JSON.parse(localStorage.getItem('dict'))
          this.orgType = this.dict.filter(
            i => i.code == 'job_org_type'
          )[0].children
          this.jobEdu = this.dict.filter(i => i.code == 'job_edu')[0].children
          this.jobExp = this.dict.filter(i => i.code == 'job_exp')[0].children
          this.job_gm = this.dict.filter(i => i.code == 'job_gm')[0].children
          this.jobPay = this.dict.filter(i => i.code == 'job_pay')[0].children
        }
      })
    },
    getOrgType(id) {
      if (this.orgType.length > 0 && id) {
        return this.orgType.filter(i => i.code == id)[0].name
      } else {
        return ''
      }
    },
    getPostEdu(id) {
      if (this.jobEdu && id) {
        return this.jobEdu.filter(i => i.code == id)[0].name
      } else {
        return ''
      }
    },
    getPostExp(id) {
      if (this.jobExp && id) {
        return this.jobExp.filter(i => i.code == id)[0].name
      } else {
        return ''
      }
    },
    getPay(code) {
      if (this.jobPay && code) {
        return this.jobPay.filter(i => i.code == code)[0].name
      } else {
        return ''
      }
    },
    // 回显省份
    getProvinceForId(id) {
      if (parseFloat(id) && this.showProvinceList) {
        return this.showProvinceList.filter(i => i.PROVINCE_CODE == id)[0]
          .SHORT_NAME
      } else {
        return '湖北'
      }
    },
    // 回显城市
    getCityForId(id) {
      if (parseFloat(id) && this.showCityList) {
        return this.showCityList.filter(i => i.CITY_CODE == id)[0].SHORT_NAME
      } else {
        return '武汉'
      }
    },

    //优秀信用评价
    toEvaluation() {
      if (this.evaluateValue) {
        this.$router.push({
          name: 'evaluation',
          query: {
            value: this.evaluateValue,
          },
        })
      } else {
        this.$router.push({
          name: 'evaluation',
        })
      }
    },
    //优秀信用评价
    toEllent() {
      this.$router.push({
        name: 'ellent',
      })
    },
    //优秀个人详情
    toEvaluationDetail(item) {
      this.$router.push({
        name: 'evaluationDetail',
        query: {
          // id: item.idCard,
          id: window.btoa(item.idCard),
        },
      })
    },

    //热搜榜单
    getHotList() {
      let params = {
        type: 'hot',
        pageNo: 1,
      }
      jobOrgAllPage(params).then(res => {
        this.hotList = res.data.rows
      })
    },
    // 核心企业 //知名企业推荐
    getCoreList() {
      let params = {
        type: 'core',
        pageNo: 1,
        pageSize: 10,
      }
      jobOrgAllPage(params).then(res => {
        this.coreList = res.data.rows
      })
      let data = {
        type: 'core',
        pageNo: 1,
        pageSize: 10,
      }
      jobOrgAllPage(data).then(res => {
        this.famousList = res.data.rows
      })
    },

    // 企业展示
    getShowList() {
      let params = {
        type: 'show',
        pageNo: 1,
        pageSize: 10,
      }
      jobOrgAllPage(params).then(res => {
        this.showList = res.data ? res.data.rows : ''
        this.showList = res.data ? res.data.rows[0] : ''
        if (this.showList) {
          this.showList.welfare = this.showList.welfare.split(',')
          if (this.showList.children.length > 0) {
            this.showList.children.forEach(i => {
              if (i.logo) {
                i.logo = i.logo.split(',')
              }
            })
          }
        }
      })
    },

    //热招岗位
    getPostList() {
      let params = {
        type: 'post',
        pageNo: 1,
        pageSize: 12,
      }
      jobOrgAllPage(params).then(res => {
        this.postList = res.data.rows
      })
    },
    // 回显工种
    getExpectPostForId(list, id) {
      var title = ''
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          title = list[i].title
          break
        } else if (list[i].children && list[i].children.length > 0) {
          title = this.getExpectPostForId(list[i].children, id)
          if (title) return title
        }
      }
      return title
    },
    getGm(code) {
      return this.job_gm.filter(i => i.code == code)[0].name
    },
    imgInfo(code) {
      let img = this.$imgUrl(code)
      return img
    },
    geTel(tel) {
      if (tel) {
        var reg = /^(\d{3})\d{4}(\d{4})$/
        return tel.replace(reg, '$1****$2')
      } else {
        return ''
      }
    },
    // 轮播图
    // getRotationChart() {
    //   rotationChart().then((res) => {
    //     this.rotationChartList = res.data;
    //   });
    // },
    // 新闻栏目
    getJobNewsColumnTree(id) {
      jobNews({
        jobNewsColumnId: id,
      }).then(res => {
        this.newsList = res.data.rows
      })
    },
    // 平台资讯展示
    getPlatform(id) {
      jobNews({
        jobNewsColumnId: id,
      }).then(res => {
        this.platform = res.data.rows.splice(0, 6)
      })
    },
    // 新闻栏
    async getNewsList() {
      let data = await jobNewsColumnTree()
      let info = []
      data.data[0].children.map(item => {
        let params = {
          name: '',
          id: '',
        }
        params.name = item.title
        params.id = item.id
        if (
          item.title == '培训动态' ||
          item.title == '行业动态' ||
          item.title == '政策法规'
        ) {
          this.tabList.unshift(params)
        } else {
          this.tabList.push(params)
        }
        if (item.title == '平台资讯') {
          this.getPlatform(item.id)
        }
        return item
      })
      this.tabName = this.tabList[0].id
      this.getJobNewsColumnTree(this.tabName)
    },
    //新闻栏切换
    onClick(tab, event) {
      this.getJobNewsColumnTree(tab.name)
    },

    //优秀个人
    async getCreditList() {
      let params = {
        pageNo: 1,
        pageSize: 4, //this.limit,
        type: '1',
        // grade: "",
      }
      let res = await jobEvaluationData(params)
      this.creditList = res.data.rows
    },

    //上链滚动
    async getUpperList() {
      let res = await newEvaluation()
      this.upperList = res.data
    },

    //考证及培训
    async getCriticism() {
      let params = {
        pageNo: 1,
        // pageSize: this.limit,
        pageSize: 3,
        auditStatus: 2
      }

      let res = await jcCoursePage(params)
      this.criticismList = res.data.rows
    },
    //培训
    async getTraining() {
      let params = {
        pageNo: 1,
        pageSize: 2,
        auditStatus: 2,
        // applyStatus: 1
      }
      let res = await jcTrainingPage(params)
      this.trainList = res.data.rows
    },
    //找人才
    async getTalented() {
      let params = {
        pageNo: 1,
        pageSize: 12,
      }
      let res = await queryJobInfoPage(params)
      this.talentedList = res.data.rows
    },
    //找企业
    async getFind() {
      let params = {
        pageNo: 1,
        pageSize: 10,
        name: '',
        size: '',
        properties: '',
        orgType: '',
      }
      if (this.searchValue) {
        params.name = this.searchValue
      }
      let res = await findJobOrg(params)
      this.findList = res.data.rows
    },

    //行业/工种
    async getOrg() {
      let params = {
        code: 'trade_type',
      }
      let res = await jobPostType(params)
      this.orgList = this.hasChildren(res.data[0].children)
    },
    // 添加对应字段
    hasChildren(list) {
      list.forEach(item => {
        item.name = item.title
        item.child = item.children
        item.selected = false
        if (item.child.length > 0) {
          this.hasChildren(item.child)
        } else {
          item.child = []
        }
      })
      return list
    },
    getTradeType(str) {
      let newStr = str.split(',')
      let arr = []
      if (this.orgList.length > 0) {
        newStr.forEach(i => {
          let ids = this.getOrgTypeForTreer(this.orgList, i)
          if (ids.length > 0) {
            arr.push(ids)
          }
        })
      }
      let strs = arr.join('，')
      return strs
    },
    // 根据公司行业id回显  递归找父级id
    getOrgTypeForTreer(list, id) {
      var ids = []
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          ids.push(list[i].title)
          break
        } else if (list[i].children && list[i].children.length > 0) {
          ids = this.getOrgTypeForTreer(list[i].children, id)
          if (ids.length > 0) return ids
        }
      }
      return ids
    },
    // 招聘会切换
    handleClick(tab) {
      if (tab.name == 'first') {
        this.getCarer()
      } else {
        this.getFair()
      }
    },
    //招聘会
    async getCarer() {
      let params = {
        auditStatus: 2,
      }
      let res = await jobFairApplyList(params)
      let applyList = res.data.filter(i => i.auditStatus == '2')
      this.list = applyList.length > 4 ? applyList.slice(0, 4) : applyList
    },
    //往期招聘会
    async getFair() {
      let params = {
        pageNo: 1,
        pageSize: this.limit,
        btime: '',
        ntime: '',
      }
      params.etime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      let res = await jobFairPage(params)
      this.carerList = res.data.rows
    },
    timeMoment(val) {
      if (val.fairType !== '2') {
        let nowTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        let beginTime = moment(val.beginTime).format('YYYY-MM-DD HH:mm:ss')
        let endTime = moment(val.endTime).format('YYYY-MM-DD HH:mm:ss')
        if (beginTime <= nowTime && nowTime <= endTime) {
          return '进入会场'
        } else {
          return '立即报名'
        }
      } else {
        return '立即报名'
      }
    },
    // 课程/培训页面
    toOnlineLearning() {
      this.$router.push({ name: 'onlineLearning' })
    },

    // 课程详情/培训详情
    toCourseDetails(num, item) {
      this.$router.push({
        name: num == '1' ? 'courseDetails' : 'registrationDetails',
        query: {
          id: item.id,
          sumApplyNum: item.sumApplyNum ? item.sumApplyNum : '',
        },
      })
    },
    //人才详情
    toJobInformationDetails(item) {
      readInfo(item) //存入(看过的人才)记录中
      this.$router.push({
        name: 'jobInformationDetails',
        query: {
          id: item.id,
        },
      })
    },

    //热招岗位详情
    toDatum(id) {
      this.$router.push({
        name: 'datum',
        query: {
          id: id,
        },
      })
    },
    //进入企业详情
    toEnterpriseDetails(id) {
      this.$router.push({
        name: 'enterpriseDetails',
        query: {
          id: id,
        },
      })
    },

    // 进入往期招聘会详情
    // toJobFairDetails(info) {
    //   if(info.fairType == '2'){
    //     let route = this.$router.resolve({
    //     query: { id: info.id},
    //     path: "/jobFairDetails",
    //   });
    //   window.open(route.href, "_blank");
    //   }else{
    //     let route = this.$router.resolve({
    //           query: { id: info.id },
    //           path: "/jobLineDetails",
    //         });
    //     window.open(route.href, "_blank");
    //   }
    // },
    toJobFairDetails1(id, held) {
      this.$router.push({
        name: 'jobFairDetails',
        query: {
          id: id,
          held: held,
        },
      })
    },
    //进入找人才
    toJobInformation() {
      this.$router.push({ name: 'jobInformation' })
    },

    //进入热招岗位
    toPosition(id, held) {
      this.$router.push({
        name: 'position',
        query: {
          id: id,
          held: held,
        },
      })
    },

    //进入平台资讯
    toContent(item) {
      console.log(item)
      if (item.newsType == '1' && item.target) {
        window.open(item.target, '_blank')
      } else {
        this.$router.push({
          name: 'content',
          query: {
            id: item.id,
          },
        })
      }
    },

    gowen() {
      this.$router.push({ path: 'newscenter' })
    },

    toJobFair() {
      this.$router.push({ name: 'jobFair' })
    },
    getFirstWord(name) {
      if (!name) {
        return
      }
      let pinyin = require('js-pinyin')
      pinyin.setOptions({ checkPolyphone: false, charCase: 0 })
      let word = pinyin.getFullChars(name).substring(0, 1)
      return word
    },
  },
}
</script>
<style>
.introx {
  overflow: hidden;
  height: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.introxs {
  width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.yuming {
  width: 730px;
  height: 15px;
  color: rgba(154, 161, 179, 1);
  font-size: 12px;
  line-height: 15px;
  margin: 36px auto;
}
html {
  overflow-x: hidden;
}
</style>

<style scoped lang="scss">
a {
  text-decoration: none;
}
.register-img {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  .img_box {
    position: absolute;
    background-image: url(../../img/bg-img.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 101%;
    height: 101%;
  }
}

.jobNewsActive {
  background-color: rgb(2, 154, 255);
  border-radius: 10px;
  color: #fff;
}
.industryTrends {
  display: flex;
  align-items: center;
  margin: 20px 0 35px 0;
  .titleText {
    font-size: 28px;
    margin-right: 10px;
  }
  .columnName {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .center-box {
    display: flex;
    align-items: center;
    .tabs {
      padding: 0 10px;
      height: 30px;
      line-height: 30px;
      margin-right: 26px;
      cursor: pointer;
    }
  }
  .allNews {
    width: 50px;
    text-align: right;
    margin-right: 22px;
    cursor: pointer;
    margin-top: 4px;
  }
}

::v-deep .carouselShow {
  .el-carousel__indicators {
    li {
      padding: 12px 30px;
      .el-carousel__button {
        width: 37px;
        height: 4px;
        background-color: #dedede;
        opacity: 1;
      }
    }
    .is-active {
      .el-carousel__button {
        background-color: #029aff;
      }
    }
  }
}
::v-deep .pane-list,
::v-deep .team-pane-list {
  .el-tabs__nav-scroll {
    border: 1px solid #ccc !important;
    border-radius: 4px;
    padding: 10px 20px;
  }
  .el-tabs__active-bar {
    bottom: -10px;
  }
}

@import './scss/index.scss';

.titleCarousel {
  img {
    object-fit: cover;
  }
}
</style>

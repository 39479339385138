import request from "@/api/request"

export function addOrg(data) {
  return request({
    method: 'post',
    url: '/jobUser/jobOrg/add',
    data:data
  })
}


export function editOrg(data) {
  return request({
    method: 'post',
    url: '/jobUser/jobOrg/edit',
    data:data
  })
}

 
<template>
  <div
    class="qi"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- 版心 -->
    <div :class="show == 3 ? 'qitye-titles' : 'qitye-title'">
      <!-- 复用头部 -->
      <!-- <futongtitle ref="productImage"> </futongtitle> -->
      <certification ref="productImage"> </certification>
      <div>
        <div class="banner">
          <div class="banner-img">
            <div class="banner-zi">企业认证</div>
            <img src="../../../img/youtu.png" alt="" />
          </div>
        </div>
      </div>
      <div :class="show == 3 ? 'beis' : 'bei'">
        <div class="mac">企业认证</div>
        <!-- 步骤条 -->
        <div class="renwu">
          <div class="renwu1">
            <div class="xinxi bgcc" v-if="show == 1">1</div>
            <img v-else class="duigou" src="../../../img/对钩.png" alt="" />
            <div class="xingxiz">基本信息</div>
            <div class="xian"></div>

            <!-- 上传证件 -->
            <div :class="show >= 2 ? 'bgc' : 'xi'" v-if="show <= 2">2</div>
            <img v-else class="duigou" src="../../../img/对钩.png" alt="" />
            <div class="xingxiz">上传证件</div>

            <div :class="show > 2 ? 'xian' : 'xian1'"></div>

            <!-- <div class="xinxi xi">3</div> -->
            <div :class="show >= 3 ? 'bgc' : 'xi'">3</div>
            <div class="xingxiz">等待审核</div>
          </div>
        </div>
        <!-- 基本信息 -->
        <div class="qi-box" v-if="show == 1">
          <div class="top-box">
            <div class="jiben">
              <div class="tiao"></div>
              <div class="xi">基本信息</div>
            </div>
          </div>
          <!-- 企业名称 -->
          <el-form
            :inline="true"
            :model="orgInfo"
            class="demo-form-inline"
            :rules="rules"
            ref="orgInfo"
          >
            <el-form-item label="企业名称:" prop="name">
              <el-input
                v-model="orgInfo.name"
                label-width="400px"
                placeholder="请输入"
                class="ipt blockx"
              ></el-input>
            </el-form-item>
            <!-- 统一社会信用代码 -->
            <el-form-item label="统一社会信用代码:" class="rig" prop="orgCode">
              <el-input
                v-model="orgInfo.orgCode"
                placeholder="请输入18位社会信用代码"
                class="iptssx"
              ></el-input>
            </el-form-item>
            <!-- 所在城市 -->
            <div class="block">
              <div class="block-box">
                <el-form-item label="所在城市:" prop="desc">
                  <el-select
                    v-model="orgInfo.desc"
                    placeholder="请选择省份"
                    class="blockz"
                    @change="handleChangeProvincial"
                  >
                    <!-- <el-option
                      v-for="item in Provinciallist"
                      :key="item.pid"
                      :label="item.Provincial"
                      :value="item.pid"
                    ></el-option> -->

                    <el-option
                      v-for="item in provincialList"
                      :key="item.PROVINCE_CODE"
                      :label="item.SHORT_NAME"
                      :value="item.PROVINCE_CODE"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="desc">
                  <!-- <el-select v-model="orgInfo.citys" class="blockss"> -->
                  <el-select
                    v-model="orgInfo.city"
                    @change="handleChangeCity"
                    class="blockss"
                  >
                    <!-- <el-option
                    placeholder="请选择城市"
                      v-for="item in city"
                      :key="item.cid"
                      :label="item.city"
                      :value="item.cid"
                    ></el-option> -->
                    <el-option
                      placeholder="请选择城市"
                      v-for="item in cityList"
                      :key="item.CITY_CODE"
                      :label="item.SHORT_NAME"
                      :value="item.CITY_CODE"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <!-- 单位地址 -->
              <el-form-item label="单位地址:" class="rigs" prop="address">
                <el-input
                  @focus="dialogInputVisible = true"
                  v-model="orgInfo.address"
                  placeholder="请输入/选择"
                  class="ipts"
                ></el-input>
              </el-form-item>
            </div>
            <!-- 单位性质 -->
            <div class="block">
              <!-- <el-form-item label="单位性质:" prop="properties">
                <el-input
                  v-model="orgInfo.properties"
                  label-width="400px"
                  placeholder="请输入"
                  class="ipt"
                ></el-input>
              </el-form-item> -->
              <div>
                <el-form-item label="单位性质:" prop="properties">
                  <el-select
                    v-model="orgInfo.properties"
                    placeholder="请选择"
                    class="ipt"
                  >
                    <el-option
                      v-for="item in dict_orgType"
                      :key="item.value"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <!-- 单位类型 -->
              <!--
              <div class="liandong">
                <el-form-item label="单位类型:" prop="orgType">

                  <div class="block dong">
                    <el-cascader
                      placeholder="可搜索职位"
                      filterable
                      :options="treer"
                      :props="{
                        multiple: true,
                        value: 'id',
                        label: 'title',
                        children: 'children'
                      }"
                      @change="chooseTradeType"
                      class="mak"
                    >

                    </el-cascader>
                  </div>
                </el-form-item>
              </div>
 -->

              <div class="liandong">
                <el-form-item label="单位类型:" prop="orgType">
                  <!-- <el-select
                    v-model="orgInfo.orgType"
                    multiple
                    placeholder="请选择"
                    class="dong"
                  >
                    <el-option
                      v-for="item in treer"
                      :key="item.value"
                      :label="item.title"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select> -->

                  <!-- v-model="orgInfo.orgType" -->
                  <el-cascader
                    class="ipts"
                    v-model="orgInfo.orgType"
                    :options="treer"
                    :show-all-levels="false"
                    :props="typeProps"
                    collapse-tags
                    placeholder="公司行业(您最多只能选择7个行业类型)"
                    @change="chooseTndustry"
                  ></el-cascader>
                </el-form-item>
              </div>
            </div>
            <!-- 单位网址 -->
            <el-form-item label="单位网址:" style="margin-left:10px;" prop="orgUrl">
              <el-input
                v-model="orgInfo.orgUrl"
                label-width="400px"
                placeholder="例: www.xxx.com"
                class="ipt"
              ></el-input>
            </el-form-item>
            <!-- 单位规模规 -->
            <!-- <el-form-item label="单位规模规:" class="wei" prop="size">
              <el-input
                v-model="orgInfo.size"
                placeholder="请输入"
                class="ipt iptss"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="单位规模:" prop="size" class="wei">
              <el-select
                v-model="orgInfo.size"
                placeholder="请选择"
                class="ipt iptss"
              >
                <el-option
                  v-for="item in this.job_gm"
                  :key="item.value"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <!-- 联系人信息 -->
            <div class="top-box">
              <div class="jiben jibens">
                <div class="tiao"></div>
                <div class="xi">联系人信息</div>
              </div>
            </div>
            <!-- 主要联系人 -->
            <el-form-item label="主要联系人:" prop="legallyLiablePerson">
              <el-input
                v-model="orgInfo.legallyLiablePerson"
                label-width="400px"
                placeholder="请输入联系人姓名"
                class="shu"
              ></el-input>
            </el-form-item>
            <!-- 邮    编 -->
            <el-form-item label="邮    编:" class="di-rig" prop="postcode">
              <el-input
                v-model="orgInfo.postcode"
                placeholder="例: 430000"
                class="ipt"
              ></el-input>
            </el-form-item>

            <el-form-item label="座机电话:" style="margin-left:15px;" prop="tel">
              <el-input
                v-model="orgInfo.tel"
                label-width="400px"
                placeholder="例：xxxx-xxxxxxxx"
                class="shu"
              ></el-input>
            </el-form-item>
            <!-- 邮    编 -->
            <el-form-item label="邮    箱:" class="di-rig" prop="email">
              <el-input
                v-model="orgInfo.email"
                placeholder="例: email@126.com"
                class="ipt"
              ></el-input>
            </el-form-item>

            <el-form-item label="手机号:" style="margin-left:30px;" prop="phone">
              <el-input
                v-model="orgInfo.phone"
                label-width="400px"
                placeholder="请输入11位手机号"
                class="shu"
              ></el-input>
            </el-form-item>
            <div>
              <el-form-item class="intro" label="企业简介:" prop="intro">
                <el-input type="textarea" placeholder="请输入" v-model="orgInfo.intro" maxlength="1000" show-word-limit></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <!-- 上传证件 -->
        <div class="qi-box" v-if="show == 2">
          <!-- 上传证件 -->
          <div class="top-box top-bos">
            <div class="jiben">
              <div class="tiao"></div>
              <div class="xi">上传证件</div>
            </div>
          </div>
          <!-- 营业执照 -->
          <div class="upload-demo demo">
            <div class="ying">营业执照</div>
            <el-upload
              drag
              name="uploadfile"
              :action="$global.uploadAction"
              :before-upload="beforeUpload"
              :on-success="upSuccess"
              :on-error="upError"
              :on-remove="upRemve"
              :on-change="upChange"
              :show-file-list="false"
              multiple
              class="box"
            >
              <div class="tu">
                <img
                  v-if="up == false"
                  class="tuo"
                  src="../../../img/Upload_Drag upload@3x.png"
                  alt=""
                />
                <img v-else class="tuo" :src="codeImg" />
              </div>

              <i class="el-icon-upload"></i>
              <!-- <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div> -->

              <div class="el-upload__tip" slot="tip">
                营业执照原件彩色扫描件或者复印件
                <br />
                大小:≤5M
                <br />
                <!-- 格式为：.rar .zip .doc .docx .pdf .jpg... -->
                <span>格式为：.jpg .png</span>
              </div>
            </el-upload>
          </div>
          <!-- 工作证明文件 -->
          <!-- <div class="upload-demo dome">
            <div class="ying">工作证明文件</div>
            <el-upload
              drag
              :action="$global.baseUrl + '/jobUser/sysFileInfo/upload'"
              :before-upload="beforeUpload"
              :on-success="upSuccess2"
              :on-error="upError"
              :on-remove="upRemve"
              :on-change="upChange"
              :show-file-list="false"
              multiple
              class="box"
            >
              <div class="tu">
                <img
                  v-if="ups == false"
                  class="tuo"
                  src="../../../img/tuo.png"
                  alt=""
                />
                <img v-else class="tuo" :src="codeImgs" />
              </div>

              <i class="el-icon-upload"></i>
              <div class="el-upload__tip" slot="tip">
                大小:≤5M
                <br />
                <span>格式为：.jpg .png</span>
              </div>
            </el-upload>
          </div> -->
        </div>
        <!-- 已提交 -->
        <div v-if="show == 3">
          <img
            class="diannao"
            src="../../../img/authentication_icon_shenhezhong_normal@3x.png"
            alt=""
          />
          <div
            class="miaoshuya"
            v-if="orgInfo.auditStatus == 2 && authOrg.auditStatus == 2"
          >
            审核已通过
          </div>
          <div class="miaoshuya" v-else>
            您的资料已经提交完成，请耐心等待平台审核
          </div>
        </div>
        <div class="btn-box">
          <div v-if="show > 1 && show < 3" class="btn" @click="toBack()">
            上一步
          </div>
          <div v-if="show < 3" class="btn" @click="submitForm('orgInfo')">下一步</div>
        </div>
      </div>
    </div>
    <foots :class="show == 3 ? 'footaa' : ''"></foots>
    <!-- 弹窗 -->

    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <!-- <span>企业证书及证明文件必须上传</span> -->
      <span>营业执照必须上传</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="请选择单位地址"
      :visible.sync="dialogInputVisible"
      width="40%"
      :close-on-click-modal="false"
    >
      <div class="mapBox">
        <el-amap-search-box
          class="search-box"
          :search-option="searchOption"
          :on-search-result="onSearchResult"
        >
        </el-amap-search-box>
        <div style="height: 30px; line-height: 30px">
          <span>{{ mapSearchKeyWord }}</span>
        </div>
        <el-amap
          class="amap-box"
          :vid="'amap-vue'"
          ref="map"
          :events="events"
          :zoom="zoom"
        >
          <el-amap-marker
            :position="center"
            :content="content"
          ></el-amap-marker>
        </el-amap>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogInputVisible = false">取 消</el-button>
        <el-button type="primary" @click="mapDialogBoxOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getOrg,
  preview,
  // getProvincial,
  // getCity,
} from '@/api/certification/certification'
import { addOrg, editOrg } from '@/api/org/org'
import { addOrgAuth, getAuthOrg } from '@/api/orgAuth/orgAuth'
import { tree } from '../../../api/certification/certification'
import { getLoginUser } from '@/api/login/login'
import tobs from '../../../components/tab/tab.vue'
import certification from '../../../components/hometitle/hometitle.vue'
import foots from '../../../components/foot/foot.vue'
import throttle from 'lodash/throttle' //节流
import { getToken } from '../../../utils/myauth'
import { getProvincial, getCity } from '@/api/index/index'
export default {
  data() {
    const validateOrgCode = (rule, value, callback) => {
      let regTest = /^([1-9ANY])([1-9])([0-9]{6})([0-9A-HJ-NP-RT-UW-Y]{10})$/
      console.log(value)
      if (!value) {
        callback(new Error('请输入统一社会信用代码'))
      } else {
        if (!regTest.test(value)) {
          callback(new Error('统一社会信用代码格式不正确'))
          return
        }
        callback()
      }
    }
    const validatePostcode = (rule, value, callback) => {
      let regTest = /^[0-9][0-9]{5}$/
      console.log(value)
      if (!value) {
        callback(new Error('请输入邮编'))
      } else {
        if (!regTest.test(value)) {
          callback(new Error('邮编格式不正确'))
          return
        }
        callback()
      }
    }
    const validateTel = (rule, value, callback) => {
      let regTest = /^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/
      console.log(value)
      if (!value) {
        callback(new Error('请输入座机电话号码'))
      } else {
        if (!regTest.test(value)) {
          callback(new Error('电话号码格式不正确，例：xxxx-xxxxxxxx'))
          return
        }
        callback()
      }
    }
    const validateEmail = (rule, value, callback) => {
      let regTest = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      if (!value) {
        callback(new Error('请输入邮箱'))
      } else {
        if (!regTest.test(value)) {
          callback(new Error('邮箱格式不正确'))
          return
        }
        callback()
      }
    }
    const validatePhone = (rule, value, callback) => {
      let regTest =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      console.log(value)
      if (!value) {
        callback(new Error('请输入手机号码'))
      } else {
        if (!regTest.test(value)) {
          callback(new Error('手机号码格式不正确'))
          return
        }
        callback()
      }
    }
    return {
      locationName: '', //地图搜索选择地址
      center: [114.304576, 30.593374],
      maxlength:1000,
      // center: [],
      zoom: 9,
      lng: '',
      lat: '',
      content: '',
      inputValue: '',
      searchResult: {
        address: '',
        latitude: '',
        longitude: '',
        name: '',
        type: '',
        country: '',
        province: '',
        city: '',
        area: '',
        township: '',
        street: '',
        neighborhood: '',
        locationName: '',
      },
      searchOption: {
        // city: '武汉',
        citylimit: false,
      },
      events: {},
      mapSearchKeyWord: '',
      dialogInputVisible: false, //地图对话框开关
      typeProps: {
        value: 'id',
        label: 'title',
        children: 'children',
        multiple: true,
      },
      treer: [],
      Token: [],
      pid: [],
      city: [],
      loading: true,
      centerDialogVisible: false,
      up: false,
      ups: false,
      codeImg: [],
      // codeImgs: [],
      active: 0,
      show: 1,
      name: '',
      value: [],
      Provinciallist: [],
      options: [
        {
          title: 'zhinan',
          id: '指南',
          children: [
            {
              title: 'shejiyuanze',
              id: '设计原则',
              children: [
                {
                  title: 'yizhi',
                  id: '一致',
                },
                {
                  title: 'fankui',
                  id: '反馈',
                },
                {
                  title: 'xiaolv',
                  id: '效率',
                },
                {
                  title: 'kekong',
                  id: '可控',
                },
              ],
            },
          ],
        },
      ],
      rules: {
        //         blur  失去焦点 比如输入框里。校验文本框是否为空
        //         change数据改变
        // 比如下拉框，需要手动选择的东西，用change
        name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        // orgCode: [{ required: true, message: '请选认真输入', trigger: 'blur' }],
        orgCode: [{ required: true, validator: validateOrgCode }],
        desc: [{ required: true, message: '请选择区域', trigger: ' change' }],
        address: [{ required: true, message: '请选单位地址', trigger: 'blur' }],
        properties: [{ required: true, message: '请选择单位性质', trigger: 'blur' }],
        orgType: [{ required: true, message: '请选择单位类型', trigger: ' change' }],
        // orgUrl: [{ required: true, message: 'www.xxx.com', trigger: 'blur' }],
        size: [{ required: true, message: '请选择单位规模', trigger: 'blur' }],
        legallyLiablePerson: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
        // postcode: [{ required: true, message: '不能为空', trigger: 'blur' }],
        postcode: [{ required: true, validator: validatePostcode }],
        // tel: [{ required: true, message: '不能为空', trigger: 'blur' }],
        tel: [{ required: true, validator: validateTel }],
        // email: [{ required: true, message: '不能为空', trigger: 'blur' }],
        email: [{ required: true, validator: validateEmail }],
        // phone: [{ required: true, message: '不能为空', trigger: 'blur' }],
        phone: [{ required: true, validator: validatePhone }],
        intro: [{ required: true, message: '不能为空', trigger: 'blur' }],
      },

      dictData: JSON.parse(localStorage.getItem('dict')),
      dict_orgType: [],
      job_gm: [],
      orgInfo: {
        jobUserId: '',
        name: '',
        address: '',
        longitude: '',
        latitude: '',
        city: '',
        cityName: '',
        orgCode: '',
        properties: '',
        orgType: '',
        orgUrl: '',
        size: '',
        intro: '',
        legallyLiablePerson: '',
        email: '',
        phone: '',
        tel: '',
        postcode: '',
        desc: '',
        user: '',
        citys: '',
        tradeType: '',
      },
      orgAuthInfo: { orgCode: '', businessLicense: '', jobPermit: '' },
      addtype: 'add',
      unitType: [], //单位类型
      provincialList: [], //省份列表
      cityList: [], //城市列表
      authOrg: {}, //认证信息
    }
  },
  components: {
    tobs,
    foots,
    certification,
  },
  computed: {},
  created() {
    this.cityList = JSON.parse(localStorage.getItem('cityList'))
    this._getProvincial()
    // this.props = {
    //   value: 'id',
    //   label: 'title',
    //   children: 'children',
    //   lazy: true,
    //   lazyLoad: (node, resolve) => {
    //     const { level } = node
    //     console.log(level)
    //     // if (level == 0) {
    //     tree().then(res => {
    //       if (res.data.alias == 'trade_type') console.log(res, '省份列表')
    //       const city = res.data
    //       //   resolve(city)

    //       // this.treer = res.data
    //     })
    //     // }
    //   },
    // }
    this.getJobTradeType()
    this.Token = getToken()
    // 城市
    // getProvincial().then(res => {
    //   this.Provinciallist = res.data
    //   console.log(this.Provinciallist, ' this.Provinciallist')
    // })

    // 获取企业信息

    getOrg().then(res => {
      this.loading = false //加载窗
      // 如果没有企业信息，在第一步
      if (res.data == null) {
        this.show = 1
      }
      // 查得到企业信息，查不到认证信息，在第二步
      if (res.data) {
        this.orgAuthInfo.orgCode = res.data.orgCode
        if (
          res.data.auditStatus == 1 ||
          res.data.auditStatus == 2 ||
          res.data.auditStatus == 4
        ) {
          this.show = 2
        }
        this.orgInfo = res.data
        if (res.data.auditStatus == 3) {
          this.orgInfo.auditStatus = 4
          this.show = 1
        }
        this.orgInfo.desc = this.cityList.filter(
          i => i.CITY_CODE == res.data.city
        )[0].PROVINCE_CODE
        // 单位类型回显
        // this.orgInfo.orgType = JSON.parse(this.orgInfo.orgType)
        if(typeof this.orgInfo.orgType == 'string' && this.orgInfo.orgType.constructor == String){
          this.orgInfo.orgType = this.orgInfo.orgType.split(',')
        }
        if (this.treer.length > 0) {
          var arr = []
          this.orgInfo.orgType.forEach(i => {
            let ids = this.getOrgTypeForTreer(this.treer, i)
            if (ids.length > 0) {
              arr.push(ids)
            }
          })
          console.log(arr, 1111111111)
        }
        console.log(arr,'arrarrarr');
        this.orgInfo.orgType = arr
        this.addtype = 'edit'
        // 查得到认证信息在第三步
        getAuthOrg().then(res => {
          console.log(res, '认证信息')
          if (res.data == null) {
            this.show = 2
          } else {
            this.authOrg = res.data
            if (
              res.data.auditStatus == 1 ||
              res.data.auditStatus == 2 ||
              res.data.auditStatus == 4
            ) {
              this.show = 3
            }
            if (res.data.auditStatus == 3) {
              if (
                this.orgInfo.auditStatus == 4 ||
                this.orgInfo.auditStatus == 1
              ) {
                this.show = 1
              } else {
                this.show = 2
              }
              // this.orgAuthInfo.id = res.data.id
              // this.orgAuthInfo.businessLicense = res.data.businessLicense
              // this.orgAuthInfo.jobPermit = res.data.jobPermit
              // this.orgAuthInfo.auditStatus = 4

              this.orgAuthInfo = res.data
              this.orgAuthInfo.auditStatus = 4
              this.orgAuthInfo.auditState = 4
              if(res.data.businessLicense){
                this.up = true
                this.codeImg = res.data.businessLicense
              }
              // preview({ id: res.data.businessLicense }).then(res => {
              //   console.log('图片', res)
              //   let blob = new Blob([res]) // 返回的文件流数据
              //   let url = window.URL.createObjectURL(blob) // 将他转化为路径
              //   this.codeImg = url
              //
              //   console.log('图片1', this.codeImg)
              // })

              // preview({ id: res.data.jobPermit }).then(res => {
              //   console.log('图片', res)
              //   let blob = new Blob([res]) // 返回的文件流数据
              //   let url = window.URL.createObjectURL(blob) // 将他转化为路径
              //   this.codeImgs = url
              //   this.ups = true
              //   console.log('图片1', this.codeImg)
              // })
            }
          }
        })
      }
      // if (res.data.auditStatus == 1) {
      //   this.show = 3
      // }
    })
    // console.log(this.dictData)
    this.dict_orgType = this.dictData.find(
      e => e.code === 'job_org_type'
    ).children
    // 工作类型
    this.job_gm = this.dictData.find(e => e.code === 'job_gm').children
    // console.log(this.job_gm, 'this.job_gm')
    // console.log(this.dict_orgType)
    // 企业登录
    getLoginUser().then(res => {
      console.log(res, '9999')
      if (res.data) {
        this.orgInfo.jobUserId = res.data.id
      }
    })
    // 省份
    // getProvincial().then(res => {
    //   this.Provinciallist = res.data
    //   console.log(this.Provinciallist, '省份')
    // })

    //获取企业认证
    // getAuthOrg().then((res) => {
    //   console.log(res, 'resdappp')
    //   console.log('认证信息s', res)
    // })
    // 单位类型
  },
  mounted() {
    this.$refs.productImage.getindex0(-1)
    this.events.click = this.click
  },
  methods: {
    click(e) {
      console.log(e)
      this.center = [e.lnglat.lng, e.lnglat.lat]
      this.lng = e.lnglat.lng
      this.lat = e.lnglat.lat
      // this.zoom = 10
      this.$nextTick(() => {
        this.$refs.map.$$getInstance().setFitView()
      })
      this.getAddress(this.center)
    },
    toBack() {
      if(typeof this.orgInfo.orgType == 'string' && this.orgInfo.orgType.constructor == String){
          this.orgInfo.orgType = this.orgInfo.orgType.split(',')
          if (this.treer.length > 0) {
          var arr = []
          this.orgInfo.orgType.forEach(i => {
            let ids = this.getOrgTypeForTreer(this.treer, i)
            if (ids.length > 0) {
              arr.push(ids)
            }
            })
          }
          this.orgInfo.orgType = arr
        }
      this.getOrgInfo();
      this.show--;
    },
    getOrgInfo(){
      this.addtype = 'edit'
      getOrg().then(res => {
      this.loading = false //加载窗

      // 查得到企业信息，查不到认证信息，在第二步
      if (res.data) {
        this.orgAuthInfo.orgCode = res.data.orgCode
        this.orgInfo = res.data
        this.orgInfo.desc = this.cityList.filter(
          i => i.CITY_CODE == res.data.city
        )[0].PROVINCE_CODE
        if(typeof this.orgInfo.orgType == 'string' && this.orgInfo.orgType.constructor == String){
          this.orgInfo.orgType = this.orgInfo.orgType.split(',')
          if (this.treer.length > 0) {
          var arr = []
          this.orgInfo.orgType.forEach(i => {
            let ids = this.getOrgTypeForTreer(this.treer, i)
            if (ids.length > 0) {
              arr.push(ids)
             }
            })
          }
          this.orgInfo.orgType = arr
        }
      }
    })
    console.log(this.orgInfo)
    },
    onSearchResult(pois) {
      console.log('pois', pois)
      //搜索
      // let latSum = 0
      // let lngSum = 0
      let that = this
      if (pois && pois.length > 0) {
        //如果长度为1则无需转化
        let poi = pois[0]
        let lng = poi['lng']
        let lat = poi['lat']
        this.center = [poi.lng, poi.lat]
        this.lng = lng
        this.lat = lat
        // // that.zoom = 15
        this.$nextTick(() => {
          this.$refs.map.$$getInstance().setFitView()
        })
        // that.content = poi.name
        that.searchResult.address = poi.address
        that.searchResult.latitude = poi.lat
        that.searchResult.longitude = poi.lng
        // that.form.lon = lng;
        // that.form.lat = lat;
        that.getAddress(that.center)
      } else {
        that.searchResult = []
      }
    },
    // 获取详细地址
    getAddress(center) {
      let _this = this
      let geocoder = new AMap.Geocoder({})
      geocoder.getAddress(center, function (status, result) {
        if (status === 'complete' && result.info === 'OK') {
          let obj = result.regeocode.addressComponent
          let locationName =
            obj.province +
            obj.city +
            obj.district +
            obj.township +
            obj.street +
            obj.streetNumber
          _this.locationName = locationName
          _this.mapSearchKeyWord = locationName
        }
      })
    },

    mapDialogBoxOk() {
      this.orgInfo.address = this.locationName
      this.orgInfo.longitude = this.lng
      this.orgInfo.latitude = this.lat
      this.dialogInputVisible = false
      this.$refs['orgInfo'].validateField('address', (val) => {
      if (!val) {
        return true;
      } else {
        return false;
      }
    })
    },

    // 查询省份
    _getProvincial() {
      getProvincial().then(res => {
        this.provincialList = res.data
      })
    },
    // 查询城市
    _getCity(code) {
      getCity({ PROVINCE_CODE: code }).then(res => {
        this.cityList = res.data
      })
    },
    // 根据省份id查询城市
    handleChangeProvincial(val) {
      console.log(val)
      // this.orgInfo.citys = ''
      this._getCity(val)
    },
    // 选择城市名字
    handleChangeCity(val) {
      let cityName = this.cityList.filter(i => i.CITY_CODE == val)[0].SHORT_NAME
      this.orgInfo.cityName = cityName
    },
    // 根据单位类型id回显  递归找父级id
    getOrgTypeForTreer(list, id) {
      var ids = []
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          if (list[i].pid != '1537642960810602497') {
            ids.push(list[i].pid)
          }
          ids.push(list[i].id)
          break
        } else if (list[i].children && list[i].children.length > 0) {
          ids = this.getOrgTypeForTreer(list[i].children, id)
          if (ids.length > 0) return ids
        }
      }
      return ids
    },
    // chooseTradeType(val) {
    //   console.log(val, '公司行业')
    //   this.tradeTypeId = val[length - 1]
    // },

    // 单位类型
    chooseTndustry(e) {
      console.log(e,'e')
      let arr = []
      e.forEach(i => {
        arr.push(i[i.length - 1])
      })
      this.unitType = arr
      console.log(this.unitType, 'unitType')
    },

    // 下拉省份
    // tree(value) {
    //   console.log(value, '下拉省份')
    // },
    hasChildren(list) {
      list.forEach(item => {
        if (item.children.length > 0) {
          this.hasChildren(item.children)
        } else {
          item.children = null
        }
      })
      return list
    },
    //行业类型
    getJobTradeType() {
      tree({ code: 'trade_type' }).then(res => {
        // this.treer = res.data
        this.treer = this.hasChildren(res.data[0].children)
        console.log(this.treer, 'this.treer')
      })
    },

    handleChange(value) {
      // 省份
      // getProvincial().then(res => {
      //   this.Provinciallist = res.data
      // })
      // getCity({ pid: value }).then(res => {
      //   this.city = res.data
      //   console.log(res, '详情位置')
      // })
      // console.log(this.Provinciallist, '省份')
      // console.log(value, '城市id')
    },
    saveOrg() {
      // console.log(this.addtype, this.orgInfo)
      // this.orgInfo.orgType=this.orgInfo.orgType[0]
      // this.orgInfo.orgType = JSON.stringify(this.unitType)

      // this.orgInfo.orgType = this.orgInfo.orgType.join(',')
      // this.orgInfo.orgType = this.unitType.join(',')
      let unitType = []
      if(this.orgInfo.orgType.constructor === Array && this.orgInfo.orgType.length <= 7){
        unitType = this.orgInfo.orgType
        let arr = []
        unitType.forEach(i => {
          arr.push(i[i.length - 1])
        })
        this.orgInfo.orgType = arr.join(',')
      }else{
        this.$message.error('公司行业最多只能选择7个行业类型')
        return
      }
      console.log(this.orgInfo.orgType, 'this.orgInfo')
      if (this.addtype === 'add') {
        addOrg(this.orgInfo).then(res => {
          console.log(res)
          if (res.success) {
            this.show++
          }else{
            this.orgInfo.orgType = unitType
            this.$message.error(res.message)
          }
        }).catch(error=>{
          this.orgInfo.orgType = unitType
          this.$message.error(error.message)
        })
      } else if (this.addtype === 'edit') {
        editOrg(this.orgInfo).then(res => {
          if (res.success) {
            this.show++
          }else{
            this.orgInfo.orgType = unitType
          this.$message.error(res.message)
          }
        }).catch(error=>{
          this.orgInfo.orgType = unitType
          this.$message.error(error.message)
        })
      }
    },
    saveOrgAuth() {
      this.orgAuthInfo.orgCode = this.orgInfo.orgCode
      console.log(this.addtype, this.orgAuthInfo)
      addOrgAuth(this.orgAuthInfo).then(res => {
        console.log(res)
        this.show++
      })
    },
    // 节流
    submitForm: throttle(function (orgInfo) {
      console.log(this.orgInfo)
      if (this.show == 1) {
        this.$refs[orgInfo].validate(valid => {
          if (valid) {
            if (this.show == 1) {
              this.saveOrg()
              // this.show++
            } else {
              this.show == 3
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        if (this.show == 2) {
          //获取企业认证
          // if (this.up == true && this.ups == true) {
          if (this.up) {
            this.saveOrgAuth()
          } else {
            this.centerDialogVisible = true
          }
        } else {
          this.show == 3
        }
      }
    }, 3000),

    // 上传文件判断
    beforeUpload(file) {
      console.log(file)
      // const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const whiteList = ['rar', 'zip', 'doc', 'docx', 'pdf', 'jpg', 'png']
      // const whiteList = ['jpg', 'png']
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 .jpg .png 格式!')
      }
      // if (whiteList.indexOf(fileSuffix) === -1) {
      //   this.$message({
      //     type: 'error',
      //     // message: '上传文件只能是 .rar .zip .doc .docx .pdf .jpg .png... 格式',
      //     message: '上传文件只能是 .jpg .png 格式',
      //     showClose: true,
      //     offset: 80,
      //   })
      //   this.fileList = []
      //   return false
      // }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message({
          type: 'error',
          message: '上传文件不能超过5MB',
          showClose: true,
          offset: 80,
        })
        return false
      }
    },
    // 上传成功
    upSuccess(res) {
      console.log(res)
      this.orgAuthInfo.businessLicense = res.msg
      this.$message({
        type: 'success',
        message: '上传成功',
        showClose: true,
        offset: 80,
      })
      this.up = true
      this.codeImg = res.msg
      // preview({ id: res.data }).then(res => {
      //   console.log('图片', res)
      //   let blob = new Blob([res]) // 返回的文件流数据
      //   let url = window.URL.createObjectURL(blob) // 将他转化为路径
      //   this.codeImg = url
      //   console.log('图片1', this.codeImg)
      // })
    },

    // 上传成功
    upSuccess2(res) {
      console.log(res)
      this.orgAuthInfo.jobPermit = res.data
      this.$message({
        type: 'success',
        message: '上传成功',
        showClose: true,
        offset: 80,
      })
      this.ups = true
      preview({ id: res.data }).then(res => {
        let blob = new Blob([res]) // 返回的文件流数据
        let url = window.URL.createObjectURL(blob) // 将他转化为路径
        this.codeImgs = url
      })
    },
    // 上传失败
    upError() {
      this.$message({
        type: 'error',
        message: '上传失败',
        showClose: true,
        offset: 80,
      })
    },
    //上传的文件改变时（覆盖原来的文件）
    upChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]
      }
    },
    // 移除列表
    upRemve(file) {
      console.log(file)
    },
  },
}
</script>

<style scoped lang="scss">
@import './scss/index.scss';
.posd {
  width: 400px;
  height: 400px;
  display: block;
}
.footaa {
  margin-top: 207px;
}
.banner {
  width: 100%;
  background: url(../../../img/beitong.png) repeat;
  background-size: 100% 100%;
  margin-top: 80px;
}
.banner-img {
  display: block;
  margin: 0 auto;
  width: 1200px;
  height: 250px;
  display: flex;
  justify-content: space-around;
  img {
    width: 419px;
    height: 250px;
  }
}
.banner-zi {
  margin-top: 82px;
  width: 503px;
  height: 124px;
  display: block;
  font-size: 48px;
  color: #3584ab;
}

.mapBox {
  .amap-box {
    height: 400px;
  }
}
</style>

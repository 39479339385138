<template>
  <div>
    <div class="title">
      <div class="title-box">
        <div class="left-box">
          <div class="fu">
            <img class="img" src="../../img/title.png" alt="" />
            <div class="left-zi">甄工匠</div>
          </div>

          <div class="center-box">
            <div class="tabs" v-for="(item, index) in tab" :key="index">
              <span @click="xianshi(index)">{{ item.name }}</span>
              <div class="xians" v-if="xian == index ? true : false"></div>
            </div>
          </div>
          <div :class="xian >= 1 ? 'right-bo' : 'right-box'">
            <div class="right-app">
              <div class="zi">APP</div>
              <img src="../../img/Rectangle 12@3x.png" alt="" />
              <img class="shouji" src="../../img/Frame 32.png" alt="" />
            </div>
            <div class="right-ying">
              <div class="zi">应聘</div>
              <img class="imgame" src="../../img/Rectangle 12@3x.png" alt="" />
              <img class="shouji" src="../../img/Frame 31.png" alt="" />
            </div>
            <div class="right-ying zhao">
              <div class="zi">招聘</div>
              <img class="imgame" src="../../img/Rectangle 12@3x.png" alt="" />
              <img class="wendang" src="../../img/Frame 30.png" alt="" />
            </div>
            <!-- 用户 -->
            <div v-if="xian < 1">
              <img class="use" src="../../img//Group 630.png" alt="" />
            </div>
            <div class="an">
              <div v-if="xian >= 1" class="register" @click="register">
                登录
              </div>
              <div v-if="xian >= 1" class="zuce" @click="zuce">注册</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      xian: -1,
      tab: [
        { name: '首页', id: 0 },
        { name: '职位搜索', id: 1 },
        { name: '求职信息', id: 2 },
        { name: '在线学习', id: 3 },
        { name: '关于我们', id: 4 }
      ]
    }
  },

  computed: {},
  created() {},
  mounted() {},
  methods: {
    getindex(index) {
      this.xian = index
    },
    getindex0(index) {
      this.xian = index
    },
    xianshi(index) {
      console.log(index)
      if (index == 0) {
        this.$router.push('/certification')

        // window.open( './certification')
      } else if (index == 1) {
        this.$router.push('./Position')
        //  window.open( './Position')
      }

      console.log(this.xian)
    },
    register() {
      this.$router.push('/home')
    },
    zuce() {
      this.$router.push('/register')
    }
  }
}
</script>

<style scoped lang="scss">
.an {
  display: flex;
}
.xians {
  margin-top: 11px;
  width: 24px;
  height: 3px;
  border-radius: 1px;
  margin: -15px auto 0;
  background-color: rgba(255, 255, 255, 1);
}
.register {
  margin-top: 8px;
  width: 80px;
  height: 36px;
  background: rgba(2, 154, 255, 1);
  backdrop-filter: blur(24px);
  color: #fff;
  border: 1px solid rgba(19, 197, 221, 1);
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}
.register:hover {
  background: rgba(19, 197, 221, 1);
}
.zuce:hover {
  background: rgba(19, 197, 221, 1);
}
.zuce {
  margin-top: 8px;
  width: 80px;
  height: 36px;
  background: rgba(2, 154, 255, 1);
  backdrop-filter: blur(24px);
  color: #fff;
  border: 1px solid rgba(19, 197, 221, 1);
  text-align: center;
  line-height: 36px;
  margin-left: 10px;
  cursor: pointer;
}

@import './scss/index.scss';
</style>

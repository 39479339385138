<template>
  <div class="printed-box">
    <prise></prise>

    <div class="printed" style="margin-top:148px">
      <!-- 头部 -->
      <!-- <div class="title">
        <div class="title-box">
          <div class="left-box">
            <img class="img" src="../../img/title.png" alt="" />
            <div class="left-zi">建筑人才市场平台</div>
            <div class="center-box">
              <div class="tabs" v-for="(item, index) in tab" :key="index">
                <span @click="xianshi(index)">{{ item.name }}</span>
                <div class="xians" v-if="xian == index ? true : false"></div>
              </div>
            </div>
            <div class="right-box">
              <div class="right-app">
                <div class="zi">APP</div>
                <img src="../../img/Rectangle 12@3x.png" alt="" />
                <img class="shouji" src="../../img/Frame 32.png" alt="" />
              </div>
              <div class="right-ying">
                <div class="zi">应聘</div>
                <img
                  class="imgame"
                  src="../../img/Rectangle 12@3x.png"
                  alt=""
                />
                <img class="shouji" src="../../img/Frame 31.png" alt="" />
              </div>
              <div class="right-ying zhao">
                <div class="zi">招聘</div>
                <img
                  class="imgame"
                  src="../../img/Rectangle 12@3x.png"
                  alt=""
                />
                <img class="wendang" src="../../img/Frame 30.png" alt="" />
              </div>
              用户
              <div>
                <img class="use" src="../../img//Group 630.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 内容区 -->
      <div class="content">
        <div class="tiao" @click="gohome">
          <!-- <img class="imgtiao" src="../../img/73跳过、分享.png" alt="" /> -->
          <div class="tiaozi">跳过>></div>
        </div>

        <div class="caption">
          欢迎您首次登录人才市场平台，请选择您的身份进行认证
        </div>

        <!-- 底部图片 -->
        <div class="bottomn-box">
          <div class="bottomn-boxs" @click="goge">
            <img class="bottomn-img" src="../../img/bangong.png" alt="" />
            <p class="zi">个人</p>
          </div>
          <div class="bottomn-boxs" @click="goTeam">
            <img class="bottomn-img" src="../../img/taolun.png" alt="" />
            <p class="zi">班组</p>
          </div>
          <div class="bottomn-boxs" @click="goqiye">
            <img class="bottomn-img" src="../../img/xzl.png" alt="" />
            <p class="zi">企业</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <foots></foots>
  </div>
</template>

<script>
// import tobs from '../../components/tab/tab.vue'
import prise from '../../components/hometitle/hometitle.vue'
import foots from '../../components/foot/foot.vue'
// import { getLoginUser } from '../../api/login/login'
import { getAuthOrg } from '../../api/orgAuth/orgAuth'
import { getOrg } from '../../api/certification/certification'
import { getJobUserAuth } from '@/api/jobInformation/jobInformation'
import { detailTeam } from "@/api/team/team";
export default {
  data() {
    return {
      xian: 0,
      tab: [
        { name: '首页' },
        { name: '职位搜索' },
        { name: '求职信息' },
        { name: '在线学习' },
        { name: '关于我们' }
      ]
    }
  },
  components: {
    foots,
    prise
  },
  computed: {},
  created() {
    if(this.$route.query.from !== 'setting') {
      this.getJobUserAuthForId()
      this._getOrg()
      this.detailTeamId()
    }
  },
  mounted() {},
  methods: {
    // 查询个人认证信息
    getJobUserAuthForId() {
      getJobUserAuth().then(res => {
        if (res.data) {
          this.$router.push('/home')
        }
      })
    },
    detailTeamId(){
      let jobUserId = localStorage.getItem("userId");
      detailTeam({ jobUserId }).then((res) => {
       if (res.data) {
          this.$router.push('/home')
        }
      });
    },
    // 查询企业认证信息
    _getOrg() {
      getOrg().then(res => {
        if(res.data) {
          this.$router.push('/home')
        }
      })
    },
    xianshi(index) {
      this.xian = index
    },
    goqiye() {
      // 获取企业信息
      getOrg().then((res) => {
        console.log(res, 'res')
        if (!res.data || res.data.auditStatus == 3 || res.data.auditStatus) {
          this.$router.push('/certification')
        } else if (res.data.auditStatus == 2) {
          this.$router.push('/home')
        } else {
          this.$router.push('/home')
        }
      })
    },
    goTeam(){
      this.$router.push('/team')
    },
    goge() {
      this.$router.push('/authentication')
    },
    // 跳过
    gohome() {
      this.$router.push('/home')
    }
  }
}
</script>
<style scoped lang="scss">
.tiao {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  float: right;
  // margin-right: 50px;
  .imgtiao {
    width: 50px;
    height: 50px;
  }
}

.tiaozi {
  float: right;
  margin-right: 40px;
  color: #999999;
  font-size: 20px;
}

.xians {
  margin-top: 11px;
  width: 24px;
  height: 3px;
  border-radius: 1px;
  margin: -15px auto 0;
  background-color: rgba(255, 255, 255, 1);
}

@import './scss/index.scss';
</style>

<template>
  <div
    class="qi"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- 版心 -->
    <div :class="show == 4 ? 'qitye-titles' : 'qitye-title'">
      <!-- 复用头部 -->
      <!-- <futongtitle ref="productImage"> </futongtitle> -->
      <certification ref="productImage"> </certification>
      <div>
        <div class="banner">
          <div class="banner-img">
            <div class="banner-zi">班组认证</div>
            <img src="../../../img/youtu.png" alt="" />
          </div>
        </div>
      </div>
      <div :class="show == 4 ? 'beis' : 'bei'">
        <div class="mac">班组认证</div>
        <!-- 步骤条 -->
        <div class="renwu">
          <div class="renwu1">
            <div class="xinxi bgcc" v-if="show == 1">1</div>
            <img v-else class="duigou" src="../../../img/对钩.png" alt="" />
            <div class="xingxiz">基本信息</div>
            <div class="xian"></div>

            <!-- 上传证件 -->
            <div :class="show >= 2 ? 'bgc' : 'xi'" v-if="show <= 2">2</div>
            <img v-else class="duigou" src="../../../img/对钩.png" alt="" />
            <div class="xingxiz">成员信息</div>

            <div :class="show > 2 ? 'xian' : 'xian1'"></div>

            <!-- <div class="xinxi xi">3</div> -->
            <div :class="show >= 3 ? 'bgc' : 'xi'" v-if="show <= 3">3</div>
            <img v-else class="duigou" src="../../../img/对钩.png" alt="" />
            <div class="xingxiz">项目经验</div>

            <div :class="show > 3 ? 'xian' : 'xian1'"></div>

            <div :class="show >= 4 ? 'bgc' : 'xi'">4</div>
            <div class="xingxiz">等待审核</div>
          </div>
        </div>
        <!-- 基本信息 -->
        <div class="qi-box" v-if="show == 1">
          <div class="top-box">
            <div class="jiben">
              <div class="tiao"></div>
              <div class="xi">基本信息</div>
            </div>
          </div>

          <el-form
            :inline="true"
            :model="orgInfo"
            label-width="auto"
            class="demo-form-inline"
            :rules="rules"
            ref="orgInfo"
          >
            <!-- 班组长名称 -->
            <el-form-item label="班组长名称:" prop="name">
              <el-input
                v-model="orgInfo.name"
                placeholder="请输入"
                class="ipt blockx"
              ></el-input>
            </el-form-item>
            <!-- 班组人数 -->
            <el-form-item label="班组人数:" class="rig" prop="orgCode">
              <el-input
                v-model="orgInfo.orgCode"
                placeholder="数量不得少于3人  注: 班组人数=( 班组长 + 成员 )"
                class="iptssx"
              ></el-input>
            </el-form-item>
            <!-- 性别 -->
            <el-form-item label="性别:" prop="sex">
              <el-select
                v-model="orgInfo.sex"
                class="ipt"
                placeholder="请选择性别"
              >
                <el-option
                  v-for="item in sex"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- 职位 -->
            <el-form-item label="职位:" prop="postTypeId" class="rig">
              <el-cascader
                class="iptssx"
                v-model="orgInfo.postTypeId"
                :options="postTypeList"
                :show-all-levels="false"
                :props="typeProps"
                collapse-tags
                @change="choosePostType"
                placeholder="职位类型"
              ></el-cascader>
            </el-form-item>
            <!-- 所在城市 -->
            <el-form-item label="所在城市:" prop="desc">
              <el-select
                style="width: 165px; margin-left: 25px"
                v-model="orgInfo.desc"
                placeholder="请选择省份"
                @change="handleChangeProvincial"
              >
                <el-option
                  v-for="item in provincialList"
                  :key="item.PROVINCE_CODE"
                  :label="item.SHORT_NAME"
                  :value="item.PROVINCE_CODE"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="city">
              <el-select
                style="width: 160px; margin-left: 25px"
                v-model="orgInfo.city"
                @change="handleChangeCity"
                class="blockss"
              >
                <el-option
                  placeholder="请选择城市"
                  v-for="item in cityList"
                  :key="item.CITY_CODE"
                  :label="item.SHORT_NAME"
                  :value="item.CITY_CODE"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- 班组类型 -->
            <el-form-item label="班组类型:" prop="properties" class="rig">
              <el-select
                v-model="orgInfo.properties"
                multiple
                placeholder="请选择"
                class="iptssx"
              >
                <el-option
                  v-for="item in team_type"
                  :key="item.value"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 身份证号 -->
            <el-form-item label="身份证号:" prop="identityCode">
              <el-input
                v-model="orgInfo.identityCode"
                label-width="400px"
                placeholder="请输入"
                class="ipt"
              ></el-input>
            </el-form-item>
            <!-- 联系方式 -->
            <el-form-item label="联系方式:" class="rig" prop="phone">
              <el-input
                v-model="orgInfo.phone"
                label-width="400px"
                placeholder="请输入"
                class="iptssx"
              ></el-input>
            </el-form-item>
            <el-form-item label="学历：" prop="edu">
              <el-select
                v-model="orgInfo.edu"
                class="ipt"
                placeholder="请选择学历"
              >
                <el-option
                  v-for="(item, index) in jobEdu"
                  :key="index"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <div class="head-height"></div>
            <el-form-item label="身份证正面照：" prop="identityCard1">
              <el-upload
                drag
                name="uploadfile"
                :action="$global.uploadAction"
                :before-upload="beforeUpload"
                :on-success="upSuccess1"
                :on-error="upError"
                :on-remove="upRemve"
                :on-change="upChange"
                :show-file-list="false"
                multiple
                class="uploadBox"
              >
                <img
                  v-if="orgInfo.identityCard1"
                  :src="orgInfo.identityCard1"
                  alt=""
                />
                <img v-else src="http://121.36.46.11:9000/job-app/56932_1.png" alt="" />
                <div class="el-upload__tip" slot="tip">
                  <span>请上传身份证正面带有头像的清晰图片</span>
                  <span>大小:≤5M</span>
                  <span>格式为：.jpg .png</span>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="身份证反面照：" prop="identityCard0">
              <el-upload
                drag
                name="uploadfile"
                :action="$global.uploadAction"
                :before-upload="beforeUpload"
                :on-success="upSuccess2"
                :on-error="upError"
                :on-remove="upRemve"
                :on-change="upChange"
                :show-file-list="false"
                multiple
                class="uploadBox"
              >
                <img
                  v-if="orgInfo.identityCard0"
                  :src="orgInfo.identityCard0"
                  alt=""
                />
                <img v-else src="http://121.36.46.11:9000/job-app/56932_2.png" alt="" />
                <div class="el-upload__tip" slot="tip">
                  <span>请上传身份证反面带有国徽的清晰图片</span>
                  <span>大小:≤5M</span>
                  <span>格式为：.jpg .png</span>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="学历证书：" prop="degreeCertificate">
              <el-upload
                drag
                name="uploadfile"
                :action="$global.uploadAction"
                :before-upload="beforeUpload"
                :on-success="upSuccess3"
                :on-error="upError"
                :on-remove="upRemve"
                :on-change="upChange"
                :show-file-list="false"
                multiple
                class="uploadBox"
              >
                <img
                  v-if="orgInfo.degreeCertificate"
                  :src="orgInfo.degreeCertificate"
                  alt=""
                />
                <img v-else src="http://121.36.46.11:9000/job-app/56932_3.png" alt="" />
                <div class="el-upload__tip" slot="tip">
                  <span>请上传学历证书的清晰图片</span>
                  <span>大小:≤5M</span>
                  <span>格式为：.jpg .png</span>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="资格证书：" prop="qualificationCertificate">
              <el-upload
                drag
                name="uploadfile"
                :action="$global.uploadAction"
                :before-upload="beforeUpload"
                :on-success="upSuccess4"
                :on-error="upError"
                :on-remove="upRemve"
                :on-change="upChange"
                :show-file-list="false"
                multiple
                class="uploadBox"
              >
                <img src="http://121.36.46.11:9000/job-app/56932_5.png" alt="" />
                <div class="el-upload__tip" slot="tip">
                  <span>请上传资格证书的清晰图片</span>
                  <span>大小:≤5M</span>
                  <span>格式为：.jpg .png</span>
                </div>
              </el-upload>
              <div
                v-if="orgInfo.qualificationCertificate.length > 0"
                class="upload-img upload-flex"
              >
                <div
                  v-for="(item, idx) in orgInfo.qualificationCertificate"
                  :key="idx"
                  class="upload-hover"
                  style="margin-left: 20px"
                  @click="delImg2(idx)"
                >
                  <img
                    class="upload-icon"
                    :src="item"
                    alt=""
                  />
                  <div class="card-btn">
                    <div class="del-icon">×</div>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="职称证书：" prop="titleCertificate">
              <el-upload
                drag
                name="uploadfile"
                :action="$global.uploadAction"
                :before-upload="beforeUpload"
                :on-success="upSuccess5"
                :on-error="upError"
                :on-remove="upRemve"
                :on-change="upChange"
                :show-file-list="false"
                multiple
                class="uploadBox"
              >
                <img src="http://121.36.46.11:9000/job-app/56932_6.png" alt="" />
                <div class="el-upload__tip" slot="tip">
                  <span>请上传职称证书的清晰图片</span>
                  <span>大小:≤5M</span>
                  <span>格式为：.jpg .png</span>
                </div>
              </el-upload>
              <div
                v-if="orgInfo.titleCertificate.length > 0"
                class="upload-img upload-flex"
              >
                <div
                  v-for="(item, idx) in orgInfo.titleCertificate"
                  :key="idx"
                  class="upload-hover"
                  style="margin-left: 20px"
                  @click="delImg3(idx)"
                >
                  <img
                    class="upload-icon"
                    :src="item"
                    alt=""
                  />
                  <div class="card-btn">
                    <div class="del-icon">×</div>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="工作证明：" prop="pow">
              <el-upload
                drag
                name="uploadfile"
                :action="$global.uploadAction"
                :before-upload="beforeUpload"
                :on-success="upSuccess6"
                :on-error="upError"
                :on-remove="upRemve"
                :on-change="upChange"
                :show-file-list="false"
                multiple
                class="uploadBox"
              >
                <img
                  v-if="orgInfo.pow"
                  :src="orgInfo.pow"
                  alt=""
                />
                <img v-else src="http://121.36.46.11:9000/job-app/56932_4.png" alt="" />
                <div class="el-upload__tip" slot="tip">
                  <span>请上传工作证明的清晰图片</span>
                  <span>大小:≤5M</span>
                  <!-- .rar .zip .doc .docx .pdf -->
                  <span>格式为：.jpg .png</span>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="班组荣誉证书：" prop="titleHonor">
              <el-upload
                drag
                name="uploadfile"
                :action="$global.uploadAction"
                :before-upload="beforeUpload"
                :on-success="upSuccess7"
                :on-error="upError"
                :on-remove="upRemve"
                :on-change="upChange"
                :show-file-list="false"
                multiple
                class="uploadBox"
              >
                <img src="http://121.36.46.11:9000/job-app/56932_9.png" alt="" />
                <div class="el-upload__tip" slot="tip">
                  <span>请上传荣誉证书的清晰图片</span>
                  <span>大小:≤5M</span>
                  <span>格式为：.jpg .png</span>
                </div>
              </el-upload>
              <div
                v-if="orgInfo.titleHonor.length > 0"
                class="upload-img upload-flex"
              >
                <div
                  v-for="(item, idx) in orgInfo.titleHonor"
                  :key="idx"
                  class="upload-hover"
                  style="margin-left: 20px"
                  @click="delImg4(idx)"
                >
                  <img
                    class="upload-icon"
                    :src="item"
                    alt=""
                  />
                  <div class="card-btn">
                    <div class="del-icon">×</div>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <!-- 成员信息 -->
        <div class="qi-box" v-if="show == 2">
          <div class="top-box top-bos">
            <div class="jiben">
              <div class="tiao"></div>
              <div class="xi">新增组员信息</div>
            </div>
          </div>
          <!-- 成员信息 -->
          <el-form
            ref="workExperienceForm"
            label-position="right"
            :model="workExperienceForm"
            class="demo-form-inline"
          >
            <div class="workExperience">
              <div class="title">
                <div class="addForm" @click="addWorkExperienceForm">
                  <div class="add">+</div>
                  <span>请新增 {{ orgInfo.orgCode - 1 }}位组员信息</span>
                </div>
              </div>
              <div
                class="content"
                v-for="(
                  workExperienceFormItem, index
                ) in workExperienceForm.works"
                :key="index"
              >
                <div class="centerDel" v-if="index != 0">
                  <div class="del" @click="deleteItem(index)">
                    <div class="del-icon">×</div>
                    <div class="btn-del">删除</div>
                  </div>
                </div>
                <el-row align="middle" :gutter="10">
                  <el-col :span="8">
                    <el-form-item
                      label="姓名:"
                      :prop="`works.${index}.name`"
                      :rules="formRules.name"
                    >
                      <el-input
                        class="work-input"
                        v-model="workExperienceFormItem.name"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="身份证号:"
                      :prop="`works.${index}.idCode`"
                      :rules="formRules.idCode"
                      class="rigs"
                    >
                      <el-input
                        class="work-input"
                        v-model="workExperienceFormItem.idCode"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="职位:"
                      :prop="`works.${index}.workTeam`"
                      :rules="formRules.workTeam"
                    >
                      <el-cascader
                        class="work-input"
                        v-model="workExperienceFormItem.workTeam"
                        :options="postTypeList"
                        :show-all-levels="false"
                        :props="typeProps"
                        collapse-tags
                        @change="choosePostTeam($event, index)"
                        placeholder="职位类型"
                      ></el-cascader>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row align="middle" :gutter="10">
                  <el-col :span="8">
                    <el-form-item
                      label="性别:"
                      :prop="`works.${index}.sex`"
                      :rules="formRules.sex"
                    >
                      <el-select
                        v-model="workExperienceFormItem.sex"
                        class="work-input"
                        placeholder="请选择性别"
                      >
                        <el-option
                          v-for="item in sex"
                          :key="item.name"
                          :label="item.name"
                          :value="item.code"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="5">
                    <el-form-item
                      label="所在地区:"
                      :prop="`works.${index}.desc`"
                      :rules="formRules.desc"
                    >
                      <el-select
                        style="width: 125px"
                        v-model="workExperienceFormItem.desc"
                        placeholder="请选择省份"
                        @change="handleChangeProvincial2($event, index)"
                      >
                        <el-option
                          v-for="item in provincialList"
                          :key="item.PROVINCE_CODE"
                          :label="item.SHORT_NAME"
                          :value="item.PROVINCE_CODE"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item
                      :prop="`works.${index}.city`"
                      :rules="formRules.city"
                    >
                      <el-select
                        style="width: 120px"
                        v-model="workExperienceFormItem.city"
                        @change="handleChangeCity2($event, index)"
                        class="blockss"
                      >
                        <el-option
                          placeholder="请选择城市"
                          v-for="item in workExperienceFormItem.cityList"
                          :key="item.CITY_CODE"
                          :label="item.SHORT_NAME"
                          :value="item.CITY_CODE"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="联系方式:"
                      :prop="`works.${index}.phone`"
                      :rules="formRules.phone"
                    >
                      <el-input
                        class="work-input"
                        v-model="workExperienceFormItem.phone"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <div class="photo-box">
                  <el-form-item
                    label="身份证正面照："
                    :prop="`works.${index}.identityCard1`"
                    :rules="formRules.identityCard1"
                  >
                    <el-upload
                      drag
                      name="uploadfile"
                      :action="$global.uploadAction"
                      :before-upload="beforeUpload"
                      :on-success="
                        (res) => {
                          return upSuccessCut1(res, index);
                        }
                      "
                      :on-error="upError"
                      :on-remove="upRemve"
                      :on-change="upChange"
                      :show-file-list="false"
                      multiple
                      class="uploadBox2"
                    >
                      <img
                        class="upload-icon"
                        v-if="workExperienceFormItem.identityCard1"
                        :src="workExperienceFormItem.identityCard1"
                        alt=""
                      />
                      <img
                        v-else
                        class="upload-icon"
                        src="@/assets/personCenter/update-photo.png"
                        alt=""
                      />
                      <div class="el-upload__tip" slot="tip">
                        <span>请上传身份证正面带有头像的清晰图片</span>
                        <span>大小:≤5M</span>
                        <span>格式为：.jpg .png</span>
                      </div>
                    </el-upload>
                  </el-form-item>
                  <el-form-item
                    label="身份证反面照："
                    :prop="`works.${index}.identityCard0`"
                    :rules="formRules.identityCard0"
                  >
                    <el-upload
                      drag
                      name="uploadfile"
                      :action="$global.uploadAction"
                      :before-upload="beforeUpload"
                      :on-success="
                        (res) => {
                          return upSuccessCut2(res, index);
                        }
                      "
                      :on-error="upError"
                      :on-remove="upRemve"
                      :on-change="upChange"
                      :show-file-list="false"
                      multiple
                      class="uploadBox2"
                    >
                      <img
                        class="upload-icon"
                        v-if="workExperienceFormItem.identityCard0"
                        :src="workExperienceFormItem.identityCard0"
                        alt=""
                      />
                      <img
                        v-else
                        class="upload-icon"
                        src="@/assets/personCenter/update-photo.png"
                        alt=""
                      />
                      <div class="el-upload__tip" slot="tip">
                        <span>请上传身份证反面带有国徽的清晰图片</span>
                        <span>大小:≤5M</span>
                        <span>格式为：.jpg .png</span>
                      </div>
                    </el-upload>
                  </el-form-item>
                  <el-form-item
                    label="学历证书："
                    :prop="`works.${index}.degreeCertificate`"
                  >
                    <el-upload
                      drag
                      name="uploadfile"
                      :action="$global.uploadAction"
                      :before-upload="beforeUpload"
                      :on-success="
                        (res) => {
                          return upSuccessCut3(res, index);
                        }
                      "
                      :on-error="upError"
                      :on-remove="upRemve"
                      :on-change="upChange"
                      :show-file-list="false"
                      multiple
                      class="uploadBox2"
                    >
                      <img
                        class="upload-icon"
                        v-if="workExperienceFormItem.degreeCertificate"
                        :src="workExperienceFormItem.degreeCertificate"
                        alt=""
                      />
                      <img
                        v-else
                        class="upload-icon"
                        src="@/assets/personCenter/update-photo.png"
                        alt=""
                      />
                      <div class="el-upload__tip" slot="tip">
                        <span
                          >小学到高中学历无需提供证书,中专及以上需要提供。</span
                        >
                        <span>大小:≤5M</span>
                        <span>格式为：.jpg .png</span>
                      </div>
                    </el-upload>
                  </el-form-item>
                  <el-form-item
                    label="资格证书："
                    :prop="`works.${index}.qualificationCertificate`"
                  >
                    <el-upload
                      drag
                      :limit="6"
                      name="uploadfile"
                      :action="$global.uploadAction"
                      :before-upload="beforeUpload"
                      :on-success="
                        (res) => {
                          return upSuccessCut4(res, index);
                        }
                      "
                      :on-error="upError"
                      :on-remove="upRemve"
                      :on-change="upChange"
                      :show-file-list="false"
                      multiple
                      class="uploadBox2"
                    >
                      <img
                        class="upload-icon"
                        src="@/assets/personCenter/update-photo.png"
                        alt=""
                      />
                    </el-upload>
                    <div
                      v-if="
                        workExperienceFormItem.qualificationCertificate.length >
                        0
                      "
                      class="upload-img"
                    >
                      <div
                        v-for="(
                          item, idx
                        ) in workExperienceFormItem.qualificationCertificate"
                        :key="idx"
                        class="upload-hover"
                        @click="delImg1(index, idx)"
                      >
                        <img
                          class="upload-icon"
                          :src="item"
                          alt=""
                        />
                        <div class="card-btn">
                          <div class="del-icon">×</div>
                        </div>
                      </div>
                    </div>
                    <div class="el-upload__tip">
                      <span>请上传资格证书的清晰图片</span>
                      <span>大小:≤5M</span>
                      <span>格式为：.jpg .png</span>
                    </div>
                  </el-form-item>
                  <el-form-item
                    label="职称证书："
                    :prop="`works.${index}.titleCertificate`"
                  >
                    <el-upload
                      drag
                      :limit="6"
                      name="uploadfile"
                      :action="$global.uploadAction"
                      :before-upload="beforeUpload"
                      :on-success="
                        (res) => {
                          return upSuccessCut5(res, index);
                        }
                      "
                      :on-error="upError"
                      :on-remove="upRemve"
                      :on-change="upChange"
                      :show-file-list="false"
                      multiple
                      class="uploadBox2"
                    >
                      <img
                        class="upload-icon"
                        src="@/assets/personCenter/update-photo.png"
                        alt=""
                      />
                    </el-upload>

                    <div
                      v-if="workExperienceFormItem.titleCertificate.length > 0"
                      class="upload-img"
                    >
                      <div
                        v-for="(
                          item, idx
                        ) in workExperienceFormItem.titleCertificate"
                        :key="idx"
                        class="upload-hover"
                        @click="delImg(index, idx)"
                      >
                        <img
                          class="upload-icon"
                          :src="item"
                          alt=""
                        />
                        <div class="card-btn">
                          <div class="del-icon">×</div>
                        </div>
                      </div>
                    </div>
                    <div class="el-upload__tip">
                      <span>请上传职称证书的清晰图片</span>
                      <span>大小:≤5M</span>
                      <span>格式为：.jpg .png</span>
                    </div>
                  </el-form-item>
                  <el-form-item label="工作证明：" :prop="`works.${index}.pow`">
                    <el-upload
                      drag
                      name="uploadfile"
                      :action="$global.uploadAction"
                      :before-upload="beforeUpload"
                      :on-success="
                        (res) => {
                          return upSuccessCut6(res, index);
                        }
                      "
                      :on-error="upError"
                      :on-remove="upRemve"
                      :on-change="upChange"
                      :show-file-list="false"
                      multiple
                      class="uploadBox2"
                    >
                      <img
                        v-if="workExperienceFormItem.pow"
                        class="upload-icon"
                        :src="workExperienceFormItem.pow"
                        alt=""
                      />
                      <img
                        v-else
                        class="upload-icon"
                        src="@/assets/personCenter/update-photo.png"
                        alt=""
                      />
                      <div class="el-upload__tip" slot="tip">
                        <span>请上传工作证明的清晰图片</span>
                        <span>大小:≤5M</span>
                        <span>格式为：.jpg .png</span>
                      </div>
                    </el-upload>
                  </el-form-item>
                </div>
              </div>
            </div>
          </el-form>
        </div>
        <!-- 项目经验 -->
        <div class="qi-box" v-if="show == 3">
          <!-- 项目经历 -->
          <div class="top-box top-bos">
            <div class="jiben">
              <div class="tiao"></div>
              <div class="xi">项目经历</div>
            </div>
          </div>
          <el-form
            ref="projectInfo"
            label-position="right"
            :model="projectInfo"
            class="demo-form-inline"
          >
            <div class="workExperience">
              <div class="title">
                <div class="addForm" @click="addProjectInfo">
                  <div class="add">+</div>
                  <span>新增项目经历</span>
                </div>
              </div>
              <div
                class="content"
                v-for="(projectInfoItem, index) in projectInfo.infos"
                :key="index"
              >
                <div class="centerDel" v-if="index != 0">
                  <div class="del" @click="deleteInfo(index)">
                    <div class="del-icon">×</div>
                    <div class="btn-del">删除</div>
                  </div>
                </div>
                <el-row align="middle" :gutter="10">
                  <el-col :span="8">
                    <el-form-item
                      label="项目名称:"
                      :prop="`infos.${index}.title`"
                    >
                      <el-input
                        class="work-input"
                        v-model="projectInfoItem.title"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="所属公司:"
                      :prop="`infos.${index}.company`"
                      class="rigs"
                    >
                      <el-input
                        class="work-input"
                        v-model="projectInfoItem.company"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="班组类型:"
                      :prop="`infos.${index}.typeWork`"
                    >
                      <el-select
                        v-model="projectInfoItem.typeWork"
                        multiple
                        placeholder="请选择"
                        class="work-input"
                      >
                        <el-option
                          v-for="item in team_type"
                          :key="item.value"
                          :label="item.name"
                          :value="item.code"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="15">
                    <el-form-item
                      label="项目时间:"
                      :prop="`infos.${index}.workTime`"
                    >
                      <el-date-picker
                        v-model="projectInfoItem.workTime"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="请选择项目开始时间"
                        end-placeholder="请选择项目结束时间"
                        @change="pickerChange(index)"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item
                  label="佐证图片："
                  :prop="`infos.${index}.titleProve`"
                >
                  <el-upload
                    drag
                    name="uploadfile"
                    :action="$global.uploadAction"
                    :before-upload="beforeUpload"
                    :on-success="
                      (res) => {
                        return proveSuccess(res, index);
                      }
                    "
                    :on-error="upError"
                    :on-remove="upRemve"
                    :on-change="upChange"
                    :show-file-list="false"
                    multiple
                    class="uploadBox"
                  >
                    <img src="http://121.36.46.11:9000/job-app/56932_7.png" alt="" />
                    <div class="el-upload__tip" slot="tip">
                      <span>请上传佐证的清晰图片</span>
                      <span>大小:≤5M</span>
                      <span>格式为：.jpg .png</span>
                    </div>
                  </el-upload>
                  <div
                    v-if="projectInfoItem.titleProve.length > 0"
                    class="upload-img upload-flex"
                  >
                    <div
                      v-for="(item, idx) in projectInfoItem.titleProve"
                      :key="idx"
                      class="upload-hover"
                      style="margin-left: 20px"
                      @click="proveImg(index,idx)"
                    >
                      <img
                        class="upload-icon"
                        :src="item"
                        alt=""
                      />
                      <div class="card-btn">
                        <div class="del-icon">×</div>
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <!-- 已提交 -->
        <div v-if="show == 4">
          <img
            class="diannao"
            src="../../../img/authentication_icon_shenhezhong_normal@3x.png"
            alt=""
          />
          <div class="miaoshuya" v-if="showStatus == 2">审核已通过</div>
          <div class="miaoshuya" v-else>
            您的资料已经提交完成，请耐心等待平台审核
          </div>
        </div>
        <div class="btn-box">
          <div v-if="show > 1 && show < 4" class="btn" @click="toBack()">
            上一步
          </div>
          <!-- <div class="btn">保存</div> -->
          <div v-if="show < 4" class="btn" @click="submitForm()">下一步</div>
        </div>
      </div>
    </div>
    <foots :class="show == 3 ? 'footaa' : ''"></foots>
  </div>
</template>

<script>
import { preview } from "@/api/certification/certification";
import { addTeam, detailTeam } from "@/api/team/team";
import tobs from "../../../components/tab/tab.vue";
import certification from "../../../components/hometitle/hometitle.vue";
import foots from "../../../components/foot/foot.vue";
import throttle from "lodash/throttle"; //节流
import { getToken } from "../../../utils/myauth";
import { getProvincial, getCity } from "@/api/index/index";
import { jobPostType } from "@/api/position/position";
import moment from "moment";
export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      let regTest =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!value) {
        callback(new Error("请输入手机号码"));
      } else {
        if (!regTest.test(value)) {
          callback(new Error("手机号码格式不正确"));
          return;
        }
        callback();
      }
    };
    const identityCode = (rule, value, callback) => {
      let regTest =
        /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (!value) {
        callback(new Error("请输入身份证号"));
      } else {
        if (!regTest.test(value)) {
          callback(new Error("身份证格式不正确"));
          return;
        }
        callback();
      }
    };
    return {
      imgPath: "",
      center: [114.304576, 30.593374],
      zoom: 9,
      lng: "",
      lat: "",
      events: {},
      dialogInputVisible: false, //地图对话框开关
      postTypeList: [], //职位类型列表
      typeProps: {
        value: "id",
        label: "title",
        children: "children",
        multiple: true,
      },
      Token: [],
      pid: [],
      city: [],
      loading: true,
      up: false,
      ups: false,
      codeImg: [],
      active: 0,
      show: 1,
      showStatus: "",
      name: "",
      value: [],
      rules: {
        name: [
          { required: true, message: "请输入班组长名称", trigger: "blur" },
        ],
        orgCode: [
          {
            required: true,
            message: "班组人数影响下一步成员信息,请准确填写",
            trigger: "blur",
          },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        postTypeId: [
          { required: true, message: "请选择职位类型", trigger: "blur" },
        ],
        desc: [{ required: true, message: "请选择区域", trigger: " change" }],
        city: [{ required: true, message: "请选择区域", trigger: " change" }],
        properties: [
          { required: true, message: "请选择班组类型", trigger: "blur" },
        ],
        edu: [{ required: true, message: "请选择学历", trigger: "blur" }],
        identityCode: [{ required: true, validator: identityCode }],
        phone: [{ required: true, validator: validatePhone }],
        identityCard1: [
          { required: true, message: "请上传身份证正面附件", trigger: "blur" },
        ],
        identityCard0: [
          { required: true, message: "请上传身份证反面附件", trigger: "blur" },
        ],
      },
      formRules: {
        name: [{ required: true, message: "请输入成员姓名", trigger: "blur" }],
        idCode: [{ required: true, validator: identityCode }],
        workTeam: [
          { required: true, message: "请选择职位类型", trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        desc: [{ required: true, message: "请选择区域", trigger: " change" }],
        city: [{ required: true, message: "请选择区域", trigger: " change" }],
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
        identityCard1: [
          { required: true, message: "请上传身份证正面附件", trigger: "blur" },
        ],
        identityCard0: [
          { required: true, message: "请上传身份证反面附件", trigger: "blur" },
        ],
      },
      dictData: JSON.parse(localStorage.getItem("dict")),
      team_type: [],
      job_gm: [],
      sex: [],
      jobEdu: [],
      orgInfo: {
        jobUserId: "",
        sex: "",
        edu: "",
        postTypeId: [],
        name: "",
        city: "",
        cityName: "",
        orgCode: "",
        properties: [],
        identityCode: "",
        phone: "",
        desc: "",
        identityCode: "", //身份证号
        identityCard1: "", //身份证正面
        identityCard0: "", //身份证背面
        degreeCertificate: "", //学历证书
        qualificationCertificate: [], //资格证书
        titleCertificate: [], //职称证书
        pow: "", //工作证明
        titleHonor: [], //荣誉证书
      },
      // 成员信息
      workExperienceForm: {
        works: [
          {
            itemType: 1,
            name: "",
            sex: "",
            workTeam: [],
            idCode: "",
            phone: null,
            desc: null,
            city: "",
            cityName: "",
            cityList: [],
            identityCard1: "", //身份证正面
            identityCard0: "", //身份证背面
            degreeCertificate: "", //学历证书
            qualificationCertificate: [], //资格证书
            titleCertificate: [], //职称证书
            pow: "", //工作证明
          },
        ],
      },
      projectInfo: {
        infos: [
          {
            itemType: 2,
            title: "",
            company: "",
            workTime: [],
            typeWork: [],
            titleProve: [],
          },
        ],
      },
      orgAuthInfo: { orgCode: "", businessLicense: "", jobPermit: "" },
      addtype: "add",
      unitType: [], //单位类型
      provincialList: [], //省份列表
      cityList: [], //城市列表
      authOrg: {}, //认证信息
      params: {},
    };
  },
  components: {
    tobs,
    foots,
    certification,
  },
  created() {
    this.getJobPostType();
    this.cityList = JSON.parse(localStorage.getItem("cityList"));
    this.orgInfo.jobUserId = localStorage.getItem("userId");
    this._getProvincial();
    this.Token = getToken();
    this.detailTeamInfo();
    this.team_type = this.dictData.find((e) => e.code === "team_type").children;
    // 性别
    this.sex = this.dictData.filter((i) => i.code == "sex")[0].children;
    // 学历
    this.jobEdu = this.dictData.filter((i) => i.code == "job_edu")[0].children;
    // 工作类型
    this.job_gm = this.dictData.find((e) => e.code === "job_gm").children;
  },
  mounted() {
    this.$refs.productImage.getindex0(-1);
    this.events.click = this.click;
  },
  methods: {
    // 获取班组认证信息
    detailTeamInfo() {
      this.loading = false;
      let jobUserId = localStorage.getItem("userId");
      detailTeam({ jobUserId }).then((res) => {
        this.loading = false; //加载窗
        // 如果没有班组信息，在第一步
        if (res.data == null) {
          this.show = 1;
        }
        // 查得到班组信息，查不到认证信息，在第4步
        if (res.data) {
          this.orgAuthInfo.orgCode = res.data.orgCode;
          if (res.data.auditStatus == 3) {
            this.show = 1;
            this.infoDetail(res);
          } else {
            this.show = 4;
          }
          this.showStatus = res.data.auditStatus;
        }
      });
    },
    // 回显数据信息
    infoDetail(res) {
      this.orgInfo.desc = this.cityList.filter(
        (i) => i.CITY_CODE == res.data.city
      )[0].PROVINCE_CODE;
      this.orgInfo.city = res.data.city;
      this.orgInfo.name = res.data.name;
      this.orgInfo.orgCode = res.data.num;
      this.orgInfo.sex = res.data.sex;
      this.orgInfo.postTypeId = res.data.postType.split(",");
      setTimeout(() => {
        if (this.postTypeList.length > 0) {
        let arr = [];
        this.orgInfo.postTypeId.forEach((i) => {
          let ids = this.changeCascader(i, this.postTypeList);
          if (ids.length > 0) {
            arr.push(ids);
          }
        });
        this.orgInfo.postTypeId = arr;
      }
      }, 1);
      this.orgInfo.properties = res.data.teamType
        ? res.data.teamType.split(",")
        : [];
      this.orgInfo.identityCode = res.data.idCard;
      this.orgInfo.phone = res.data.phone;
      this.orgInfo.edu = res.data.edu;
      this.orgInfo.identityCard1 = res.data.identityCard1;
      this.orgInfo.identityCard0 = res.data.identityCard0;
      this.orgInfo.degreeCertificate = res.data.degreeCertificate;
      this.orgInfo.qualificationCertificate = res.data.qualificationCertificate
        ? res.data.qualificationCertificate.split(",")
        : [];
      this.orgInfo.titleCertificate = res.data.titleCertificate
        ? res.data.titleCertificate.split(",")
        : [];
      this.orgInfo.titleHonor = res.data.img ? res.data.img.split(",") : [];
      this.orgInfo.pow = res.data.pow;
      this.orgInfo.id = res.data.id;
      let data = [];
      res.data.users.map((item, index) => {
        let obj = {
          qualificationCertificate: [],
          titleCertificate: [],
        };
        obj.name = item.name;
        obj.sex = item.sex;
        obj.workTeam = item.postType.split(",");
        setTimeout(() => {
          if (this.postTypeList.length > 0) {
          let arr2 = [];
          obj.workTeam.forEach((i) => {
            let ids = this.changeCascader(i, this.postTypeList);
            if (ids.length > 0) {
              arr2.push(ids);
            }
          });
          obj.workTeam = arr2;
        }
        }, 1);
        obj.city = item.city;
        obj.desc = item.province;
        obj.idCode = item.idCard;
        obj.phone = item.phone;
        obj.identityCard1 = item.identityCard1;
        obj.identityCard0 = item.identityCard0;
        obj.degreeCertificate = item.degreeCertificate;
        obj.qualificationCertificate = item.qualificationCertificate
          ? item.qualificationCertificate.split(",")
          : [];
        obj.titleCertificate = item.qualificationCertificate
          ? item.titleCertificate.split(",")
          : [];
        obj.pow = item.pow;
        obj.id = item.id;
        getCity({ PROVINCE_CODE: obj.desc }).then((res) => {
          this.workExperienceForm.works[index].cityList = res.data;
        });
        data.push(obj);
        return item;
      });
      this.workExperienceForm.works = data;
      let data1 = [];
      res.data.projects.map((item, index) => {
        let obj = {
          workTime: [],
        };
        obj.company = item.company;
        obj.title = item.project;
        if(item.startTime || item.endTime){
          obj.workTime[0] = item.startTime ? item.startTime : null;
          obj.workTime[1] = item.endTime ? item.endTime : null;
        }

        obj.typeWork = item.teamType ? item.teamType.split(",") : [];
        obj.titleProve = item.img ? item.img.split(",") : [];
        obj.id = item.id;
        data1.push(obj);
        return item;
      });
      this.projectInfo.infos = data1;
    },
    click(e) {
      this.center = [e.lnglat.lng, e.lnglat.lat];
      this.lng = e.lnglat.lng;
      this.lat = e.lnglat.lat;
      this.$nextTick(() => {
        this.$refs.map.$$getInstance().setFitView();
      });
    },
    // 职位类型
    getJobPostType() {
      jobPostType({ code: "post_type" }).then((res) => {
        this.postTypeList = this.hasChildren(res.data[0].children);
      });
    },
    // 根据职位类型id回显  递归找父级id
    changeCascader(key, arrData) {
      let arr = [];
      let returnArr = []; // 存放结果的数组
      let depth = 0; // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN; // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id;
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
            break;
          } else {
            if (childrenData[j].children) {
              depth++;
              childrenEach(childrenData[j].children, depth);
            }
          }
        }
        return returnArr;
      }
      return childrenEach(arrData, depth);
    },
    // 查询省份
    _getProvincial() {
      getProvincial().then((res) => {
        this.provincialList = res.data;
      });
    },
    // 查询城市
    _getCity(code) {
      getCity({ PROVINCE_CODE: code }).then((res) => {
        this.cityList = res.data;
      });
    },
    // 职位类型
    choosePostType(e) {
      // let arr = [];
      // e.forEach((i) => {
      //   arr.push(i[i.length - 1]);
      // });
    },
    choosePostTeam(e, index) {
      // let arr = [];
      // e.forEach((i) => {
      //   arr.push(i[i.length - 1]);
      // });
      // this.workExperienceForm.works[index].workTeam = val[val.length - 1];
      // this.$refs.workExperienceForm.validateField(
      //   `works.${index}.workTeam`,
      //   (val) => {
      //     if (!val) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   }
      // );
    },
    // 根据省份id查询城市
    handleChangeProvincial(val) {
      this._getCity(val);
    },
    handleChangeProvincial2(val, index) {
      this.workExperienceForm.works[index].city = "";
      getCity({ PROVINCE_CODE: val }).then((res) => {
        this.workExperienceForm.works[index].cityList = res.data;
        this.$forceUpdate();
      });
    },
    // 选择城市名字
    handleChangeCity(val) {
      let cityName = this.cityList.filter((i) => i.CITY_CODE == val)[0]
        .SHORT_NAME;
      this.orgInfo.cityName = cityName;
    },
    handleChangeCity2(val, index) {
      let cityName = this.cityList.filter((i) => i.CITY_CODE == val)[0]
        .SHORT_NAME;
      this.workExperienceForm.works[index].cityName = cityName;
    },
    // 根据单位类型id回显  递归找父级id
    getOrgTypeForTreer(list, id) {
      var ids = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          if (list[i].pid != "1537642960810602497") {
            ids.push(list[i].pid);
          }
          ids.push(list[i].id);
          break;
        } else if (list[i].children && list[i].children.length > 0) {
          ids = this.getOrgTypeForTreer(list[i].children, id);
          if (ids.length > 0) return ids;
        }
      }
      return ids;
    },
    hasChildren(list) {
      list.forEach((item) => {
        if (item.children.length > 0) {
          this.hasChildren(item.children);
        } else {
          item.children = null;
        }
      });
      return list;
    },
    toBack() {
      this.show--;
    },
    handleChange(value) {},
    // 节流
    submitForm: throttle(function () {
      if (this.show == 1) {
        this.$refs["orgInfo"].validate((valid) => {
          if (valid) {
            if (this.show == 1) {
              this.recordInfo(this.orgInfo);
              this.show++;
            }
          } else {
            return false;
          }
        }).catch(error=>{
          console.log(error)
        })
      } else if (this.show == 2) {
        if (this.orgInfo.orgCode - 1 == this.workExperienceForm.works.length) {
          this.$refs["workExperienceForm"].validate((valid) => {
            if (valid) {
              if (this.show == 2) {
                this.recordInfo2(this.workExperienceForm);
                this.show++;
              } else {
                this.show == 4;
              }
            } else {
              return false;
            }
          });
        } else {
          this.$message.error("新增成员数量与你填写成员数量不相等");
        }
      } else if (this.show == 3) {
        if (this.show == 3) {
          this.recordInfo3(this.projectInfo);
          this.addTeamInfo();
        } else {
          this.show == 4;
        }
      } else {
      }
    }, 3000),
    addTeamInfo() {
      if (this.showStatus == 3) {
        this.params.auditStatus = 4;
      }
      addTeam(this.params).then((res) => {
        this.$message({
          type: "success",
          message: "提交成功",
          showClose: true,
          offset: 80,
        });
        this.show++;
      });
    },
    recordInfo(val) {
      let arr = [];
      val.postTypeId.forEach((i) => {
        arr.push(i[i.length - 1]);
      });
      let arr1 = val.properties.join(",");
      let obj = {};
      obj.jobUserId = val.jobUserId;
      obj.name = val.name;
      obj.num = val.orgCode;
      obj.sex = val.sex;
      obj.postType = arr.join(",");
      obj.city = val.city;
      obj.province = val.desc;
      obj.teamType = arr1;
      obj.idCard = val.identityCode;
      obj.phone = val.phone;
      obj.edu = val.edu;
      obj.identityCard1 = val.identityCard1 ? val.identityCard1 : null;
      obj.identityCard0 = val.identityCard0 ? val.identityCard0 : null;
      obj.degreeCertificate = val.degreeCertificate ? val.degreeCertificate : null;
      obj.qualificationCertificate = val.qualificationCertificate.length > 0 ? val.qualificationCertificate.join(",") : null;
      obj.titleCertificate = val.titleCertificate.length > 0 ?val.titleCertificate.join(",") : null;
      obj.img = val.titleHonor.length > 0 ? val.titleHonor.join(",") : null;
      obj.pow = val.pow ? val.pow : null;
      if (this.orgInfo.id) {
        obj.id = this.orgInfo.id;
      }
      this.params = obj;
    },
    recordInfo2(val) {
      let data = [];
      val.works.map((item) => {
        let arr = [];
        item.workTeam.forEach((i) => {
          arr.push(i[i.length - 1]);
        });
        let obj = {};
        obj.name = item.name;
        obj.sex = item.sex;
        obj.postType = arr.join(",");
        obj.city = item.city;
        obj.province = item.desc;
        obj.idCard = item.idCode;
        obj.phone = item.phone;
        obj.identityCard1 = item.identityCard1 ? item.identityCard1 : null;
        obj.identityCard0 = item.identityCard0 ? item.identityCard0 : null;
        obj.degreeCertificate = item.degreeCertificate ? item.degreeCertificate : null;
        obj.qualificationCertificate =  item.qualificationCertificate.length > 0 ? item.qualificationCertificate.join(",") : null;
        obj.titleCertificate = item.titleCertificate.length > 0 ? item.titleCertificate.join(",") : null;
        obj.pow = item.pow ? item.pow : null;
        if (item.id) {
          obj.id = item.id;
        }
        data.push(obj);
        return item;
      });
      this.params.users = data;
    },
    recordInfo3(val) {
      let data = [];
      val.infos.map((item) => {
        let obj = {};
        obj.company = item.company;
        obj.project = item.title;
        if(item.workTime){
          obj.startTime = item.workTime.length > 0 ? moment(item.workTime[0]).format("YYYY-MM-DD HH:mm:ss") : null;
        obj.endTime = item.workTime.length > 0 ? moment(item.workTime[1]).format("YYYY-MM-DD HH:mm:ss") : null;
        }else{
          obj.startTime = null
          obj.endTime = null
        }
        obj.img = item.titleProve.length > 0 ? item.titleProve.join(",") : null;
        let arr1 = item.typeWork.join(",");
        obj.teamType = arr1;
        if (item.id) {
          obj.id = item.id;
        }
        data.push(obj);
        return item;
      });
      this.params.projects = data;
    },
    // 身份证正面上传
    upSuccess1(res) {
      this.orgInfo.identityCard1 = res.msg;
    },
    upSuccessCut1(res, index) {
      this.workExperienceForm.works[index].identityCard1 = res.msg;
    },
    // 身份背正面上传
    upSuccess2(res) {
      this.orgInfo.identityCard0 = res.msg;
    },
    upSuccessCut2(res, index) {
      this.workExperienceForm.works[index].identityCard0 = res.msg;
    },
    // 学历证书上传
    upSuccess3(res) {
      this.orgInfo.degreeCertificate = res.msg;
    },
    upSuccessCut3(res, index) {
      this.workExperienceForm.works[index].degreeCertificate = res.msg;
    },
    // 资格证书上传
    upSuccess4(res) {
      if (this.orgInfo.qualificationCertificate.length < 7) {
        this.orgInfo.qualificationCertificate.push(res.msg);
      } else {
        this.$message.error("上传资格证书不超过7张");
      }
    },
    upSuccessCut4(res, index) {
      this.workExperienceForm.works[index].qualificationCertificate.push(
        res.msg
      );
    },
    delImg2(idx) {
      this.orgInfo.qualificationCertificate.splice(idx, 1);
    },
    delImg1(index, idx) {
      this.workExperienceForm.works[index].qualificationCertificate.splice(
        idx,
        1
      );
    },
    // 职称证书上传
    upSuccess5(res) {
      if (this.orgInfo.titleCertificate.length < 7) {
        this.orgInfo.titleCertificate.push(res.msg);
      } else {
        this.$message.error("上传资格证书不超过7张");
      }
    },
    delImg3(idx) {
      this.orgInfo.titleCertificate.splice(idx, 1);
    },
    upSuccessCut5(res, index) {
      this.workExperienceForm.works[index].titleCertificate.push(res.msg);
    },
    delImg(index, idx) {
      this.workExperienceForm.works[index].titleCertificate.splice(idx, 1);
    },
    // 工作证明上传
    upSuccess6(res) {
      this.orgInfo.pow = res.msg;
    },
    upSuccessCut6(res, index) {
      this.workExperienceForm.works[index].pow = res.msg;
    },
    // 荣誉证书上传
    upSuccess7(res) {
      if (this.orgInfo.titleHonor.length < 7) {
        this.orgInfo.titleHonor.push(res.msg);
      } else {
        this.$message.error("上传荣誉证书不超过7张");
      }
    },
    delImg4(idx) {
      this.orgInfo.titleHonor.splice(idx, 1);
    },
    proveSuccess(res, index) {
      if(res){
        this.projectInfo.infos[index].titleProve.push(res.msg);
      }
    },
    proveImg(index, idx) {
      this.projectInfo.infos[index].titleProve.splice(idx, 1);
    },
    // 上传文件判断
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error("上传图片只能是 .jpg .png 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      }
      return isJPG && isLt2M;
    },
    // 上传失败
    upError() {
      this.$message({
        type: "error",
        message: "上传失败",
        showClose: true,
        offset: 80,
      });
    },
    // 上传成功
    upSuccess(res) {
      this.orgAuthInfo.businessLicense = res.data;
      this.$message({
        type: "success",
        message: "上传成功",
        showClose: true,
        offset: 80,
      });
      this.up = true;
      preview({ id: res.data }).then((res) => {
        let blob = new Blob([res]); // 返回的文件流数据
        let url = window.URL.createObjectURL(blob); // 将他转化为路径
        this.codeImg = url;
      });
    },
    //上传的文件改变时（覆盖原来的文件）
    upChange(file, fileList) {
      // if (fileList.length > 0) {
      //   this.fileList = [fileList[fileList.length - 1]];
      // }
    },
    // 移除列表
    upRemve(file) {
      console.log(file);
    },
    // 添加工作经历表单
    addWorkExperienceForm() {
      let obj = {
        itemType: 1,
        name: "",
        idCode: "",
        workTeam: [],
        sex: "",
        desc: null,
        city: "",
        cityList: [],
        phone: null,
        identityCard1: "", //身份证正面
        identityCard0: "", //身份证背面
        degreeCertificate: "", //学历证书
        qualificationCertificate: [], //资格证书
        titleCertificate: [], //职称证书
        pow: "", //工作证明
      };
      this.workExperienceForm.works.push(obj);
    },
    deleteItem(index) {
      this.workExperienceForm.works.splice(index, 1);
    },
    addProjectInfo() {
      let obj = {
        itemType: 2,
        title: "",
        company: "",
        workTime: [],
        typeWork: [],
        titleProve: [],
      };
      this.projectInfo.infos.push(obj);
    },
    deleteInfo(index) {
      this.projectInfo.infos.splice(index, 1);
    },
    pickerChange(index){
      if(this.projectInfo.infos[index].workTime === null){
         setTimeout(() => {
          this.projectInfo.infos[index].workTime = []
         }, 500);
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "./scss/index.scss";
.posd {
  width: 400px;
  height: 400px;
  display: block;
}
.footaa {
  margin-top: 207px;
}
.banner {
  width: 100%;
  background: url(../../../img/beitong.png) repeat;
  background-size: 100% 100%;
  margin-top: 80px;
}
.banner-img {
  display: block;
  margin: 0 auto;
  width: 1200px;
  height: 250px;
  display: flex;
  justify-content: space-around;
  img {
    width: 419px;
    height: 250px;
  }
}
.banner-zi {
  margin-top: 82px;
  width: 503px;
  height: 124px;
  display: block;
  font-size: 48px;
  color: #3584ab;
}

.mapBox {
  .amap-box {
    height: 400px;
  }
}
</style>

<template>
  <div class="box-titlee" v-if="showPopup">
    <el-dialog
      :visible.sync="videoVisible"
      width="685px"
      :lock-scroll="false"
      :v-text="true"
      :close-on-click-modal="false"
    >
      <div class="videoContainer">
        <div class="close" @click="closeBtn">×</div>
        <video
          ref="bgVid"
          class="fullscreenVideo"
          controlslist="nodownload"
          :controls="controlsShow"
          @ended="
            videoPauseShow = true;
            controlsShow = false;
          "
          :src="videoMask"
          poster="http://121.36.46.11:9000/job-app/video-mask-img.jpg"
          crossOrigin="anonymous"
          mute="muted"
        ></video>
        <div
          v-if="videoPauseShow"
          class="prism-big-play-btn"
          @click.stop="videoPlayBtn"
        >
          <div class="outter"></div>
        </div>
        <!-- <div class="img-box" v-if="videoPauseShow">
          <img src="@/components/popMask/1.jpg" alt="" />
        </div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      controlsShow: false,
      videoPauseShow: true,
      showPopup: false,
      videoVisible: true,
      videoMask: "http://121.36.46.11:9000/job-app/甄工匠20230325.mp4",
    };
  },
  mounted() {
     if(!localStorage.getItem("showPopup")){
      this.showPopup = true
    }
  },
  methods: {
    videoPlayBtn() {
      this.videoPauseShow = false;
      this.controlsShow = true;
      const video = this.$refs.bgVid;
      setTimeout(() => {
        video.play();
      }, 500);
    },
    closeBtn() {
      this.$refs.bgVid.pause();
      this.videoVisible = false;
      this.showPopup= false
      localStorage.setItem("showPopup", "true");
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-popup-parent--hidden{
  padding-right: 1px !important;
}
::v-deep .el-dialog__wrapper{
  overflow: hidden !important;
}
::v-deep .el-dialog__header {
  display: none;
  padding: 0 !important;
}
::v-deep .el-dialog {
  background-color: rgba(254, 254, 254, 0);
  box-shadow: none;
  border-radius: 10px;
  margin-top: -60px !important;
}
::v-deep .el-dialog__body {
  padding: 0 !important;
  background-image: url("~@/assets/home/video-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 811px;
}
.videoContainer {
  width: 610px;
  padding: 30px 20px 15px 20px;
  position: relative;

  video::-internal-media-controls-download-button {
    display: none;
  }
  video::-webkit-media-controls-enclosure {
    overflow: hidden;
  }
  video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
  }
  .prism-big-play-btn {
    z-index: 100;
    position: absolute;
    // transform: translate(-50%, -50%);
    left: 48%;
    top: 577px;
    width: 64px;
    height: 64px;
    background: url("~@/assets/home/bigplay.png") no-repeat;
    background-size: contain;
    cursor: pointer;
    .outter {
      border: 7px solid rgba(255, 255, 255, 0.51);
      width: 64px;
      height: 64px;
      border-radius: 100%;
      position: absolute;
      box-sizing: border-box;
      top: 0;
      left: 0;
    }
    .outter:hover {
      content: "";
      width: 80px;
      height: 80px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -40px;
      margin-top: -40px;
      border-radius: 50%;
      //a1为经过@keyframes 规则建立的动画名称，可本身定义web
      // a2 为动画时常 ，例如：1s   2s   可自行规定浏览器
      // a3 为css自带的动画效果，有以下几个：
      // 类名称+持续时间+（变化方式,延迟时间，重复次数（infinite循环），重复方式，开始结束的状态）
      animation: warn 1s ease-out 0s infinite;
    }
    // 在warn方法里,用百分比来规定变化发生的时间，或用关键词 "from" 和 "to"，等同于 0% 和 100%。
    // 0% 是动画的开始，100% 是动画的完成，建议使用百分比，会更加详细：
    @keyframes warn {
      0% {
        // transform: scale表示缩放，值表示倍数，可以指定X/Y轴
        transform: scale(0.5);
        opacity: 1;
      }

      30% {
        opacity: 1;
      }

      100% {
        transform: scale(1.4);
        opacity: 0;
      }
    }
  }
  .close {
    width: 25px;
    height: 25px;
    font-size: 30px;
    line-height: 20px;
    text-align: center;
    border-radius: 35px;
    position: absolute;
    top: 150px;
    right: -10px;
    cursor: pointer;
    color: #fff;
    background-color: rgba(0, 64, 133, 0.25);
  }
  .img-box {
    width: 94%;
    height: 89%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -49%);
    z-index: 1;
    img {
      width: 600px;
      height: 325px;
    }
  }
}
.fullscreenVideo {
  width: 615px;
  height: 325px;
  object-fit: cover;
  position: absolute;
  top: 456px;
  left: 35px;
}
</style>

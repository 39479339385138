<template>
  <div class="details">
         <futongtitle class="title"> </futongtitle>
    <div class="details-box">
 
      <!-- 认证书盒子 -->
      <div class="shu-box">
        <div class="title-bix">
          <img class="qiyetu" src="../../../img/企业.png@3x.png" alt="" />
          <h2 class="qiyezi">企业认证详情</h2>
        </div>
        <!-- 企业名称 -->
        <div class="middles">
          <div class="middle-left">
            <div class="middle-zi">企业名称</div>
            <div class="qiye">
              <div class="zi">武汉市市政监理有限公司</div>
            </div>
          </div>

          <div class="middle-rit">
            <div class="rit-zi">统一社会信用代码</div>
            <div class="qiye">
              <div class="zi">904939999RIJI</div>
            </div>
          </div>
        </div>
        <!-- 邮    箱 -->
        <div class="middles">
          <div class="middle-left">
            <div class="middle-zi">邮 箱</div>
            <div class="qiye">
              <div class="zi">wuhancity@whcity.com</div>
            </div>
          </div>

          <div class="middle-rit">
            <div class="rit-zi">所在城市</div>
            <div class="qiye">
              <div class="zi">湖北省 武汉市</div>
            </div>
          </div>
        </div>
        <!-- 单位地址 -->
        <div class="middles">
          <div class="middle-left">
            <div class="middle-zi">单位地址</div>
            <div class="qiye">
              <div class="zi">湖北省 武汉市 江岸区 沿江大道180号</div>
            </div>
          </div>

          <div class="middle-rit">
            <div class="rit-zi">单位类型</div>
            <div class="qiye">
              <div class="zi">监理公司</div>
            </div>
          </div>
        </div>
        <!-- 单位性质 -->
        <div class="middles">
          <div class="middle-left">
            <div class="middle-zi">单位性质</div>
            <div class="qiye">
              <div class="zi">国有企业</div>
            </div>
          </div>

          <div class="middle-rit">
            <div class="rit-zi">单位规模</div>
            <div class="qiye">
              <div class="zi">300-500人</div>
            </div>
          </div>
        </div>
        <!-- 主要联系人 -->
        <div class="middles">
          <div class="middle-left">
            <div class="middle-zi">主要联系人</div>
            <div class="qiye">
              <div class="zi">高明明</div>
            </div>
          </div>

          <div class="middle-rit">
            <div class="rit-zi">手 机</div>
            <div class="qiye">
              <div class="zi">13487088511</div>
            </div>
          </div>
        </div>
        <!-- 电    话 -->
        <div class="middles">
          <div class="middle-left">
            <div class="middle-zi">电 话</div>
            <div class="qiye">
              <div class="zi">027-88927488</div>
            </div>
          </div>

          <div class="middle-rit">
            <div class="rit-zi">邮 编</div>
            <div class="qiye">
              <div class="zi">430000</div>
            </div>
          </div>
        </div>
        <!-- 单位网址 -->
        <div class="middles">
          <div class="middle-left">
            <div class="middle-zi">单位网址</div>
            <div class="qiye">
              <div class="zi">www.wuhancity.com.cn</div>
            </div>
          </div>
        </div>

        <div class="di-zi">
          <div class="jian">企业简介</div>

          <div class="jian-rig">
            <div class="jian-miao">
              武汉市政建设监理有限公司成立于2001年08月01日，注册地位于江汉区红旗渠路浩海小区特1号，法定代表人为喻利华。经营范围包括城市道路、桥梁、排水、防洪、环保工程及一般工业与民用建筑建设监理；城市道路开发的房屋拆迁还建工程、经济技术咨询。武汉市政建设监理有限公司对外投资1家公司，具有20处分支机构。
            </div>
          </div>
        </div>
        <!-- 用户上传的信息回调 -->
        <div class="User">
          <div class="User-box">
            <div class="User-box">
              <div class="yuan"></div>
              <h2 class="ye">营业执照</h2>
            </div>

            <div class="User-boxs">
              <div class="yuans"></div>
              <h2 class="yes">法人身份证/授权书</h2>
            </div>
          </div>
          <div class="User-di" >
            <div class="User-tu">
              <img class="img" src="../../../img/diao.png" alt="" />
            </div>
            <div class="User-rittu">
              <img class="img" src="../../../img/diao.png" alt="" />
              <img class="img" src="../../../img/diao.png" alt="" />
              <img class="img" src="../../../img/diao.png" alt="" />
            </div>
          </div>
        </div>
        <div  class="btn">
      <div class="ok" >通过</div>
      <div class="no" >驳回</div>
        </div>
      
      </div>
      
    </div>
  </div>
</template>

<script>
import futongtitle from '../../../components/fuyong/fuyongtitle.vue'
export default {
  data() {
    return {}
  },
  components: {
    futongtitle
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.details {
  width: 100%;
  height: 100vh;
  // overflow: hidden;
  background: rgba(242, 242, 242, 1);
}
.details-box {
  // width: 1920px;
  background: rgba(242, 242, 242, 1);
  margin: 0 auto;
  overflow: hidden;
}
.title {
  // width: 1920px;
  margin: 0 auto 127px;
}

.shu-box {
  width: 1200px;
  height: 1562px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 0 auto;
}
.title-bix {
  position: relative;
  margin-left: 500px;
}
.shu-box .qiyetu {
  display: block;
  width: 200px;
  height: 200px;
}
.qiyezi {
  position: absolute;
  width: 156px;
  height: 38px;
  color: rgba(51, 51, 51, 1);
  font-size: 26px;
  top: 101px;
  left: 22px;
}
.middles {
  display: flex;
  // margin-left: 88px;
  margin-top: 24px;
}
.middle-left {
  display: flex;
  margin-left: 88px;
}
.middle-zi {
  color: rgba(40, 45, 48, 1);
  font-size: 14px;
  line-height: 55px;
  width: 76px;
  height: 55px;
}
.qiye {
  line-height: 55px;
  color: rgba(40, 45, 48, 1);
  font-size: 14px;
  background: rgba(252, 252, 252, 1);
  background-blend-mode: normal;
  border-radius: 5px;
  width: 358px;
  margin-left: 21px;
  .zi {
    margin-left: 15px;
  }
}
.middle-rit {
  margin-left: 99px;
  display: flex;
  .rit-zi {
    color: rgba(40, 45, 48, 1);
    font-size: 14px;
    line-height: 55px;
    width: 121px;
    height: 55px;
  }
}
.di-zi {
  display: flex;
}
.jian {
  color: rgba(40, 45, 48, 1);
  font-size: 14px;
  line-height: 55px;
  margin-left: 88px;
}
.jian-rig {
  width: 933px;
  height: 154px;
  background: rgba(252, 252, 252, 1);
  border-radius: 5px;
  color: rgba(40, 45, 48, 1);
  font-size: 14px;
  margin-left: 33px;
  margin-top: 40px;
  line-height: 28px;
}
.jian-miao {
  margin-left: 15px;
}
.User {
  margin-top: 20px;
  width: 1020px;
  height: 350px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-left: 88px;
  .qqw {
    width: 159px;
    height: 135px;
    background: red;
  }
}
.User-box {
  display: flex;
  .yuan {
    width: 8px;
    height: 8px;
    margin-top: 39px;
    margin-left: 31px;
    background: rgba(42, 130, 228, 1);
    border-radius: 50%;
  }
  .ye {
    margin-left: 13px;
    margin-top: 30px;
    color: rgba(40, 45, 48, 1);
    font-size: 16px;
  }
  .User-boxs {
    display: flex;
    margin-left: 343px;
  }
  .yuans {
    width: 8px;
    height: 8px;
    margin-top: 39px;
    margin-left: 31px;
    background: rgba(42, 130, 228, 1);
    border-radius: 50%;
  }
  .yes {
    margin-left: 13px;
    margin-top: 30px;
    color: rgba(40, 45, 48, 1);
    font-size: 16px;
  }
}
.User-di{
    display: flex;
}
.User-tu {
  width: 159px;
  height: 135px;
  margin-left: 55px;
  margin-top: 55px;

  .img {
    width: 100%;
    height: 100%;
    display: block;
  }
}
.User-rittu {
  display: flex;
  margin-left: 194px;
  margin-top: 54px;
  .img {
    width: 159px;
    height: 135px;
    display: block;
    margin-right: 31px;
  }
}
.btn{
  display: flex;
      justify-content: space-evenly;
      margin-top: 43px;
}
.ok{
  width: 120px;
height: 40px;
background: rgba(2, 154, 255, 1);
background-blend-mode: normal;
border-radius: 5px;
color: #fff;
text-align: center;
line-height: 40px;
}
.no{
width: 120px;
height: 40px;
background: rgba(224, 74, 74, 1);
background-blend-mode: normal;
border-radius: 5px;
color: #fff;
line-height: 40px;
text-align: center;
}
</style>

<template>
  <div class="Position">
    <!-- <futongtitle class="title" ref="productImage"> </futongtitle> -->
    <!-- 头部复用 -->
    <position class="title" ref="productImage"></position>
    <div class="Position-box">
      <div class="Position-item">
        <div class="Position-title">
          <div class="fiex">
            <div class="titles">
              <!-- 模态窗 -->
              <!-- <div class="city-sel" @click="city"> -->
              <div class="city-sel">
                <!-- <div class="city-text">wuhaoasdasd</div> -->
                <!-- <el-cascader
                  v-model="chooseCity"
                  :props="cityProps"
                  :options="provincialList"
                  @change="handleChange"
                ></el-cascader> -->
                <el-cascader v-model="chooseCity" :show-all-levels="false" placeholder="请选择城市" :props="props" @change="handleChange"></el-cascader>
                <!-- <img class="icon-arrow-down" src="el-icon-arrow-down" alt="" /> -->
                <!-- <i class="el-icon-arrow-down icon-arrow-down"></i> -->
              </div>

              <el-input v-model="input" placeholder="请输入职位名称 \ 公司名称" class="ipt" clearable @clear="clearSearch"> </el-input>
              <!-- 三级选择器 -->
              <el-cascader v-model="postTypeId" :options="postTypeList" :show-all-levels="false" :props="typeProps" @change="choosePostType" placeholder="职位类型"></el-cascader>

              <el-cascader v-model="tradeTypeId" :options="tradeTypeList" :show-all-levels="false" :props="typeProps" @change="chooseTradeType" placeholder="公司行业"></el-cascader>

              <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
            </div>
          </div>

          <!-- tab栏 -->
          <div class="filter-select-box">
            <div class="dropdown-wrap">
              <el-select v-model="selectExp" placeholder="工作经验" class="dropdown-select">
                <el-option v-for="item in exp" :key="item.name" :label="item.name" :value="item.code" class="ipt"> </el-option>
              </el-select>

              <el-select v-model="selectEdu" placeholder="学历要求" class="dropdown-select">
                <el-option v-for="item in jobEdu" :key="item.name" :label="item.name" :value="item.code" class="ipt"> </el-option>
              </el-select>

              <el-select v-model="selectPay" placeholder="薪资要求" class="dropdown-select">
                <el-option v-for="item in jobPay" :key="item.name" :label="item.name" :value="item.code" class="ipt"> </el-option>
              </el-select>

              <el-select v-model="selectJobOrgType" placeholder="单位性质" class="dropdown-select">
                <el-option v-for="item in jobOrgType" :key="item.name" :label="item.name" :value="item.code" class="ipt"> </el-option>
              </el-select>

              <el-select v-model="selectGm" placeholder="公司规模" class="dropdown-select">
                <el-option v-for="item in jobGm" :key="item.name" :label="item.name" :value="item.code" class="ipt"> </el-option>
              </el-select>
            </div>
            <div class="empty-filter" @click="empty">清空筛选条件</div>
          </div>
        </div>
      </div>
      <!-- 中间样式 -->
      <!-- <div class="box"> -->
      <div class="inner">
        <div class="job-list">
          <div>
          <ul>
            <li v-for="(item, index) in jobPostList" :key="index" :class="item.surplusSum ? 'liTitle' : ''">
              <div class="job-primary">
                <div class="info-primary" @click="toDatum(item)">
                  <div class="primary-wrapper" style="cursor: pointer">
                    <div class="primary-box">
                      <div class="job-title">
                        {{ item.name }}
                        <!-- <span class="job-area">{{ item.pay }}</span> -->
                        <span class="job-area">{{ getPay(item.pay) }}</span>
                      </div>
                      <div class="job-limit clearfix">
                        <div class="zi">
                          <span v-if="item.cityName">{{ item.cityName }} | </span><span v-else>{{ item.city ? $dictInfo.getCityForId(item.city) : '' }} | </span>{{ getExp(item.exp) }} |
                          {{ showEdu(item.edu) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 右边 @click="tocCompanyInfo(item)" -->
                  <div class="info-company" style="cursor: pointer">
                    <div class="company-text">
                      <div class="name">{{ item.orgName }}</div>
                      <div class="zi">
                        {{ getOrgType(item.orgType) }} | {{ getJobOrgType(item.properties) }} |
                        {{ item.size ? getGm(item.size) : '' }}
                      </div>
                    </div>
                    <div class="company-logo">
                      <img v-if="item.logo && item.logo != 'null'" :src="$imgUrl(item.logo)" alt="" :onerror="$global.srcImgUrl" />
                      <div class="cLogo" v-else>
                        <span>{{ getFirstWord(item.orgName) }}</span>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="job-boxs">
                <div class="tu" @click="sendMessage(item)">
                  <!-- <div class="job-tu">
                    <img src="../../../img/圆形 1@3x.png" alt="" />
                    @/assets/home/affiliated-img.png
                  </div> -->
                   <!-- <el-tooltip class="item" effect="dark" content="点击沟通" placement="top-start"> -->
     <div class="renshi">联系人: {{ item.legallyLiablePerson ? item.legallyLiablePerson.charAt(0) : '' }}经理</div>
    <!-- </el-tooltip> -->

                </div>
                <div class="zhiwei">
                  <!-- <div class="nox" v-if="item.welfare"> -->
                  <template v-for="(i, index) in item.welfare">
                    <div class="nox" v-if="index < 6" :key="i" style="line-height: normal">
                      {{ i }}
                    </div>
                  </template>
                </div>
              </div>
            </li>

            <el-empty v-if="total == 0" description="空空如也"></el-empty>

            <div class="paginationBox">
              <el-pagination :background="true" layout="prev, pager, next" :total="total" :page-size="10" @current-change="chengePagination"> </el-pagination>
            </div>
          </ul>
          </div>
        </div>
        <div class="job-box">
          <!-- <div class="sider">
              <div class="sign-wrap sign-wrap-v2">
                <div class="update" @click="update">上传简历一键注册</div>
              </div>
            </div> -->
          <div class="sider-list">
            <div class="sider-box">
              <h3>看过的职位</h3>
            </div>
            <div class="xian"></div>
            <div class="zhiweis" v-for="(item, index) in postRedCollectList" :key="index" @click="toDatumInfo(item)">
              <div class="wen-box">
                <div class="it">{{ item.postName ? item.postName : '' }}</div>
                <div class="qian">{{ item.pay ? getPay(item.pay) : '' }}</div>
              </div>
              <div class="bosz">{{ item.orgName ? item.orgName : '' }}</div>
            </div>
          </div>
          <div class="sider-list about-search">
            <div class="h3">热门搜索</div>
            <div class="keyWordBox">
              <div class="searchKeyWord" v-for="(item, index) in jobSearchKeyWord" :key="index" @click="searchForKeyWord(item.name)">
                <span>{{ item.name }}</span>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
    <footerss class="footer" style="margin-top: 100px"></footerss>

    <!-- 模态窗 -->
    <!-- <div class="mock" v-if="mock == 1" @click="offmock">
      <div @click.stop="offmocks" class="mock-box"></div>
    </div> -->
    <el-dialog
      :visible.sync="visibleIM"
      width="1240px"
      top="10vh"
      :before-close="handleClose"
    >
      <Notice v-if="visibleIM"></Notice>
    </el-dialog>
  </div>
</template>

<script>
import futongtitle from '../../../components/fuyong/fuyongtitle.vue'
import tabs from '../../../components/tab/tab.vue'
import position from '../../../components/hometitle/hometitle.vue'
import footerss from '../../../components/foot/foot.vue'
import { jobPostSearch, jobPostType, jobSearchAdd, jobSearchPage, jobPostRedCollectList } from '@/api/position/position'
import { getProvincial, getCity } from '@/api/index/index'
import Notice from '@/components/notice/notice.vue'
import { mapState } from 'vuex'
import { detailTeam } from "@/api/team/team";
import { getJobUserAuth } from '@/api/jobInformation/jobInformation'
export default {
  data() {
    return {
      visibleIM: false,
      userId: '',
      total: 0,
      dict: [], //字典
      jobEdu: [], //学历
      exp: [], //工作经验
      jobPay: [], //薪资
      jobGm: [], //公司规模
      jobOrgType: [], //单位性质
      selectExp: '',
      selectEdu: '',
      selectPay: '',
      selectGm: '',
      selectJobOrgType: '',
      // options: [
      //   {
      //     value: 'zhinan',
      //     label: '指南',
      //     children: [
      //       {
      //         value: 'shejiyuanze',
      //         label: '设计原则',
      //         children: [
      //           {
      //             value: 'yizhi',
      //             label: '一致',
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // ],
      mock: 0,
      input: '',
      value: '',
      provincialList: [], //省份列表
      chooseCity: '',
      cityProps: {
        value: 'pid',
        label: 'Provincial',
        children: 'children',
      },
      props: {},
      postTypeList: [], //职位类型列表
      tradeTypeList: [], //行业类型列表
      postTypeId: '', //选择职位类型id
      tradeTypeId: '', //选择行业类型id
      typeProps: {
        value: 'id',
        label: 'title',
        children: 'children',
      },
      jobPostList: [], //职位列表
      params: {
        grounding: 'Y',
        auditStatus: 2,
        pageNo: 1,
        pageSize: 10,
        keyWord: '', //关键词
        city: '', //城市
        positionType: '', //职位
        exp: '', //工作经验
        edu: '', //学历
        pay: '', //薪资
        size: '', //公司规模
        properties: '',
        orgType: '', //公司行业
      },
      jobSearchKeyWord: '', //查询搜索关键字
      postRedCollectList: [], //看过的职位
    }
  },
  components: {
    futongtitle,
    footerss,
    tabs,
    position,
    Notice
  },
  computed: {
    ...mapState(['peopleList']),
  },
  created() {
    // this.getProvincialList()
    ;(this.props = {
      value: 'PROVINCE_CODE',
      label: 'SHORT_NAME',
      children: 'children',
      lazy: true,
      lazyLoad: (node, resolve) => {
        const { level } = node
        if (level == 0) {
          getProvincial().then((res) => {
            const city = res.data
            resolve(city)
          })
        }
        if (level == 1) {
          getCity({ PROVINCE_CODE: node.value }).then((res) => {
            let arr = []
            res.data.forEach((i) => {
              const obj = {
                PROVINCE_CODE: i.CITY_CODE,
                SHORT_NAME: i.SHORT_NAME,
                leaf: true,
              }
              arr.push(obj)
            })
            const city = arr
            resolve(city)
          })
        }
      },
    }),
      (this.userId = localStorage.getItem('userId'))
    this.dict = JSON.parse(localStorage.getItem('dict'))
    if(this.dict){
      this.jobEdu = this.dict.filter((i) => i.code == 'job_edu')[0].children
      this.exp = this.dict.filter((i) => i.code == 'job_exp')[0].children
      this.jobPay = this.dict.filter((i) => i.code == 'job_pay')[0].children
      this.jobGm = this.dict.filter((i) => i.code == 'job_gm')[0].children
      this.jobOrgType = this.dict.filter((i) => i.code == 'job_org_type')[0].children
    }else{
      this._getDict()
    }
    // this.getJobPostList()
    this.getJobPostType()
    this.getJobTradeType()
    this.getJobSearchPage()
    // 看过的职位
    this.getJobPostRedCollectList()

    if (this.$route.query.keyWord) {
      this.input = this.$route.query.keyWord
      this.search()
    } else {
      this.getJobPostList()
    }
  },
  mounted() {
    this.$refs.productImage.getindex(1)
  },
  methods: {
    _getDict() {
      this.$global.getDictInfo().then(res => {
          if (res.code == 200 && res.message === "请求成功") {
          localStorage.setItem("dict", JSON.stringify(res.data));
          this.dict = JSON.parse(localStorage.getItem("dict"));
          this.jobEdu = this.dict.filter((i) => i.code == 'job_edu')[0].children
          this.exp = this.dict.filter((i) => i.code == 'job_exp')[0].children
          this.jobPay = this.dict.filter((i) => i.code == 'job_pay')[0].children
          this.jobGm = this.dict.filter((i) => i.code == 'job_gm')[0].children
          this.jobOrgType = this.dict.filter((i) => i.code == 'job_org_type')[0].children
        }
      })
    },
    // 看过的职位
    getJobPostRedCollectList() {
      let params = {
        type: '1',
        pageNo: 1,
        pageSize: 3,
      }
      this.userId ? (params.userId = this.userId) : (params.ip = returnCitySN['cip'])
      jobPostRedCollectList(params).then((res) => {
        this.postRedCollectList = res.data.rows
      })
    },
    // 查询搜索关键字
    getJobSearchPage() {
      jobSearchPage({ pageSize: 10 }).then((res) => {
        this.jobSearchKeyWord = res.data.rows
      })
    },
    searchForKeyWord(keyWord) {
      this.input = keyWord
      this.search()
    },
    // 公司行业回显
    getOrgType(str) {
      let newStr = str.split(',')
      let arr = []
      if (this.tradeTypeList.length > 0) {
        newStr.forEach((i) => {
          let ids = this.getOrgTypeForTreer(this.tradeTypeList, i)
          if (ids.length > 0) {
            arr.push(ids)
          }
        })
      }
      let strs = arr.join('，')
      // return strs.substring(0, 12) + '…'
      if (strs.length >= 12) {
        return strs.substring(0, 12) + '…'
      } else {
        return strs
      }
    },
    // 根据公司行业id回显  递归找父级id
    getOrgTypeForTreer(list, id) {
      var ids = []
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          // if (list[i].pid != '1537642960810602497') {
          //   ids.push(list[i].pid)
          // }
          ids.push(list[i].title)
          break
        } else if (list[i].children && list[i].children.length > 0) {
          ids = this.getOrgTypeForTreer(list[i].children, id)
          if (ids.length > 0) return ids
        }
      }
      return ids
    },
    // 单位性质回显
    getJobOrgType(code) {
      return this.jobOrgType.filter((i) => i.code == code)[0].name
    },
    getGm(code) {
      return this.jobGm.filter((i) => i.code == code)[0].name
    },
    getFirstWord(name) {
      if (!name) {
        return
      }
      let pinyin = require('js-pinyin')
      pinyin.setOptions({ checkPolyphone: false, charCase: 0 })
      let word = pinyin.getFullChars(name).substring(0, 1)
      return word
    },
    getPay(code) {
      if(this.jobPay.length >0 && code){
        return this.jobPay.filter((i) => i.code == code)[0].name
      }else{
        return ''
      }

    },
    chengePagination(cur) {
      this.params.pageNo = cur
      jobPostSearch(this.params).then((res) => {
        this.jobPostList = res.data.rows
        this.jobPostList.forEach((i) => {
          if (i.welfare) {
            i.welfare = i.welfare.split(',')
          }
        })
        this.total = res.data.totalRows
      })
      document.documentElement.scrollTop = 0
    },
    // 查看职位详情
    toDatum(item) {
      this.$router.push({
        path: '/datum',
        query: { id: item.id },
      })
    },
    // 查看职位详情
    toDatumInfo(item) {
      this.$router.push({
        path: '/datum',
        query: { id: item.postId },
      })
    },
    // 查看公司详情
    tocCompanyInfo(item) {
      this.$router.push({
       path: '/enterpriseDetails',
        query: { id: item.jobOrgId }
      })
    },
    // 显示学历
    showEdu(val) {
      return this.jobEdu.filter((i) => i.code == val)[0].name
    },
    // 显示工作经验
    getExp(val) {
      return this.exp.filter((i) => i.code == val)[0].name
    },
    // 级联选择器递归判断children有没有值
    hasChildren(list) {
      list.forEach((item) => {
        if (item.children.length > 0) {
          this.hasChildren(item.children)
        } else {
          item.children = null
        }
      })
      return list
    },
    // 职位类型
    getJobPostType() {
      jobPostType({ code: 'post_type' }).then((res) => {
        this.postTypeList = this.hasChildren(res.data[0].children)
      })
    },
    //行业类型
    getJobTradeType() {
      jobPostType({ code: 'trade_type' }).then((res) => {
        this.tradeTypeList = this.hasChildren(res.data[0].children)
      })
    },
    // 查询省份列表
    getProvincialList() {
      getProvincial().then((res) => {
        this.provincialList = res.data
      })
    },
    // 职位类型
    choosePostType(val) {
      this.postTypeId = val[val.length - 1]
    },
    // 公司行业
    chooseTradeType(val) {
      this.tradeTypeId = val[val.length - 1]
    },
    handleChange(val) {
      this.chooseCity = val[val.length - 1]
    },
    // 分页查询职位
    getJobPostList() {
      jobPostSearch(this.params).then((res) => {
        this.jobPostList = res.data.rows
        this.jobPostList.forEach((i) => {
          if (i.welfare) {
            i.welfare = i.welfare.split(',')
          }
        })
        this.total = res.data.totalRows
      })
    },
    // changes(e) {
    //   console.log(e)
    // },
    // city() {
    //   // this.mock = 1
    // },
    // 模态窗
    // offmock() {
    //   // stopPropagation(offmock)
    //   this.mock = 0
    // },
    // 模态窗
    // offmocks() {},
    search() {
      if (this.input) {
        jobSearchAdd({ name: this.input }).then((res) => {
          if (res.success) {
            this.getJobSearchPage()
          }
        })
      }
      this.params = {
        grounding: 'Y',
        auditStatus: 2,
        pageNo: 1,
        pageSize: 10,
        keyWord: this.input,
        city: this.chooseCity,
        positionType: this.postTypeId,
        exp: this.selectExp,
        edu: this.selectEdu,
        pay: this.selectPay,
        size: this.selectGm,
        properties: this.selectJobOrgType,
        orgType: this.tradeTypeId,
      }
      jobPostSearch(this.params).then((res) => {
        this.jobPostList = res.data.rows
        this.total = res.data.totalRows
        this.jobPostList.forEach((i) => {
          if (i.welfare) {
            i.welfare = i.welfare.split(',')
          }
        })
      })
    },
    empty() {
      this.params.pageNo = 1
      this.params.keyWord = ''
      this.params.city = ''
      this.params.positionType = ''
      this.params.exp = ''
      this.params.edu = ''
      this.params.pay = ''
      this.params.size = ''
      this.params.properties = ''
      this.params.orgType = ''

      this.input = ''
      this.chooseCity = ''
      this.postTypeId = ''
      this.tradeTypeId = ''
      this.selectExp = ''
      this.selectEdu = ''
      this.selectPay = ''
      this.selectGm = ''
      this.selectJobOrgType = ''
      this.getJobPostList()
    },
    clearSearch() {
      this.params.pageNo = 1
      this.params.keyWord = ''
      // this.params.city = ''
      // this.params.positionType = ''
      // this.params.exp = ''
      // this.params.edu = ''
      // this.params.pay = ''
      // this.params.size = ''

      this.input = ''
      // this.chooseCity = ''
      // this.postTypeId = ''
      // this.tradeTypeId = ''
      // this.selectExp = ''
      // this.selectEdu = ''
      // this.selectPay = ''
      // this.selectGm = ''
      this.getJobPostList()
    },
    // 注册
    update() {
      this.$router.push('/register')
    },
    // // 详情
    // goxi() {
    //   window.open('./datum')
    // },
    handleClose() {
      this.visibleIM = false
    },
    // 沟通发送消息
    async sendMessage(item) {
      if (!localStorage.getItem('userId')) {
        this.$confirm('请先登录!', '', {
          confirmButtonText: '登录',
          showCancelButton: false,
          type: 'warning'
        }).then(() => {
         this.$router.push('/login')
        }).catch(() => {});
        return
      }
    let res = await getJobUserAuth()
    let userAuth = res.data
    let jobUserId = localStorage.getItem("userId");
    let info = await detailTeam({ jobUserId })
    let userTeam = info.data
      if(userAuth != null || userTeam != null && userTeam.auditStatus == 2){
        this.visibleIM = true
        console.log(this.peopleList)
      if (
        this.peopleList.find(i => i.userProfile.userID == item.jobOrgId)
      ) {
        return
      }
      let peopleList = this.peopleList
      let obj = {
        userProfile: {
          userID: item.jobOrgId,
          nick: item.orgName,
        },
      }
      this.peopleList.unshift(obj)
      this.$store.commit('set_peopleList', peopleList)
      }else{
        this.$message.warning('请认证(个人/班组)后进行沟通')
      }
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .btn-prev {
  background: #fff;
}

::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}
.footer {
  background: #fff;
}

// .box {
//   width: 1800px;
//   margin: 0 auto;
// }
::v-deep .ipt {
  border: none;
}

.title {
  background: rgb(2, 154, 255);
}
.Position {
  width: 100%;
  height: 100%;
  background: #eff1f4;
  // margin: 0 auto;
  // overflow: hidden;
}
.Position-box {
  // width: 1920px;
  height: 100%;
  margin: 0 auto;
  margin-top: 80px;
}
.Position-item {
  width: 100%;
  height: 146px;
  background: rgba(255, 255, 255, 1);

  .Position-title {
    // width: 1920px;
    width: 1200px;
    padding-top: 36px;
    height: 110px;
    background: rgba(255, 255, 255, 1);

    border-radius: 5px;
    margin: 0 auto;
    .titles {
      display: flex;
      width: 894px;
      height: 48px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(2, 154, 255, 1);
      border-radius: 5px;
      // margin: 0 auto;
      // margin-left: 367px;
    }
    ::v-deep .el-input__inner {
      height: 44px;
      line-height: 44px;
      // border-radius: 5px 0 0 5px;\
      border: none;
      // border-top: none;
      // border-bottom: none;
    }

    .ipt {
      height: 48px;
      ::v-deep .el-input__inner {
        margin-top: 5px;
        height: 35px;
        line-height: 48px;
        width: 300px;
        border-radius: 0;
      }
    }
  }
}
::v-deep .el-select {
  height: 48px;
  line-height: 48px;
  width: 100px;
}
.city-sel {
  position: relative;
  cursor: pointer;
  width: 240px;
  height: 28px;
}
.city-text {
  width: 68px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  text-align: center;
  line-height: 48px;
  margin-left: 20px;
}

.icon-arrow-down {
  position: absolute;
  right: 7px;
  top: 15px;
  display: block;
  width: 16px;
  height: 16px;
}
// 分页
// .ye {
//   margin-left: 178px;
//   margin-top: 65px;
// }
.paginationBox {
  text-align: center;
  // margin: 30px 0;
}
// 模态窗
.mock {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #252830;
  background: rgba(37, 40, 48, 0.7);
  z-index: 1002;
  .mock-box {
    width: 786px;
    height: 527px;
    margin: -243px 0 0 -393px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #fff;
    z-index: 1003;
  }
}
::v-deep .el-button {
  border-radius: 0px 5px 5px 0px;
  margin-right: -1px;
}
// tab
.filter-select-box {
  display: flex;
  margin: 0 auto;
  height: 40px;
  font-size: 12px;
  width: 1184px;
  height: 40px;
  justify-content: space-between;
  .empty-filter {
    float: right;
    line-height: 40px;
    color: #8d92a1;
    margin-right: 0;
    line-height: 58px;
    cursor: pointer;
  }
  .dropdown-wrap {
    display: flex;
    width: 160px;
    // float: left;
    position: relative;
    .dropdown-select {
      margin-left: 20px;
      width: 120px;
      min-width: 120px;
      border: none;
      padding-top: 5px;
      font-size: 12px;
      padding-bottom: 3px;
      position: unset;
      display: inline-block;
      background-color: #fff;

      .ipt {
        width: 75px;
        min-width: 75px;
        border: none;
        padding-top: 5px;
        font-size: 12px;
        padding-left: 0;
        padding-right: 3px;
        color: #414a60;
        cursor: pointer;
        background: 0 0;
        position: relative;
        outline: none; // 去除选中状态边框
      }
    }
  }
}
.fiex {
  display: flex;
  justify-content: center;
  width: 1200px;
  margin: 0 auto;
}
.btn {
  width: 282px;
  height: 48px;
  margin-left: 20px;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
// 中间样式

.inner {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.home-inner {
  max-width: 1184px;
}
.job-box {
  // margin-top: 30px;
  // float: right;
  width: 284px;
}
// 上传简历一键注册
.sign-wrap {
  width: 282px;
  height: 60px;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  padding-top: 24px;
  margin-bottom: 18px;

  .update {
    margin-left: 24px;
    width: 230px;
    height: 36px;
    background: rgba(2, 154, 255, 1);
    border-radius: 3px;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
  }
}

.sider-list {
  background: #fff;
  margin-bottom: 16px;
  .sider-box {
    width: 282px;
    height: 48px;
    background: rgba(255, 255, 255, 1);
    border-radius: 3px, 3px, 0px, 0px;
    h3 {
      color: rgba(2, 154, 255, 1);
      font-size: 14px;
      line-height: 48px;
      margin-left: 24px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .xian {
    width: 282px;
    height: 0px;
    border: 1px solid rgba(232, 232, 232, 1);
  }
  .zhiweis {
    width: 282px;
    height: 82px;
    cursor: pointer;
    .wen-box {
      margin-top: 13px;
      display: flex;
      justify-content: space-around;
      .it {
        width: 125px;
        height: 21px;
        color: rgba(64, 64, 64, 1);
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      :hover.it {
        color: rgba(51, 133, 255, 1);
      }
      .qian {
        color: rgba(255, 141, 25, 1);
        font-size: 14px;
      }
    }
    .bosz {
      margin-left: 20px;
      margin-top: 9px;
      color: rgba(168, 168, 168, 1);
      font-size: 12px;
    }
  }
}
// tab
.about-search {
  .h3 {
    height: 48px;
    line-height: 48px;
    font-size: 15px;
    color: #1592ea;
    padding-left: 30px;
    font-weight: 400;
    border-bottom: solid 2px #f2f2f5;
  }
  .keyWordBox {
    padding: 0 24px;
    .searchKeyWord {
      height: 56px;
      border-bottom: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        flex: 1;
        font-size: 14px;
      }
    }
    .searchKeyWord:nth-last-child(1) {
      border-bottom: none;
    }
    .searchKeyWord:hover {
      color: #1592ea;
    }
  }

  // :hover.yuan {
  //   color: #1592ea;
  //   cursor: pointer;
  // }
  // .yuan {
  //   width: 282px;
  //   height: 56px;
  //   color: rgba(64, 64, 64, 1);
  //   font-size: 14px;
  //   line-height: 56px;
  //   padding-left: 30px;
  //   .el-icon-arrow-right {
  //     margin-left: 155px;
  //   }
  //   .xian {
  //     width: 234px;
  //     height: 0px;
  //     border: 1px solid rgba(232, 232, 232, 1);
  //   }
  // }
}
// 左边
ul {
  list-style: none;
}
li {
  list-style: none;
  // height: 130px;
  // cursor: pointer;
  margin-bottom: 10px;
  background: #fff;
}
.job-list {
  width: 900px;
  // background: 0 0;
  // margin-right: 300px;
  ul {
    padding: 0;
    margin: 0;
    // li {

    // }
  }
}
.job-primary {
  height: 100px;
  box-sizing: border-box;
  padding: 0;
  color: #4a4160;
  margin: 0 30px;
  border-bottom: 1px #f2f2f5 solid;
  position: relative;
  .info-primary {
    width: auto;
    padding-top: 20px;
    float: none;
    display: flex;
    justify-content: space-between;
  }
  .primary-wrapper {
    vertical-align: top;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .primary-box {
      display: inline-block;
      line-height: 26px;
      .job-title {
        color: rgba(68, 68, 68, 1);
        font-weight: 400;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        font-size: 18px;
      }
    }
  }
  .el-select-dropdown__item {
    height: 40px;
  }
  .job-area {
    float: right;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    color: rgba(255, 141, 25, 1);
    font-size: 20px;
    margin-left: 30px;
  }
  .clearfix {
    .zi {
      color: rgba(102, 102, 102, 1);
      font-size: 14px;
      margin-top: 5px;
    }
  }
}
.info-company {
  display: flex;

  .company-text {
    width: auto;
    .name {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 3px;
      overflow: visible;
    }
    .zi {
      color: rgba(102, 102, 102, 1);
      font-size: 14px;
      margin-top: 5px;
    }
  }
  .company-logo {
    width: 60px;
    height: 60px;
    margin-left: 16px;
    img {
      width: 60px;
      height: 60px;
      object-fit: contain;
    }
    .cLogo {
      width: 60px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      border: 2px solid #ccc;
      background-color: #f4f4f4;
      span {
        font-size: 24px;
        color: #ccc;
        font-weight: bolder;
      }
    }
  }
}
// 描述
.job-boxs {
  display: flex;
  // width: 842px;
  height: 46px;
  line-height: 46px;
  background: rgba(250, 250, 250, 1);
  justify-content: space-between;
}
.tu {
  display: flex;
  margin-left: 33px;
  cursor: pointer;
}
.job-tu {
  width: 26px;
  height: 26px;
  // z-index: 99;

  img {
    display: block;
    width: 100%;
    height: 100%;
    margin-top: 8px;
  }
}
.renshi {
  height: 18px;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  margin-left: 10px;
}
.tu :hover{
	color: #1592ea;
}
.zhiwei {
  display: flex;
  margin-top: 11px;
  margin-right: 33px;
  .nox {
    // width: 78px;
    // width: 120%;
    padding: 0 10px;
    height: 24px;
    background: rgba(227, 244, 255, 1);
    border-radius: 3px;
    margin-left: 10px;
    color: rgba(51, 133, 255, 1);

    .nox-zi {
      text-align: center;
      line-height: 24px;
      font-size: 14px;
    }
  }
}
.liTitle {
  position: relative;
}
.liTitle::after {
  content: '';
  background-image: url(https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/9b7b3d29-1370-4412-b6fe-7a6772d38a2e.png);
  background-repeat: no-repeat;
  padding: 21px 30px;
  position: absolute;
  top: 0px;
}
</style>
